import emailTextToChat from "./emailTextToChat";
import { EmailChatMessage, TrimmedEmailLine } from "./model";

type CollapseInstruction = {
  collapse: boolean;
  end?: number;
};

const GMAIL_QUOTED_REPLY_HEADER =
  /^>.*$|^On.*$|^From:.*$|^.*<\S+@\S+>.* wrote:$/;

const tryCollapse = (
  lines: TrimmedEmailLine[],
  start: number,
): CollapseInstruction => {
  let end = start + 1;
  for (; end < lines.length; end++) {
    const line = lines[end];
    if ((!line.seen || !line.quoted) && line.trimmedLine.length > 0) {
      break;
    }
  }
  return { collapse: true, end: end - 1 };
};

const collapseQuotedLines = (lines: TrimmedEmailLine[]) => {
  let html = "";
  let i = 0;
  for (; i < lines.length; i++) {
    const line = lines[i];
    let shouldHide = line.seen;
    if (!shouldHide) {
      // Check standard quoted section headers: "On ... wrote:"
      if (GMAIL_QUOTED_REPLY_HEADER.test(line.trimmedLine)) {
        shouldHide = true;
      }
    }
    if (!shouldHide) {
      html += `${line.line}<br/>`;
    } else {
      const collapseInstruction = tryCollapse(lines, i);
      if (collapseInstruction.collapse && collapseInstruction.end) {
        i = collapseInstruction.end;
      }
    }
  }
  return html;
};

export default (message: EmailChatMessage) => {
  if (message.linesOfText) {
    return collapseQuotedLines(message.linesOfText);
  }
  return message.data?.text
    ? emailTextToChat(message.data.text).join("<br/>")
    : message.data?.html;
};
