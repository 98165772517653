import {
  CheckCircle,
  Contrast,
  RadioButtonUnchecked,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import { ExecutionState } from "../../../../model/Process";
import { AccountProfileViewer } from "../../AccountProfilePage";

type ProcessBoxProps = {
  name: string;
  description?: string;
  completedTasks: number;
  totalTasks: number;
  visibleToUser: boolean;
  userType: AccountProfileViewer;
  status?: ExecutionState;
  onClick?: () => Promise<unknown>;
};

const ProcessBox = ({
  name,
  description,
  completedTasks,
  totalTasks,
  visibleToUser,
  status,
  userType,
  onClick,
}: ProcessBoxProps) => {
  const getStatusIcon = () => {
    switch (status) {
      case ExecutionState.InProgress:
        return (
          <Contrast
            sx={{ my: "auto", color: (theme) => theme.palette.grey[600] }}
          />
        );
      case ExecutionState.Completed:
        return (
          <CheckCircle
            sx={{
              my: "auto",
              color: (theme) => theme.palette.progress.complete,
            }}
          />
        );
    }
    return (
      <RadioButtonUnchecked
        sx={{ my: "auto", color: (theme) => theme.palette.grey[600] }}
      />
    );
  };
  const getProgressBar = () => {
    const percentage = (completedTasks / totalTasks) * 100;

    return (
      <Box
        sx={{
          height: "100%",
          background: (theme) =>
            status == ExecutionState.InProgress ||
            status == ExecutionState.Completed
              ? `linear-gradient(to right, ${theme.palette.progress.progress} 0% ${percentage}%, ${theme.palette.progress.remaining} ${percentage}% ${percentage}%)`
              : theme.palette.background.paper,
          borderRadius: "7px",
          border: (theme) => "1px solid" + theme.palette.grey[500],
        }}
      />
    );
  };

  const getVisibilityIcon = () => {
    switch (visibleToUser) {
      case true:
        return (
          <Tooltip
            title="Client is able to view this process"
            placement="top"
            arrow
          >
            <Visibility sx={{ mt: "10px" }} />
          </Tooltip>
        );
      case false:
        return (
          <Tooltip
            title="Client is not able to view this process"
            placement="top"
            arrow
          >
            <VisibilityOff sx={{ mt: "10px" }} />
          </Tooltip>
        );
    }
  };
  return (
    <Box
      sx={{
        width: "70%",
        my: 2,
        px: 2,
        py: 1,
        border: "1px solid",
        borderColor: (theme) => theme.palette.grey[500],
        borderRadius: "10px",
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      {getStatusIcon()}
      <Box
        sx={{
          flex: 1,
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {userType === AccountProfileViewer.User && (
          <Link
            sx={{
              margin: 0,
              padding: 0,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            color="text.primary"
            underline="hover"
            onClick={onClick}
          >
            {name}
          </Link>
        )}
        {userType === AccountProfileViewer.Admin && (
          <Typography
            sx={{
              margin: 0,
              padding: 0,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {name}
          </Typography>
        )}
        <Typography
          sx={{
            margin: 0,
            padding: 0,
            fontSize: "small",
            color: (theme) => theme.palette.grey[600],
          }}
        >
          {description}
        </Typography>
      </Box>
      {userType == AccountProfileViewer.Admin && getVisibilityIcon()}
      <Box
        sx={{
          width: "15%",
          minWidth: "75px",
          height: "25px",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Typography
          sx={{
            position: "relative",
            height: 0,
            margin: 0,
            top: "0.2em",
            fontSize: "0.8em",
            fontWeight: "bold",
            zIndex: 1,
          }}
        >
          {completedTasks}/{totalTasks}
        </Typography>
        {getProgressBar()}
      </Box>
    </Box>
  );
};

export default ProcessBox;
