import {
  Box,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import {
  FormDefinitionType,
  RadioFormElement,
  SelectionOption,
  TaskExecutionState,
} from "../../../../types";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";
import FormExecutionComponent, {
  FormExecutionComponentProps,
} from "../FormExecutionComponent";

type SelectionOptionWithElements = SelectionOption & {
  elements?: Array<FormDefinitionType>;
};

type TaskRadioFormType = {
  formId?: string;
  disabled: boolean;
  onValueChange: (value: SelectionOptionWithElements) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution: any;
  formExecutionComponentProps: FormExecutionComponentProps;
} & RadioFormElement;

const TaskRadioFormComponent: FC<TaskRadioFormType> = ({
  execution,
  formId,
  required,
  options,
  label,
  formExecutionComponentProps,
  taskExecutionState,
  onValueChange,
  ...props
}) => {
  const [selection, setSelection] = useState<SelectionOptionWithElements>(
    execution?.value ?? execution?.selection,
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const foundOption = options.find(({ id }) => id == e.target.id);
    const newValue: SelectionOptionWithElements = {
      id: e.target.id,
      name: e.target.value,
      elements: foundOption?.elements,
    };
    submitValue(newValue);
  };

  const submitValue = useCallback(
    (newValue: SelectionOptionWithElements) => {
      setSelection(newValue);
      onValueChange(newValue);
    },
    [onValueChange],
  );

  // useEffect(() => {
  //   if (execution?.selection) {
  //     // selection is used for changes made to the component itself
  //     submitValue(execution?.selection);
  //   } else if (execution?.value) {
  //     // value is what we store in the execution version of this object
  //     submitValue(execution?.value);
  //   }
  // }, [execution, execution?.selection, execution?.value, submitValue]);

  // Readonly view
  if (props.disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <>
        <Grid item container gap={1} alignItems={"center"}>
          <Typography variant="subtitle1">{label}:</Typography>
          <Typography>{execution?.value?.name || execution?.value}</Typography>
        </Grid>
        {execution?.value?.elements && (
          <Box>
            <FormExecutionComponent
              {...formExecutionComponentProps}
              elements={selection.elements}
              executionElements={selection.elements}
              hideCompleteButton
              hideEditButton
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <Grid item>
      <RadioGroup
        id={formId}
        onChange={handleOnChange}
        value={
          (props.disabled
            ? execution?.value?.name || execution?.value
            : selection?.name) ?? null
        }
      >
        <FormLabel required={required}>{label}</FormLabel>
        <FormElementTaskDescription>
          {props.description}
        </FormElementTaskDescription>
        {options.map(({ id, name }) => {
          // selection should be used here when possible to preserve nested form data
          return (
            <React.Fragment key={id}>
              <FormControlLabel
                id={id}
                key={id}
                value={name}
                control={<Radio disabled={props.disabled} id={id} />}
                label={<Chip label={name} sx={{ lineHeight: "15px" }} />} // Currently all the same color
              />
              {selection?.id == id && selection.elements && (
                <Box>
                  <FormExecutionComponent
                    {...formExecutionComponentProps}
                    elements={selection.elements}
                    executionElements={selection?.elements ?? []}
                    hideCompleteButton
                    hideEditButton
                  />
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </Grid>
  );
};

export default TaskRadioFormComponent;
