import { FilePresent } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useAlert } from "../../lib/alert";
import { FileUploadResponse } from "../../transfer/FileUploadResponse";
import downloadApiFile from "../../util/downloadApiFile";

type DownloadableAttachmentProps = {
  file: FileUploadResponse;
};

function getFileName(fragment: string) {
  const parts = fragment.split("/");
  return parts[parts.length - 1];
}

const DownloadableAttachment = ({ file }: DownloadableAttachmentProps) => {
  const { handleRejectionWithError } = useAlert();
  const theme = useTheme();

  const handleDownload = () => {
    fetch(
      `${process.env.REACT_APP_REST_API}/files/${encodeURIComponent(file.key)}`,
    )
      .then((response) => response.blob())
      .then((blob) => downloadApiFile(blob, getFileName(file.key)))
      .catch(handleRejectionWithError);
  };

  return (
    <Box
      onClick={handleDownload}
      sx={{
        cursor: "pointer",
        display: "flex",
        width: "100%",
        alignItems: "center",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      }}
    >
      <FilePresent
        fontSize="large"
        sx={{
          mr: 1,
        }}
      />
      <Typography
        sx={{
          textDecorationLine: "underline",
        }}
      >
        {getFileName(file.key)}
      </Typography>
    </Box>
  );
};

export default DownloadableAttachment;
