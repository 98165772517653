import WebSocketMessage, {
  WebSocketNotificationCancelData,
} from "../../model/WebSocketMessage";
import { cancelNotification } from "../notification";

/**
 * Handles a PUSH_NOTIFICATION_CANCEL event from the server
 * Cancels the notification with the given id
 * Uses the native notification implementation, so each platform will have different behavior
 */
export const handleCancelNotification = async (message: WebSocketMessage) => {
  // do something

  // in the message, expect the properties:
  // - title: string
  // - body: string
  // - icon: string

  // try to convert into WebSocketNotificationData
  try {
    const cancelNotificationData =
      message.data as WebSocketNotificationCancelData;
    cancelNotification(cancelNotificationData.id);
  } catch (e) {
    console.error(e);
  }
};
