import { TextField, Typography } from "@mui/material";
import { useState } from "react";
import { UserSelection } from "../../../../components/core/Selection";
import { UserOrInvitedUser } from "../../../../components/core/Selection/UserSelection";
import Dialog from "../../../../components/Dialog";
import useFeature from "../../../../hooks/useFeature";

type RoleCreationDialogType = {
  open: boolean;
  roleName: string;
  organizationId: string;
  handleConfirm: (
    roleName: string,
    selectedUsers: Partial<UserOrInvitedUser>[],
  ) => void;
  handleCancel: () => void;
  setNewRoleName: (newRoleName: string) => void;
};

const RoleCreationDialog = ({
  open,
  roleName,
  organizationId,
  handleConfirm,
  handleCancel,
  setNewRoleName,
}: RoleCreationDialogType) => {
  const [selectedUsers, setSelectedUsers] = useState<
    Array<Partial<UserOrInvitedUser> & { id: string }>
  >([]);

  const canManageRoles = useFeature("roles.administration");
  if (!canManageRoles) {
    handleCancel();
  }

  return (
    <Dialog
      open={open}
      maxWidth={"xs"}
      title={
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {"Create New Role"}
        </Typography>
      }
      confirmText="Save"
      cancelText="Cancel"
      handleConfirm={async () => {
        handleConfirm(roleName, selectedUsers);
      }}
      handleCancel={() => {
        setSelectedUsers([]);
        handleCancel();
      }}
    >
      <TextField
        autoFocus
        required
        margin="dense"
        id="role-title"
        label="Role Title"
        type="text"
        fullWidth
        variant="outlined"
        value={roleName}
        onChange={(event) => {
          setNewRoleName(event.target.value as string);
        }}
      />
      <Typography
        variant="h4"
        sx={{ pl: 0, pr: 0, pt: 2, pb: 1, fontWeight: 700 }}
      >
        {"Fill this Role (optional)"}
      </Typography>
      <Typography component="h2" variant="body2" sx={{ marginBottom: 2 }}>
        {
          "Select an existing user or invite a new one. This can always be done later on the User page."
        }
      </Typography>

      <UserSelection
        organizationId={organizationId}
        onInvite={(invitation) => {
          setSelectedUsers((prev) => [
            ...(prev ?? []),
            { id: invitation.target_user_id, invitationId: invitation.id },
          ]);
        }}
        AutoCompleteProps={{
          renderInput: (params) => (
            <TextField {...params} label={"Name or Email Address"} />
          ),
          multiple: true,
          value: (users) => {
            return selectedUsers
              .map(({ id }) => users.find((user) => user.id == id))
              .filter((u): u is UserOrInvitedUser => !!u);
          },
          onChange: (_, users) => {
            setSelectedUsers(
              (users as UserOrInvitedUser[]).map((user) => ({
                id: user.id,
                invitationId: user.invitationId,
              })),
            );
          },
        }}
      />
    </Dialog>
  );
};

export default RoleCreationDialog;
