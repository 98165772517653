import { CircularProgress, Grid } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProcessAPI, ProcessExecutionAPI } from "../../../../api";
import PATHS from "../../../../components/navigation/_paths";
import { useAlert } from "../../../../lib/alert";
import { ExecutionState } from "../../../../model/Process";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import {
  AccountPageContext,
  AccountProfileViewer,
} from "../../AccountProfilePage";
import AccountProfileProgramBlank from "./AccountProgramBlank";
import ProcessBox from "./ProcessBox";

type ProgramCardInfo = {
  id: string;
  name: string;
  description?: string;
  completedTasks: number;
  totalTasks: number;
  visibleToUser: boolean;
  status?: ExecutionState;
  processId: string;
  executionId?: string;
};

const AccountProfileProgramViewer = () => {
  const navigate = useNavigate();
  const { handleRejectionWithWarning } = useAlert();
  const [programCards, setProgramCards] = useState<ProgramCardInfo[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { account, accountTypeProcesses, programs, userType } =
    useContext(AccountPageContext);

  const loadCardInformation = useCallback(async () => {
    const newProgramCards = [];
    for (const program of programs) {
      // create the card using the program and execution,
      // if no execution exists, load the process and tasks to create the card

      if (program.execution_id) {
        const execution = await ProcessExecutionAPI.getDto(
          program.execution_id,
        );
        newProgramCards.push({
          id: program.id,
          name: execution.process_name,
          description: execution.process_description,
          completedTasks: parseInt(execution.completed_tasks ?? "0"),
          totalTasks: parseInt(execution.total_tasks ?? "0"),
          visibleToUser: program.visible,
          status: execution.state,
          processId: execution.process_id,
          executionId: execution.id,
        });
      } else {
        const process = accountTypeProcesses.find(
          (process) => process.meta === program.process_meta,
        );
        if (process) {
          const tasks = await ProcessAPI.getTasks(process.id);
          newProgramCards.push({
            id: program.id,
            name: process.name,
            description: process.description,
            completedTasks: 0,
            totalTasks: tasks.length,
            visibleToUser: program.visible,
            processId: process.id,
          });
        }
      }
    }

    setProgramCards(newProgramCards);
    setIsLoaded(true);
  }, [accountTypeProcesses, programs]);

  useEffect(() => {
    loadCardInformation();
  }, [loadCardInformation]);

  return (
    <Grid style={{ textAlign: "center", height: "100%" }}>
      {isLoaded ? (
        programCards.length > 0 ? (
          <ul style={{ listStyleType: "none" }}>
            {programCards.map((card) => {
              if (
                userType == AccountProfileViewer.Admin ||
                (userType == AccountProfileViewer.User && card.visibleToUser)
              ) {
                return (
                  <li key={card.id}>
                    <ProcessBox
                      name={card.name}
                      description={card.description}
                      completedTasks={card.completedTasks}
                      totalTasks={card.totalTasks}
                      visibleToUser={card.visibleToUser}
                      userType={userType}
                      status={card.status}
                      onClick={async () => {
                        if (card.executionId) {
                          navigate(
                            userType == AccountProfileViewer.User
                              ? PATHS.ACCOUNT_EXECUTION.linkTo(
                                  "",
                                  card.executionId,
                                )
                              : PATHS.EXECUTION.linkTo(
                                  card.processId,
                                  card.executionId,
                                ),
                          );
                        } else {
                          ProcessExecutionAPI.start(card.processId, {
                            name: account?.account_name
                              ? `${account?.account_name} - ${card.name}`
                              : card.name,
                            subject_id: account?.id,
                            parameters: {
                              account: account?.id,
                            },
                          }).then(
                            (processExecution: ProcessExecution) => {
                              navigate(
                                userType == AccountProfileViewer.User
                                  ? PATHS.ACCOUNT_EXECUTION.linkTo(
                                      "",
                                      processExecution.id,
                                    )
                                  : PATHS.EXECUTION.linkTo(
                                      processExecution.process_id,
                                      processExecution.id,
                                    ),
                              );
                            },
                            (err) => {
                              handleRejectionWithWarning(
                                `Could not start program "${card.name}"`,
                              )(err);
                            },
                          );
                        }
                      }}
                    />
                  </li>
                );
              }
            })}
          </ul>
        ) : (
          <AccountProfileProgramBlank
            accountName={account?.account_name ?? "this account"}
            onButtonClick={() => {
              navigate(`./editor`);
            }}
          />
        )
      ) : (
        <CircularProgress sx={{ mt: 25 }} />
      )}
    </Grid>
  );
};

export default AccountProfileProgramViewer;
