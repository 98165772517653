import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import {
  MultiSelectionFormElement,
  SelectionOption,
  TaskExecutionState,
} from "../../../../types";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";

type TaskMultiSelectFormType = {
  formId?: string;
  disabled: boolean;
  onValueChange: (value: Array<SelectionOption>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution?: any;
} & MultiSelectionFormElement;

const TaskMultiSelectFormComponent: FC<TaskMultiSelectFormType> = ({
  formId,
  required,
  options,
  label,
  execution,
  onValueChange,
  taskExecutionState,
  ...props
}) => {
  const [checked, setChecked] = useState<Array<SelectionOption>>(
    execution?.value || execution?.selection || [],
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const selectedItem: SelectionOption = {
      id: e.target.id,
      name: e.target.value,
    };

    let newValue = [];
    if (isChecked) {
      // Add the checked value to the array of checked items
      newValue = [...(checked || []), selectedItem];
    } else {
      // Remove the checked value from the array of checked values
      newValue = checked.filter((item) => item.id !== selectedItem.id);
    }
    submitValue(newValue);
  };

  const submitValue = useCallback(
    (newValue: Array<SelectionOption>) => {
      setChecked(newValue);
      onValueChange(newValue);
    },
    [onValueChange],
  );

  /*useEffect(() => {
    if (execution?.selection) {
      // selection is used for changes made to the component itself
      submitValue(execution.selection);
    } else if (execution?.value) {
      // value is what we store in the execution version of this object
      submitValue(execution.value);
    }
  }, [execution?.selection, execution?.value, submitValue]); */

  const isChecked = useCallback(
    (id: string) => {
      if (props.disabled) {
        return (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          execution?.value?.find((selection: any) => selection.id == id) != null
        );
      }
      return checked.find((selection) => selection.id == id) != null;
    },
    [checked, execution?.value, props.disabled],
  );

  const renderReadOnly = useCallback(() => {
    const checked = options.filter((option) => isChecked(option.id));
    return checked.map(({ id, name }, index) => {
      const isLastElement = index === checked.length - 1;
      return (
        <Typography key={id}>
          {name}
          {isLastElement ? "" : ","}
        </Typography>
      );
    });
  }, [isChecked, options]);

  // Readonly view
  if (props.disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <Grid item container gap={1} alignItems={"center"}>
        <Typography variant="subtitle1">{label}:</Typography>
        {renderReadOnly()}
      </Grid>
    );
  }

  return (
    <Grid item>
      <FormGroup id={formId} onChange={handleOnChange}>
        <FormLabel required={required}>{label}</FormLabel>
        <FormElementTaskDescription>
          {props.description}
        </FormElementTaskDescription>
        {options.map(({ id, name }) => {
          const checked: boolean = isChecked(id);
          return (
            <FormControlLabel
              key={id}
              value={name}
              label={<Typography sx={{ fontSize: "14px" }}>{name}</Typography>}
              control={
                <Checkbox id={id} disabled={props.disabled} checked={checked} />
              }
            />
          );
        })}
      </FormGroup>
    </Grid>
  );
};

export default TaskMultiSelectFormComponent;
