import { Cancel } from "@mui/icons-material";
import { Chip, SvgIcon, SxProps } from "@mui/material";
import { lazy, Suspense } from "react";

const DynamicIcon = lazy(() => import("../../../components/core/DynamicIcon"));

const ProcessChip = ({
  icon,
  name,
  onClick,
  onDelete,
  sx,
}: {
  icon?: string;
  name: string;
  onClick?: () => void;
  onDelete?: () => void;
  sx?: SxProps;
}) => {
  return (
    <Chip
      icon={
        icon ? (
          <Suspense fallback={<SvgIcon />}>
            <DynamicIcon name={icon} />
          </Suspense>
        ) : undefined
      }
      component="a"
      deleteIcon={onDelete ? <Cancel /> : undefined}
      onClick={onClick}
      onDelete={onDelete}
      label={name}
      sx={{
        color: (theme) => theme.palette.text.secondary,
        paddingLeft: "4px",
        marginLeft: "4px",
        ...sx,
      }}
    />
  );
};
export default ProcessChip;
