import { ArrowDropDown, PlayCircle } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import { useEffect, useState } from "react";
import { Process, ProcessExecution } from "../../../../model/Process";

interface ProcessQuickDropdownProps {
  processes: Array<Process>;
  handleStart: (process: Process) => void;
  sx?: SxProps<Theme>;
  lastProcessExecution?: ProcessExecution;
}
export const ProcessQuickDropdown = ({
  processes,
  handleStart,
  ...props
}: ProcessQuickDropdownProps) => {
  // Preferences
  const autoLaunchProcess = true; // If true, launch process automatically when selected from menu

  // Menu Props
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };
  const menuProps = processes.map((p) => (
    <MenuItem key={p.meta} onClick={() => handleSelect(p)}>
      {p.name}
    </MenuItem>
  ));
  const handleClose = () => {
    setAnchor(null);
  };
  const handleSelect = (process: Process) => {
    if (autoLaunchProcess) {
      handleStart(process);
      return;
    }
    setSelectedProcess(process);
    handleClose();
  };

  // Button Props
  const [selectedProcess, setSelectedProcess] = useState<Process | undefined>(
    undefined,
  );
  const startProcess = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedProcess) {
      handleStart(selectedProcess);
    } else {
      setAnchor(event.currentTarget);
    }
  };
  useEffect(() => {
    if (props.lastProcessExecution) {
      const process = processes.find((process) => {
        return process.meta == props.lastProcessExecution?.process_id;
      });

      if (process) {
        setSelectedProcess(process);
      }
    } else {
      setSelectedProcess(undefined);
    }
  }, [processes, props.lastProcessExecution]);

  // Show full dropdown if there are previous process executions
  if (selectedProcess) {
    return (
      <Box sx={{ whiteSpace: "nowrap", ...props.sx }}>
        <Button
          variant="contained"
          onClick={startProcess}
          startIcon={<PlayCircle />}
          sx={{
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            height: "auto",
            boxShadow: 0,
            //border: "1.5px solid black",
            borderRight: "1px solid #050588",
          }}
        >
          {selectedProcess?.name || "Select Flight Plan"}
        </Button>

        <Button
          id="process-dropdown-button"
          onClick={handleClick}
          variant="contained"
          aria-controls={isOpen ? "process-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          sx={{
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            //border: "1.5px solid black",
            borderLeft: 0,
            my: 0,
            px: 0.5,
            minWidth: 0,
            boxShadow: 0,
            height: "auto",
          }}
        >
          {/* Without this the buttons are offset by half of a pixel. */}
          <ArrowDropDown sx={{ mt: "0.5px" }} />
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={isOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "process-dropdown-button",
          }}
        >
          {menuProps}
        </Menu>
      </Box>
    );
  } else {
    // Otherwise show limited dropdown.
    return (
      <Box sx={{ whiteSpace: "nowrap", ...props.sx }}>
        <Button
          variant="contained"
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
          sx={{ height: "auto" }}
        >
          {"Start a Flight Plan"}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={isOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "process-dropdown-button",
          }}
        >
          {menuProps}
        </Menu>
      </Box>
    );
  }
};
