import { Box, Tabs } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { useAccountProgramTab } from "./hooks/useAccountProgramTab";
import useOrganizationAccountPage, {
  AccountPageContext,
} from "./hooks/useOrganizationAccountPage";
import { OrganizationAccountPageTab } from "./OrganizationAccountPageTab";
import { AccountProfileViewer, AccountSubpage } from "./types";

type AccountProfilePageProps = {
  viewer: AccountProfileViewer;
};

const OrganizationAccountPage = ({ viewer }: AccountProfilePageProps) => {
  const { pathname } = useLocation();
  const accountPageContext = useOrganizationAccountPage(viewer);
  const { showProgramsTab } = useAccountProgramTab(
    viewer,
    accountPageContext?.programs,
  );

  if (!accountPageContext) {
    return <></>;
  }

  const currentTab = Object.values(AccountSubpage).find((path) => {
    if (path == AccountSubpage.Programs && !showProgramsTab) {
      return false;
    }
    return pathname.endsWith(`/${path}`);
  });

  if (!accountPageContext?.account) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", width: "400px" }}>
        <Tabs value={currentTab ?? false} sx={{ flexGrow: 1, ml: 4 }}>
          <OrganizationAccountPageTab
            label={`Flights`}
            value={AccountSubpage.Flights}
          />
          <OrganizationAccountPageTab
            label={`Profile`}
            value={AccountSubpage.Profile}
          />
          <OrganizationAccountPageTab
            label={`Fields`}
            value={AccountSubpage.Fields}
          />
          {showProgramsTab && (
            <OrganizationAccountPageTab
              label={`Programs`}
              value={AccountSubpage.Programs}
            />
          )}
        </Tabs>
      </Box>
      <AccountPageContext.Provider value={accountPageContext}>
        <Box flex={1}>
          <Outlet />
        </Box>
      </AccountPageContext.Provider>
    </>
  );
};

export default OrganizationAccountPage;
