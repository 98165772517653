import {
  ArticleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDownloadOutlined,
  PlayCircleFilled,
  UploadFileOutlined,
} from "@mui/icons-material";
import { Box, Button, Link, Tooltip } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import debounce from "lodash.debounce";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedDate, useIntl } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import sanitize from "sanitize-filename";
import { ProcessAPI, ProcessExecutionAPI } from "../../../api";
import Processes from "../../../api/processes";
import { DeleteDialog } from "../../../components/dialogs";
import { CommonDatagridWrapper } from "../../../components/elements";
import DatagridToolbar from "../../../components/elements/datagrid/DatagridToolbar";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";
import ToolbarButton from "../../../components/elements/datagrid/ToolbarButton";
import { PageHeaderProps } from "../../../components/layout/page/PageHeader";
import PATHS from "../../../components/navigation/_paths";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { useAlert } from "../../../lib/alert";
import { Organization } from "../../../model";
import { Process, ProcessStatus } from "../../../model/Process";
import {
  ExecutionType,
  ProcessExecution,
} from "../../../model/ProcessExecution";
import messages from "../messages";
import ImportProcessDialog from "./ImportProcessDialog";

type OrganizationProcessesProps = {
  currentOrganization: Organization | undefined;
  fetch: () => Promise<Process[]>;
  onDataLoaded?: (processes: Process[]) => void;
  isDirty?: number;
  PageHeaderProps?: PageHeaderProps;
  title?: string;
  extraColumns?: Array<GridColDef>;
};

const OrganizationProcesses: FC<OrganizationProcessesProps> = ({
  title,
  fetch,
  PageHeaderProps,
  onDataLoaded,
  extraColumns,
  ...props
}) => {
  const intl = useIntl();
  const { handleRejectionWithError, handleRejectionWithWarning } = useAlert();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processList, setProcessList] = useState<Array<Process>>([]);

  const [processExecutionSelectionModel, setProcessExecutionSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [numRowsSelected, setNumRowsSelected] = useState<number>(0);

  const [importProcessDialogOpen, setImportProcessDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const processesName = intl.formatMessage(messages.processes.props);
  const processName = intl.formatMessage(messages.process.props);
  const pageTitle = title ?? processesName;

  const canAdministrate = useIsAdmin();
  if (!canAdministrate) {
    navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
  }

  // TODO: Need to pull column definition out of the fields that the
  // process_execution object exposes by API

  const handleExecuteProcessRequest = (
    metaOrId: string,
    name: string,
    execution_type = ExecutionType.Live,
  ) => {
    const configuration = { name: name, execution_type };
    ProcessExecutionAPI.start(metaOrId, configuration as ProcessExecution).then(
      (processExecution: ProcessExecution) => {
        navigate(
          PATHS.EXECUTION.linkTo(
            processExecution.process_id,
            processExecution.id,
          ),
        );
      },
      (err) => {
        handleRejectionWithWarning(
          `Could not start process execution for process ${metaOrId}`,
        )(err);
      },
    );
  };

  // TODO: break out into separate component
  const processListColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${processName} Name`,
      sortable: true,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Link
            underline="hover"
            key={params.row.id}
            component={RouterLink}
            to={PATHS.PROCESS.linkTo(params.row.id)}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "category_name",
      headerName: "Category", // TODO implement https://github.com/creative-mines/code/issues/255
      sortable: true,
      flex: 1,
      width: 200,
      maxWidth: 250,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "created_at",
      type: "date",
      // This is labled last updated even though it's reading the created date
      // because whenever a process is published a new version is created (with a new created_at).
      // The column therefore is actually closer to a "Last Updated" value than
      // the original creation date of the first version of the process which
      // is what "Created At" would imply.
      headerName: "Last Updated",
      sortable: true,
      width: 140,
      valueGetter: (value) => new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FormattedDate
            value={params.row.created_at}
            year="numeric"
            month="long"
            day="numeric"
          />
        );
      },
    },
    ...(extraColumns ?? []),
    {
      field: "edit_action",
      width: 150,
      headerName: "",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            {localStorage.getItem("runway.singleProcess") && (
              <Tooltip title="View All Executions">
                <Button
                  onClick={async () => {
                    const mostRecentProcessId = (
                      await Processes.fetchLatest(params.row.meta)
                    ).id;
                    navigate(`../../executions/${mostRecentProcessId}/table`);
                  }}
                >
                  <ArticleOutlined />
                </Button>
              </Tooltip>
            )}
            {params.row.status === ProcessStatus.Draft && (
              <Button
                sx={{ ml: 1, width: "100px" }}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // don't select this row after clicking
                  handleExecuteProcessRequest(
                    params.row.meta ?? params.row.id,
                    params.row.name,
                  );
                }}
                startIcon={<PlayCircleFilled></PlayCircleFilled>}
              >
                Start
              </Button>
            )}
            {params.row.status === ProcessStatus.Unpublished && (
              <Button
                sx={{
                  ml: 1,
                  pl: 0,
                  width: "100px",
                  color: "text.secondary",
                }}
                variant={"text"}
                color="inherit"
                onClick={() =>
                  handleExecuteProcessRequest(
                    params.row.id,
                    `TEST ${params.row.name}`,
                    ExecutionType.Test,
                  )
                }
                startIcon={<PlayCircleFilled></PlayCircleFilled>}
              >
                Test
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const fetchProcesses = useCallback(() => {
    return fetch().then(
      (processes: Array<Process>) => {
        // TODO - filtering should be done on server side
        const filteredProcesses = processes.filter(
          (p) => p.status !== ProcessStatus.Deleted,
        );
        filteredProcesses.map((p) => p.created_by_user_id);
        setProcessList(filteredProcesses);
        return filteredProcesses;
      },
      (err) => {
        handleRejectionWithWarning("Could not load processes for organization")(
          err,
        );
        return [];
      },
    );
  }, [handleRejectionWithWarning, fetch]);

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          fetchProcesses().then((result) => {
            // Cancel any queued calls now that we've finished loading
            reload.cancel();

            setProcessList(result);
            setIsLoaded(true);
            onDataLoaded?.(result);
          });
        },
        5000,
        { leading: true },
      ),
    [onDataLoaded, fetchProcesses],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    if (props.isDirty && props.currentOrganization) {
      reload && reload();
    }
  }, [props.currentOrganization, props.isDirty, reload]);

  const importProcessBtn = (
    <ToolbarButton
      dataCy="import-process-btn"
      key="importBtn"
      startIcon={<UploadFileOutlined />}
      onClick={() => setImportProcessDialogOpen(true)}
      textContent="Import"
    />
  );

  const editBtn = (
    <ToolbarButton
      dataCy="edit-process-btn"
      key="editBtn"
      startIcon={<EditOutlined />}
      disabled={numRowsSelected == 1 ? false : true}
      onClick={() =>
        navigate(
          PATHS.PROCESS.linkTo(processExecutionSelectionModel.toString()),
        )
      }
      textContent="Edit"
    />
  );

  const exportBtn = (
    <ToolbarButton
      dataCy="export-process-btn"
      key="exportBtn"
      startIcon={<FileDownloadOutlined />}
      onClick={() => {
        processExecutionSelectionModel.forEach(async (processId) => {
          const processData = await ProcessAPI.export(processId as string);
          const url = window.URL.createObjectURL(processData);
          const link = document.createElement("a");
          link.href = url;
          const processName =
            processList.find((p) => p.id === processId)?.name ?? "process";
          const fileName = `${sanitize(processName).replace(/,/g, "-")}.json`;
          link.setAttribute("download", fileName);
          link.click();
          setTimeout(() => window.URL.revokeObjectURL(url), 0);
        });
      }}
      textContent="Export"
    />
  );

  const deleteBtn = (
    <ToolbarButton
      dataCy="delete-process-btn"
      key="deleteBtn"
      startIcon={<DeleteOutlined />}
      onClick={() => setDeleteDialogOpen(true)}
      textContent="Delete"
    />
  );

  const processDataGrid = (
    <StyledDataGrid
      slots={{ toolbar: DatagridToolbar }}
      slotProps={{
        toolbar: {
          countMsg:
            processList.length == 1
              ? `${processList.length} ${processName}`
              : `${processList.length} ${processesName}`,
          numSelected: numRowsSelected,
          defaultBtns: importProcessBtn,
          selectedBtns: (
            <>
              {editBtn}
              {exportBtn}
              {deleteBtn}
            </>
          ),
        },
      }}
      disableColumnSelector
      hideFooterSelectedRowCount
      checkboxSelection
      disableRowSelectionOnClick
      rows={processList}
      columns={processListColumns}
      columnVisibilityModel={{
        id: false,
        description: false,
      }}
      pageSizeOptions={[10]}
      onRowSelectionModelChange={(newSelectionModel) => {
        setProcessExecutionSelectionModel(newSelectionModel);
        setNumRowsSelected(newSelectionModel.length);
      }}
      rowSelectionModel={processExecutionSelectionModel}
    />
  );

  return (
    <>
      <CommonDatagridWrapper
        loaded={processDataGrid}
        isLoaded={isLoaded}
        PageHeaderProps={PageHeaderProps}
        title={pageTitle}
      />
      {importProcessDialogOpen && (
        <ImportProcessDialog
          key="importProcessDialog"
          open={importProcessDialogOpen}
          handleCancel={() => setImportProcessDialogOpen(false)}
          handleConfirm={async () => {
            setImportProcessDialogOpen(false);
            reload();
          }}
        ></ImportProcessDialog>
      )}
      {deleteDialogOpen && (
        <DeleteDialog
          key="deleteDialog"
          title={
            processExecutionSelectionModel.length > 1
              ? `Delete ${processesName}`
              : `Delete ${processName}`
          }
          question={`Are you sure you want to delete ${
            processExecutionSelectionModel.length === 1
              ? `the "${
                  processList.find(
                    (p) => p.id === processExecutionSelectionModel[0],
                  )?.name
                }" ${processName}`
              : `these ${processExecutionSelectionModel.length} ${processesName}`
          }?`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={() => {
            return Promise.all(
              processExecutionSelectionModel.map((processId) =>
                ProcessAPI.delete(processId as string),
              ),
            ).then(
              () => {
                reload();
              },
              handleRejectionWithError(
                `One or more ${processesName} could not be deleted`,
              ),
            );
          }}
        />
      )}
    </>
  );
};

export default OrganizationProcesses;
