import * as React from "react";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { mapCognitoError } from "../../../lib/cognito";
import { GoogleAuthButton } from "./GoogleAuth";
import { createNewPasswordChallenge, login } from "../../../lib/auth";
import { currentUserId } from "../../../lib/auth";
import { UserAPI } from "../../../api";
import { User } from "../../../model";

type RegisterComponentProps = {
  includeFooter?: boolean;
  disabled?: boolean;
  email?: string;
  cognitoUser?: CognitoUser;
  sx?: SxProps;
  onComplete: (user: User) => void;
  onClickLogin?: () => void;
};

export default function RegisterComponent({
  onComplete,
  onClickLogin,
  ...props
}: RegisterComponentProps) {
  const navigate = useNavigate();

  // This is set to true immediately to prevent flashing from the false to true
  // state when we begin attempting to authenticate
  const [authenticating, setAuthenticating] = useState<boolean>(
    props.disabled ? false : true,
  );
  const [error, setError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const [registered, setRegistered] = useState<boolean>(false);

  // Handle redirects from the verification page
  useEffect(() => {
    if (props.disabled) return;
    setAuthenticating(true);
    login().then(
      (result) => {
        if (result.success) {
          UserAPI.get(currentUserId()).then((user) => {
            onComplete(user);
            setRegistered(true);
            setAuthenticating(false);
          });
        }
      },
      () => {
        // Do nothing, this indicates that the user does not already have a session
        // which is not a problem
        setAuthenticating(false);
      },
    );
  }, [onComplete, props.disabled]);

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    console.error("RegisterPage: cognito user pool id was not defined");
    return <Navigate to={"/"} />;
  }

  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    console.error("RegisterPage: clientId was not defined");
    return <Navigate to={"/"} />;
  }

  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  };

  const userPool = new CognitoUserPool(poolData);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const firstNameValue = data.get("firstname");
    if (!firstNameValue) {
      setError("First name is required");
      return;
    }

    const lastNameValue = data.get("lastname");
    if (!lastNameValue) {
      setError("Last name is required");
      return;
    }

    const rawEmailValue = props.email ?? data.get("email");
    if (!rawEmailValue) {
      setError("Invalid email address");
      return;
    }
    // Ensure that the email is lowercase to avoid issues with a
    // case-sensitive cognito user pool
    const emailValue = rawEmailValue.toString().toLowerCase();

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: emailValue,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: firstNameValue.toString(),
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastNameValue.toString(),
      }),
    ];

    const passwordValue = data.get("password");
    if (!passwordValue) {
      setError("Invalid password");
      return;
    }

    setAuthenticating(true);

    // If a cognito user for this email already exists, update the password
    if (props.cognitoUser) {
      createNewPasswordChallenge(
        props.cognitoUser,
        firstNameValue.toString(),
        lastNameValue.toString(),
        passwordValue.toString(),
      )
        .then((result) => {
          if (result.success) {
            UserAPI.get(currentUserId())
              .then((user) => {
                onComplete(user);
                setRegistered(true);
              })
              .finally(() => setAuthenticating(false));
          }
        })
        .catch((err) => {
          setError(mapCognitoError(err));
        });
    } else {
      // If the cognito user for this email does not exist, sign them up
      userPool.signUp(
        emailValue,
        passwordValue.toString(),
        attributeList,
        [],
        function (err, result) {
          if (err) {
            setError(mapCognitoError(err));
            setAuthenticating(false);
            return;
          }

          if (!result) {
            setError("Unknown authentication error, please try again.");
            setAuthenticating(false);
            return;
          }

          const cognitoUser = result.user;
          setRegistered(true);
          setAuthenticating(false);

          navigate("/verify", {
            replace: true,
            state: {
              username: cognitoUser.getUsername(),
              password: passwordValue.toString(),
              origin: window.location.href,
            },
          });
        },
      );
    }
  };

  return (
    <Paper elevation={9} sx={{ ...props.sx, height: "fit-content" }}>
      {/* main content */}
      {authenticating ? (
        <Box
          sx={{
            width: "100%",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        !registered && (
          <Box sx={{ px: 4, py: 4 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography component="h1" variant="h4">
                Create Account
              </Typography>
              {onClickLogin && (
                <Typography
                  component="h2"
                  variant="body2"
                  sx={{ mt: 1, mb: 1.5 }}
                >
                  {"Already have an account? "}
                  <Link
                    onClick={onClickLogin}
                    component="button"
                    underline="hover"
                  >
                    Login now
                  </Link>
                </Typography>
              )}

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: onClickLogin ? 1 : 2 }}
              >
                {error && (
                  <Alert sx={{ width: "100%", mb: 1 }} severity="error">
                    {error}
                  </Alert>
                )}
                {/* form fields */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="firstname"
                      label="First Name"
                      name="firstname"
                      value={firstName}
                      autoComplete="firstname"
                      disabled={props.disabled}
                      onChange={(e) => setFirstName(e.target.value as string)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      name="lastname"
                      value={lastName}
                      autoComplete="lastname"
                      disabled={props.disabled}
                      onChange={(e) => setLastName(e.target.value as string)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={props.email ?? emailAddress}
                      disabled={props.disabled || !!props.email} // disabled from email since it cant be changed anyways
                      onChange={(e) =>
                        setEmailAddress(e.target.value as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      helperText="Passwords must be at least 8 characters and contain an uppercase letter, a lowercase letter, and a number."
                      autoComplete="new-password"
                      disabled={props.disabled}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, py: 1.5, textTransform: "capitalize" }}
                  disabled={props.disabled}
                >
                  Create Account
                </Button>

                <Divider>
                  <Typography component="p" variant="caption">
                    OR
                  </Typography>
                </Divider>
                <GoogleAuthButton
                  setAuthenticating={(state) => setAuthenticating(state)}
                  origin={`${window.location.origin}${window.location.pathname}`}
                  label="Create Account"
                />
              </Box>
            </Box>
          </Box>
        )
      )}

      {/* footer */}
      {props.includeFooter !== false && (
        <Box
          sx={{
            backgroundColor: grey[100],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography component="h2" variant="body2">
            <Link href="/terms.html" underline="hover" color="inherit">
              Terms &amp; Conditions
            </Link>{" "}
            &middot;{" "}
            <Link href="/privacy.html" underline="hover" color="inherit">
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      )}
    </Paper>
  );
}
