import {
  CheckBoxOutlineBlank,
  CheckCircle,
  LockOutlined,
  MoreVert,
  RadioButtonUnchecked,
  Speed,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  styled,
  Tooltip,
} from "@mui/material";
import { ValidationError } from "class-validator";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactQuillViewer from "../../../components/core/ReactQuill/ReactQuillViewer";
import { IconButtonDropdownMenu } from "../../../components/elements";
import useFeature from "../../../hooks/useFeature";
import { Role, User } from "../../../model";
import messages from "../../process/messages";
import { TaskExecution, TaskType } from "../types";
import { isAutomationRole } from "../utils/helpers";
import RoleContainer from "./RoleContainer";
import RetryTaskButton from "./execution/RetryTaskButton";

type TaskComponentProps = {
  label: JSX.Element | string;
  description?: string;
  type?: TaskType;
  assignedUser?: User;
  isComplete?: boolean;
  showChildren?: boolean;
  isLocked?: boolean;
  role?: Role;
  onClick?: () => void;
  icon?: JSX.Element;
  error?: boolean;
  retryErrorTask?: () => void;
  onShare?: () => void;
  onCopilot?: () => void;
  validationFailures?: ValidationError[];
  execution?: TaskExecution;
  processExecutionId?: string;
  requestTracking?: boolean;
};

const TaskContainerHeader = styled(Box)(({ theme }) => {
  return {
    alignItems: "center",
    padding: theme.spacing(0, 1, 1),
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
    },
  };
});

const TaskContainerChildren = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(0, 2),
    marginLeft: theme.spacing(4),
  };
});

const TaskContainer = styled(
  ({
    label,
    description,
    type,
    assignedUser,
    isComplete,
    showChildren: showChildrenOverride,
    isLocked,
    role,
    onClick: onClickOverride,
    icon: iconOverride,
    children,
    className,
    onShare,
    onCopilot,
    validationFailures,
    execution,
    processExecutionId,
    error,
    retryErrorTask,
    requestTracking,
  }: React.PropsWithChildren<TaskComponentProps> & {
    className?: string;
  }) => {
    const intl = useIntl();
    const executionMsg = intl.formatMessage(messages.execution.props);
    const [showChildren, setShowChildren] = useState<boolean>(false);
    const [shareDropdownClosed, setShareDropdownClosed] =
      useState<boolean>(true);

    const canTaskBeAssigned = useFeature("process.assigned");

    const onClick = useCallback(() => {
      if (onClickOverride) {
        onClickOverride();
      } else {
        setShowChildren((prev) => !prev);
      }
    }, [onClickOverride]);

    useEffect(() => {
      if (showChildrenOverride != undefined) {
        setShowChildren(showChildrenOverride);
      }
    }, [showChildrenOverride]);

    const icon = useMemo(() => {
      if (iconOverride) {
        return iconOverride;
      }
      return isLocked ? (
        <LockOutlined data-cy="task-lock" />
      ) : type == TaskType.Check ? (
        <CheckBoxOutlineBlank />
      ) : (
        <RadioButtonUnchecked />
      );
    }, [type, iconOverride, isLocked]);

    const checkedIcon = useMemo(() => {
      if (iconOverride) {
        return iconOverride;
      }
      return isLocked ? (
        <LockOutlined data-cy="task-lock" />
      ) : (
        <CheckCircle data-cy="completed-task-check" />
      );
    }, [iconOverride, isLocked]);

    return (
      <Box className={className}>
        <TaskContainerHeader>
          <FormGroup>
            <FormControlLabel
              onClick={onClick}
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={isComplete}
                  color={isComplete ? "success" : "default"}
                  disableRipple
                />
              }
              label={
                !!execution && type === TaskType.Check && !isComplete ? (
                  <></>
                ) : (
                  <p
                    style={{ fontSize: 15 }}
                    onClick={onClick}
                    className="task-title"
                  >
                    {label}
                  </p>
                )
              }
            />
          </FormGroup>
          {error && (
            <RetryTaskButton
              onClick={() => {
                retryErrorTask && retryErrorTask();
              }}
            />
          )}
          <Box sx={{ color: "common.black", display: "flex" }}>
            {requestTracking && (
              <Tooltip
                title={`This task has tracking enabled! You will be charged once this ${executionMsg} is complete.`}
              >
                <IconButton
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  <Speed sx={{ color: "common.black" }} />
                </IconButton>
              </Tooltip>
            )}
            {canTaskBeAssigned && (
              <RoleContainer
                role={role}
                assignedUser={assignedUser}
                hide={!type || isAutomationRole(role?.id)}
                showWarning={
                  !!validationFailures?.find(
                    (failure) => failure.property == "execution_role_id",
                  )
                }
                execution={execution}
                processExecutionId={processExecutionId}
              />
            )}
            {isComplete && onShare && (
              <Box
                sx={{
                  color: "common.black",
                  marginTop: "-4px",
                  marginRight: "-34px",
                }}
              >
                <IconButtonDropdownMenu
                  size="small"
                  element={<MoreVert />}
                  closed={shareDropdownClosed}
                  setClosed={setShareDropdownClosed}
                >
                  <MenuItem
                    onClick={(e) => {
                      setShareDropdownClosed(true);
                      e.stopPropagation();
                      onShare?.();
                    }}
                  >
                    Share Result
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setShareDropdownClosed(true);
                      e.stopPropagation();
                      onCopilot?.();
                    }}
                  >
                    Process with Copilot
                  </MenuItem>
                </IconButtonDropdownMenu>
              </Box>
            )}
          </Box>
        </TaskContainerHeader>
        {showChildren && (
          <TaskContainerChildren>
            {type != TaskType.Content && type != TaskType.Form && children}
            {!!description &&
              description != "<p><br></p>" &&
              description != "<p><br /></p>" && (
                <Paper
                  elevation={isLocked || isComplete ? 0 : 2}
                  sx={{
                    backgroundColor:
                      isLocked || isComplete ? "#F4F7F9" : "#E2EFF3",
                    color: isLocked || isComplete ? "#626364" : "#343434",
                    padding: "5px",
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  <ReactQuillViewer value={description} variant="regular" />
                </Paper>
              )}
            {(type == TaskType.Content || type == TaskType.Form) && children}
          </TaskContainerChildren>
        )}
      </Box>
    );
  },
)(({ theme, isLocked, isComplete, role, error }) => {
  const customStyles: Record<string, unknown> = {
    padding: "1rem 1rem 2rem 1rem",
    borderRadius: "4px",
    color: theme.palette.text.primary,
  };
  if (isLocked) {
    customStyles.color = theme.palette.text.disabled;
  }
  if (role?.id === "automation") {
    customStyles.backgroundColor = theme.palette.grey[200];
  }
  if (isComplete) {
    customStyles["p.task-title"] = {
      color: theme.palette.success.main,
    };
    if (role?.id === "automation") {
      customStyles.backgroundColor = "#F4F7F9";
    }
  }
  if (role?.id === "automation" && error) {
    customStyles.backgroundColor = "#E2C5BB";
  }
  return customStyles;
});

export default TaskContainer;
