import { InvitationProvider, UserProvider } from ".";
import Invitations from "../api/invitations";
import Organizations from "../api/organizations";
import Roles from "../api/roles";

const userProvider = {
  fetch: (organizationId: string) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Organizations.getMembers(organizationId).then((result) => {
      return result;
    });
  },
  removeFromOrganization: (organizationId: string, userId: string) => {
    return Organizations.removeMember(organizationId, userId);
  },
  assignRole: (roleId: string, userId: string) => {
    return Roles.assignRole(roleId, userId);
  },
  unassignRole: (roleId: string, userId: string) => {
    return Roles.unassignRole(roleId, userId);
  },
} as UserProvider;

const invitationProvider = {
  fetch: (organizationId: string) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Invitations.membersByOrganization(organizationId).then((result) => {
      return result;
    });
  },
  delete: (id: string) => {
    return Invitations.delete(id).then((result) => {
      return result;
    });
  },
  assignRole: (roleId: string, userId: string) => {
    return Invitations.assignRole(roleId, userId);
  },
  unassignRole: (roleId: string, invitationId: string) => {
    return Invitations.unassignRole(roleId, invitationId);
  },
} as InvitationProvider;

export { invitationProvider, userProvider };
