import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { CopilotChatAPI } from "../../api";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../lib/alert";
import Chat from "./Chat";
import Message from "./Message";
import TypingIndicator from "./TypingIndicator";

const ChatUI = () => {
  const [input, setInput] = React.useState("");
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [visible, setVisible] = React.useState(false);
  const [chat, setChat] = React.useState<Chat | undefined>(undefined);
  const [typing, setTyping] = React.useState(false);

  const { organization } = useGlobalOrganizationContext();
  const { error } = useAlert();

  const ref = React.useRef<HTMLDivElement | undefined>(null);

  const newChat = React.useCallback(async () => {
    if (!organization) {
      return;
    }

    setMessages([]);
    try {
      const responseChat = await CopilotChatAPI.createChat(organization.id);
      setChat(responseChat);
    } catch (e) {
      console.error("Error while creating chat", e);
      error("Failed to create new chat");
      return;
    }
  }, [error, organization]);

  const handleSend = React.useCallback(async () => {
    if (input.trim() === "" || !organization) {
      setInput("");
      return;
    }

    const message = {
      id: uuid(),
      data: {
        role: "user",
        content: input.trim(),
      },
    };

    let updatedMessages = [...messages, message];
    setMessages(updatedMessages);

    setInput("");

    let currentChatId = chat?.id;
    if (!currentChatId) {
      try {
        const responseChat = await CopilotChatAPI.createChat(organization.id);
        setChat(responseChat);
        currentChatId = responseChat.id;
      } catch (e) {
        console.error("Error while creating chat", e);
        error("Failed to create new chat");
        return;
      }
    }

    try {
      setTyping(true);
      const responseMessage = await CopilotChatAPI.sendMessage(
        organization.id,
        currentChatId,
        message,
      );
      updatedMessages = [...updatedMessages, responseMessage];
      setMessages(updatedMessages);
      setChat(await CopilotChatAPI.getChat(currentChatId));
    } catch (e) {
      console.error("Error while sending chat message", e);
      error("Failed to send message");
    } finally {
      setTyping(false);
    }
  }, [chat?.id, organization, input, messages, error]);

  React.useEffect(() => {
    setChat(undefined);
    setMessages([]);
  }, [organization]);

  React.useEffect(() => {
    if (messages.length) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages.length]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          right: 40,
        }}
      >
        <IconButton
          sx={{ bgcolor: "grey.300" }}
          color="primary"
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <ChatIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          borderRadius: "10px",
          boxShadow:
            "0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033",
          height: "500px",
          position: "fixed",
          bottom: 80,
          right: 40,
          bgcolor: "grey.200",
          display: visible ? "flex" : "none",
          flexDirection: "column",
          width: "500px",
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
          {messages.map((message) => (
            <MessageComponent key={message.id} message={message.data} />
          ))}
          {chat && chat.state === "END" && (
            <Typography>The current chat has ended</Typography>
          )}
          {chat && typing && (
            <Box
              sx={{
                height: "32px",
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              <TypingIndicator />
            </Box>
          )}
          <Box ref={ref} />
        </Box>
        <Box sx={{ p: 2, backgroundColor: "background.default" }}>
          {chat && chat.state === "END" ? (
            <Grid container>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={newChat}
                >
                  New Chat
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Type a message"
                  variant="outlined"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents the default action (form submission or line break)
                      handleSend();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleSend}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

type MessageComponentProps = {
  message: {
    role: string;
    content: string;
  };
};

const MessageComponent = ({ message }: MessageComponentProps) => {
  const isBot = message.role === "assistant";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          color: isBot ? "common.white" : "common.black",
          backgroundColor: isBot ? "primary.light" : "common.light",
          borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
        }}
      >
        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
          {message.content}
        </Typography>
      </Paper>
    </Box>
  );
};

export default ChatUI;
