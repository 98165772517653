import { ErrorOutlineRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { OrganizationAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { BillingState } from "../../../model/OrganizationBillingState";

const OrganizationBillingBanner = () => {
  const { organizationBillingState, organization } =
    useGlobalOrganizationContext();
  // get the simplfied billing state
  const currentBillingState = organizationBillingState
    ? organizationBillingState.billing_state
    : BillingState.VALID;

  const [forceRefreshResult, setForceRefreshResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const forceRefreshSubscription = useCallback(() => {
    if (!organization) {
      return;
    }
    setIsLoading(true);
    OrganizationAPI.forceRefreshSubscription(organization.id).then((result) => {
      setForceRefreshResult(result);
      setIsLoading(false);
    });
  }, [organization]);

  return (
    <>
      {currentBillingState != BillingState.VALID && !forceRefreshResult && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={1}
          color="white"
          sx={{
            bgcolor: (theme) =>
              currentBillingState == BillingState.INVALID
                ? theme.palette.error.main
                : theme.palette.warning.main,
          }}
        >
          <ErrorOutlineRounded
            sx={{
              marginRight: 1,
            }}
          />
          <Typography>
            {currentBillingState == BillingState.INVALID
              ? "Your subscription is no longer active!"
              : "Your subscription needs to be renewed soon!"}
          </Typography>
          {!isLoading && (
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                py: 0,
                px: 1,
                borderRadius: 1,
                ml: 1,
              }}
              onClick={forceRefreshSubscription}
            >
              Refresh
            </Button>
          )}
          {isLoading && (
            <CircularProgress size={20} sx={{ ml: 1, color: "white" }} />
          )}
        </Box>
      )}
    </>
  );
};

export default OrganizationBillingBanner;
