import { Task } from "../features/task";
import { NotificationEventType } from "./NotificationEvent";
import { ExecutionState, Process } from "./Process";
import Role from "./Role";
import User from "./User";

export type ProcessExecutionParameters = {
  account?: string;
  hidden?: boolean;
  data?: unknown;
};

export enum ExecutionType {
  Live = "LIVE",
  Test = "TEST",
}

export type ProcessExecution = {
  id: string;
  org_id: string;
  process_id: string;
  process_name: string;
  process_icon?: string;
  process_description: string;
  name: string;
  next_task_id: string;
  next_task_instance?: NextTaskInstance;
  next_step_id: string;
  next_step: Partial<Process>;
  roles: Array<Partial<Role>>;
  started_at: Date;
  started_by_user_id: string;
  subject_id?: string;
  updated_at: Date;
  due_date?: Date;
  completed_at?: Date;
  completed_by_user_id?: string;
  execution_role_id: string;
  completed_tasks?: string;
  total_tasks?: string;
  state?: ExecutionState;
  display?: ProcessExecutionDisplay;
  parameters?: ProcessExecutionParameters;
  execution_type?: ExecutionType;
  owner?: User;

  notification_events?: Array<TaskNotificationEvent>;
};

export type NextTaskInstance = Task & {
  role: Role;
  assignee: User;
};

export type ProcessExecutionDisplay = {
  fontColor: string;
  fillColor: string;
};

export type TaskNotificationEvent = {
  timestamp: string;
  eventType: NotificationEventType;
};
