import { FC } from "react";
import OrganizationScopedPage from "../../components/OrganizationScopedPage";

import { useNavigate } from "react-router";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import useFeature from "../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import OrganizationRoles from "./OrganizationRoles";

const RoleListPage: FC<unknown> = () => {
  useDisplayOptions({});

  const { organization } = useGlobalOrganizationContext();

  const navigate = useNavigate();
  const canViewRoles = useFeature("roles.view");
  if (!canViewRoles) {
    navigate("/user/organization/executions");
  }

  return (
    <OrganizationScopedPage
      category="roles"
      displayOrganizationActionMenu={false}
    >
      {organization && <OrganizationRoles organizationId={organization.id} />}
    </OrganizationScopedPage>
  );
};

export default RoleListPage;
