import { useNavigate } from "react-router";
import Processes from "../../../api/processes";
import PATHS from "../../../components/navigation/_paths";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import { ACCOUNT_TYPE } from "../../../model/EntityRef";
import { Process, ProcessStatus } from "../../../model/Process";
import useAccountType from "../../accounts/hooks/useAccountType";

const useCreateAndNavigateToProcess = () => {
  const { organization } = useGlobalOrganizationContext();
  const { info, handleRejectionWithError } = useAlert();
  const entityTypeId = useAccountType()?.id;
  const navigate = useNavigate();

  const process = (process: Partial<Process>) => {
    if (!organization) {
      info("You must create an organization before creating a process");
      return Promise.resolve();
    }

    const request: Partial<Process> = {
      ...process,
      org_id: organization.id,
      status: ProcessStatus.Unpublished,
    };

    return Processes.create(
      request as Process,
      entityTypeId
        ? {
            entity_type: ACCOUNT_TYPE,
            entity_id: entityTypeId,
            counterparty_can_execute: true,
          }
        : undefined,
    ).then((result) => {
      navigate(PATHS.PROCESS.linkTo(result.id));
    }, handleRejectionWithError("Could not load process page"));
  };

  return process;
};

export default useCreateAndNavigateToProcess;
