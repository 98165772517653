import { CheckCircle, MoreVert, Notifications } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { ProcessExecutionAPI } from "../../../../api";
import TaskExecutionsAPI from "../../../../api/taskExecutions";
import { fetchUsers } from "../../../../components/core/Selection/UserSelection";
import RoleIcon from "../../../../components/elements/RoleIcon";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import { useGlobalUserContext } from "../../../../hooks/useGlobalUserContext";
import { useAlert } from "../../../../lib/alert";
import { TaskExecution } from "../../../task";
import { useProcessExecutionContext } from "../../hooks";
import TaskExecutionAccountModal from "./TaskExecutionAccountModal";
interface TaskOptionDropdownProps {
  taskExecution: TaskExecution;
  canBeAssigned: boolean;
}

const TaskOptionsButton = ({
  taskExecution,
  canBeAssigned,
}: TaskOptionDropdownProps) => {
  const hideRemindFeature = true; // temporary; hiding till template is made

  const org_id = useGlobalOrganizationContext().organization?.id;
  const user = useGlobalUserContext().user;

  const notify = useAlert();

  const { getAssignedUser } = useProcessExecutionContext();
  const [assignedUser, setAssignedUser] = useState(
    getAssignedUser(taskExecution),
  );
  const [teaModalOpen, setTeaModalOpen] = useState<boolean>(false);
  const [sentReminder, setSentReminder] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [canAssignSelf, setCanAssignSelf] = useState<boolean>(false);

  // Have to jump through some hoops to find out if global user has role
  fetchUsers(org_id).then((result) =>
    setCanAssignSelf(
      result
        .find((u) => u.id == user.id)
        ?.roles?.some((r) => r.id == taskExecution.task?.role?.id) || false,
    ),
  );

  const openTaskExecutionAccountModal = () => {
    setTeaModalOpen(true);
  };
  const closeTaskExecutionAccountModal = () => {
    setTeaModalOpen(false);
  };

  const assignToSelf = () => {
    ProcessExecutionAPI.assign(
      taskExecution.process_execution_id || "",
      taskExecution.task_id || "",
      user.id || "",
    )
      .then(() => {
        notify.success("Successfully assigned!");
      })
      .catch(() => {
        notify.error("Failed to assign!");
      });
    close();
  };

  const assignToOther = () => {
    openTaskExecutionAccountModal();
    close();
  };

  const remindUser = () => {
    setSending(true);
    // If reminder has not been set yet
    if (!sentReminder && !sending) {
      if (!taskExecution.id) {
        close();
        return;
      }

      TaskExecutionsAPI.remindTaskAccount(taskExecution.id)
        .then(() => {
          notify.success("Reminder Sent Successfully!");
          setSentReminder(true);
          setSending(false);
        })
        .catch((e) => {
          notify.error("Reminder Failed to Send!");
          console.error(e);
          setSending(false);
        });
    }
    close();
  };

  const FullDropdownButton = () => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchor);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchor(event.currentTarget);
    };

    const close = () => {
      setAnchor(null);
    };

    return (
      <>
        <Button
          sx={{ mx: "10px" }}
          id="task-option-dropdown-button"
          variant="text"
          disabled={Boolean(taskExecution.completed_by_user_id)}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={onClick}
          startIcon={
            <RoleIcon
              role={taskExecution.task?.role}
              assignedUser={assignedUser}
              toolTipPosition="right"
            />
          }
          endIcon={!taskExecution.completed_by_user_id && <MoreVert />}
        ></Button>
        <Menu
          anchorEl={anchor}
          open={open}
          onClose={close}
          MenuListProps={{ "aria-labelledby": "task-option-dropdown-button" }}
        >
          {
            // Always allow user to remind assigned role once
            !sentReminder && !hideRemindFeature && (
              <MenuItem onClick={remindUser}>
                Remind {taskExecution.task?.role?.name || "Next User"}
              </MenuItem>
            )
          }
          {
            !sentReminder && !hideRemindFeature && <Divider /> // Must be seperate or else MUI will throw an error
          }
          {canBeAssigned && (
            <MenuItem onClick={assignToOther}>Assign To Someone</MenuItem>
          )}
          {canBeAssigned && canAssignSelf && (
            <MenuItem onClick={assignToSelf}>Assign To Myself</MenuItem>
          )}
        </Menu>
        <TaskExecutionAccountModal
          open={teaModalOpen}
          handleClose={closeTaskExecutionAccountModal}
          taskExecution={taskExecution}
          assignedUser={assignedUser}
          setAssignedUser={setAssignedUser}
        />
      </>
    );
  };

  const SimpleRemindIcon = () => {
    return (
      !hideRemindFeature && (
        <Tooltip
          title={
            (!sentReminder &&
              `Remind ${taskExecution.task?.role?.name || "Next User"}`) ||
            "Reminder Sent!"
          }
        >
          <IconButton onClick={remindUser}>
            {(!sentReminder && !sending && <Notifications />) || ( // Not yet sent
              <CheckCircle /> // Sent
            )}
          </IconButton>
        </Tooltip>
      )
    );
  };

  /*
   * If can be assigned, use full dropdown UI
   * Otherwise, show just a simple remind button if not yet reminded
   */
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      {(canBeAssigned && <FullDropdownButton />) ||
        (!taskExecution.completed_by_user_id && <SimpleRemindIcon />)}
    </Box>
  );
};

export default TaskOptionsButton;
