import { AddCircle, PlayCircleFilled } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { ProcessAPI, ProcessExecutionAPI } from "../../../../api";
import PATHS from "../../../../components/navigation/_paths";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import { useAlert } from "../../../../lib/alert";
import Organization from "../../../../model/Organization";
import { Process, ProcessStatus } from "../../../../model/Process";
import {
  ExecutionType,
  ProcessExecution,
} from "../../../../model/ProcessExecution";
import useCreateAndNavigateToProcess from "../../hooks/useCreateAndNavigateToProcess";
import messages from "../../messages";
import CreateProcessDialog from "../CreateProcessDialog";
import { ProcessQuickDropdown } from "./ProcessQuickDropdown";
import { ProcessQuickList } from "./ProcessQuickList";

type ProcessQuickButtonProps = {
  organization?: Organization;
  lastProcessExecution?: ProcessExecution;
};

const ProcessQuickStart: FC<ProcessQuickButtonProps> = ({
  organization,
  lastProcessExecution,
}) => {
  const [processes, setProcesses] = useState<Array<Process>>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [processCreateDialogOpen, setProcessCreateDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { handleRejectionWithWarning } = useAlert();
  const createAndNavigateToProcess = useCreateAndNavigateToProcess();
  const intl = useIntl();
  const processWord = intl.formatMessage(messages.process.props);
  const isAdmin = useIsAdmin();

  const fetchProcessesForOrganization = useCallback(async () => {
    if (!organization?.id) return;
    return await ProcessAPI.byOrganization(organization.id);
  }, [organization?.id]);

  // Fetch processes
  useEffect(() => {
    setFetching(true);
    fetchProcessesForOrganization()
      .then((processes) => {
        // Filter thru the processes and find only active processes.
        const activeProcesses = processes?.filter(
          (process) =>
            (process.status == ProcessStatus.Draft ||
              process.status == ProcessStatus.Published) &&
            // TODO: Remove invitation process. This just hides it.
            process.name != "Invitation",
        );
        setProcesses(activeProcesses ?? []);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [fetchProcessesForOrganization]);

  const handleExecuteProcessRequest = (
    process: Process,
    execution_type = ExecutionType.Live,
  ) => {
    const metaOrId = process.meta ?? process.id;
    const name = process.name;
    const configuration = { name: name, execution_type };
    ProcessExecutionAPI.start(metaOrId, configuration).then(
      (processExecution: ProcessExecution) => {
        navigate(
          PATHS.EXECUTION.linkTo(
            processExecution.process_id,
            processExecution.id,
          ),
        );
      },
      (err) => {
        handleRejectionWithWarning(
          `Could not start process execution for process ${process.name}`,
        )(err);
      },
    );
  };

  // Select component output
  const assignQuickFlightType = (numFlights: number) => {
    // No flights, "Create Flight Plan Button" (Work/CRM)
    if (numFlights == 0) {
      if (!isAdmin) return <></>;
      return (
        <>
          <Box sx={{ display: "inline", margin: "4px 48px 0 0" }}>
            <Button
              variant="contained"
              data-cy="create-process-btn"
              startIcon={<AddCircle sx={{ mr: "0.25em" }} />}
              onClick={() => {
                setProcessCreateDialogOpen(true);
              }}
            >
              {`Create ${processWord}`}
            </Button>
          </Box>
          <CreateProcessDialog
            open={processCreateDialogOpen}
            showSubjectField={false}
            handleConfirm={createAndNavigateToProcess}
            handleCancel={function (): void {
              setProcessCreateDialogOpen(false);
            }}
          />
        </>
      );
    }

    // If one flight (Runway Connect), quick start flight
    if (numFlights == 1 && !fetching) {
      return (
        <Box sx={{ display: "inline", margin: "4px 48px 0 0" }}>
          <Button
            variant="contained"
            startIcon={<PlayCircleFilled />}
            onClick={() => {
              handleExecuteProcessRequest(processes[0]);
            }}
          >
            {processes[0].name}
          </Button>
        </Box>
      );
    }

    if (numFlights <= 10) {
      return (
        <ProcessQuickDropdown
          processes={processes}
          handleStart={handleExecuteProcessRequest}
          lastProcessExecution={lastProcessExecution}
        />
      );
    }

    return (
      <ProcessQuickList
        processes={processes}
        handleStart={handleExecuteProcessRequest}
        lastProcessExecution={lastProcessExecution}
      />
    );
  };

  return assignQuickFlightType(processes.length);
};

export default ProcessQuickStart;
