import { CheckCircle, Draw, Send, Visibility } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { HumanReadableTimestamp } from "../../../../components";
import {
  NotificationEventType,
  ProcessExecutionTracking,
} from "../../../../model/NotificationEvent";

const iconStages: {
  [key: string]: {
    icon: JSX.Element;
    tooltip: (completed: Date | undefined) => JSX.Element;
  };
} = {
  Delivery: {
    icon: <Send fontSize="small" />,
    tooltip: (completed: Date | undefined) => (
      <>
        {completed ? (
          <HumanReadableTimestamp
            timestamp={completed}
            variant="caption"
            format="time"
          />
        ) : (
          <Typography variant="caption">Not Delivered</Typography>
        )}
      </>
    ),
  },
  SeenAssigned: {
    icon: <Visibility fontSize="small" />,
    tooltip: (completed: Date | undefined) => (
      <>
        {completed ? (
          <HumanReadableTimestamp
            timestamp={completed}
            variant="caption"
            format="time"
          />
        ) : (
          <Typography variant="caption">Not Seen</Typography>
        )}
      </>
    ),
  },
  Progress: {
    icon: <Draw fontSize="small" />,
    tooltip: (completed: Date | undefined) => (
      <>
        {completed ? (
          <HumanReadableTimestamp
            timestamp={completed}
            variant="caption"
            format="time"
          />
        ) : (
          <Typography variant="caption">Not Edited</Typography>
        )}
      </>
    ),
  },
  Completed: {
    icon: <CheckCircle fontSize="small" />,
    tooltip: (completed: Date | undefined) => (
      <>
        {completed ? (
          <HumanReadableTimestamp
            timestamp={completed}
            variant="caption"
            format="time"
          />
        ) : (
          <Typography variant="caption">Not Completed</Typography>
        )}
      </>
    ),
  },
};

const TaskExecutionMiniTracker = ({
  notification_events,
}: {
  notification_events: Array<ProcessExecutionTracking>;
}) => {
  const showIcons = [
    NotificationEventType.Delivery,
    NotificationEventType.SeenAssigned,
    NotificationEventType.Progress,
    NotificationEventType.Completed,
  ];

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={1}
      >
        {showIcons.map((event, index) => {
          const found = notification_events.find(
            (tracking) => tracking.state === event,
          );
          return (
            <>
              {index > 0 && (
                <Box
                  flex={1}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 4,
                    marginLeft: 0.5,
                    marginRight: 0.5,
                    minWidth: 30,
                    opacity: found ? 1 : 0.5,
                  }}
                ></Box>
              )}
              <Tooltip
                title={iconStages[event.toString()].tooltip(found?.timestamp)}
              >
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    opacity: found ? 1 : 0.5,
                  }}
                >
                  {iconStages[event.toString()].icon}
                </Box>
              </Tooltip>
            </>
          );
        })}
        {/* <Box
          flex={1}
          sx={{
            backgroundColor: "white",
            borderRadius: 5,
            height: 4,
            marginLeft: 1,
            marginRight: 1,
            minWidth: 60,
          }}
        {/* <Box
          flex={1}
          sx={{
            backgroundColor: "white",
            borderRadius: 5,
            height: 4,
            marginLeft: 1,
            marginRight: 1,
            minWidth: 60,
          }}
        ></Box> */}
      </Box>
    </>
  );
};

export default TaskExecutionMiniTracker;
