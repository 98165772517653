import { Grid } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountProcessExecutions, AccountProcessHero } from ".";
import { ProcessExecutionAPI } from "../../../api";
import useFeature from "../../../hooks/useFeature";
import useLinks from "../../../hooks/useLinks";
import { useAlert } from "../../../lib/alert";
import { ProcessExecution } from "../../../model/ProcessExecution";
import { AccountPageContext } from "../hooks/useOrganizationAccountPage";
import { AccountProfileViewer } from "../types";
import AccountProcessCard from "./cards/AccountProcessCard";

const OrganizationAccountFlights = ({
  viewer,
}: {
  viewer: AccountProfileViewer;
}) => {
  const links = useLinks();
  const showAccountProcessCard = useFeature("account.page.process.card", false);
  const showAccountProcessHero = useFeature("account.process.hero", false);
  const navigate = useNavigate();
  const { handleRejectionWithWarning } = useAlert();
  const { account, organization, accountTypeProcesses } =
    useContext(AccountPageContext);
  const accountProcessCardVisible =
    showAccountProcessCard && accountTypeProcesses.length > 0;

  return (
    <>
      <Grid item>
        <Grid container spacing={2} sx={{ px: 4 }} alignItems="stretch">
          {showAccountProcessHero && account.process_execution_id && (
            <Grid item xs={12}>
              <AccountProcessHero
                processExecutionId={account.process_execution_id}
              />
            </Grid>
          )}
          <Grid item xs={accountProcessCardVisible ? 8 : 12}>
            <AccountProcessExecutions
              organizationId={organization.id}
              subjectId={account.id}
              viewer={viewer}
              useWrapper={false}
              noBorder={true}
            />
          </Grid>
          {accountProcessCardVisible && (
            <Grid item xs={4} sx={{ height: "334px", mt: "10px" }}>
              <AccountProcessCard
                processes={accountTypeProcesses}
                onProcessClick={(process) => {
                  if (process.meta) {
                    ProcessExecutionAPI.start(process.meta, {
                      name: account.account_name
                        ? `${account.account_name} - ${process.name}`
                        : process.name,
                      subject_id: account.id,
                      parameters: {
                        account: account.id,
                      },
                    }).then(
                      (processExecution: ProcessExecution) => {
                        navigate(
                          links.EXECUTION.linkTo(
                            processExecution.process_id,
                            processExecution.id,
                          ),
                        );
                      },
                      (err) => {
                        handleRejectionWithWarning(
                          `Could not start process execution for process ${process.id}`,
                        )(err);
                      },
                    );
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationAccountFlights;
