import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AccountAPI, ProcessAPI } from "../../../api";
import accountPrograms from "../../../api/accountPrograms";
import processCategories from "../../../api/processCategories";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { Organization } from "../../../model";
import { AccountDto } from "../../../model/Account";
import { AccountProgram } from "../../../model/AccountProgram";
import { ACCOUNT_TYPE } from "../../../model/EntityRef";
import { Process, ProcessStatus } from "../../../model/Process";
import { ProcessCategory } from "../../../model/ProcessCategory";
import { AccountProfileViewer } from "../types";

type AccountPageContextType = {
  account: AccountDto;
  organization: Organization;
  accountTypeProcesses: Process[];
  programs: AccountProgram[];
  userType: AccountProfileViewer;
  categories?: ProcessCategory[];
  setAccount: Dispatch<SetStateAction<AccountDto | undefined>>;
};

export const AccountPageContext = React.createContext<
  AccountPageContextType | Record<string, never>
>({});

const useOrganizationAccountPage = (
  viewer: AccountProfileViewer,
): AccountPageContextType | undefined => {
  const [account, setAccount] = useState<AccountDto>();
  const [accountTypeProcesses, setAccountTypeProcesses] = useState<Process[]>();
  const [categories, setCategories] = useState<ProcessCategory[]>();
  const [programs, setPrograms] = useState<AccountProgram[]>();

  // REFACTOR to pull subjectId out!!!
  // Element of least surprise
  const subjectId = useParams().subjectId;

  const { organization, setOrganization, accountListIsMemberOf, isMember } =
    useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);

  // Load account information
  useEffect(() => {
    (async () => {
      const accnt =
        subjectId && ((await AccountAPI.get(subjectId)) as AccountDto);
      accnt && setAccount(accnt);
    })();
  }, [subjectId]);

  // Load organization data
  useEffect(() => {
    (async () => {
      if (account && accountListIsMemberOf && !organization) {
        const org =
          account.organization ??
          accountListIsMemberOf.find((a) => account.id === a.id)?.organization;
        setOrganization(org);
      }
    })();
  }, [account, accountListIsMemberOf, organization, setOrganization]);

  useEffect(() => {
    (async () => {
      // loads all aspects of an account once for smooth page navigation, passes context to children
      if (account && organization) {
        setAccountTypeProcesses(
          await ProcessAPI.byEntity(
            organization.id,
            {
              entity_type: ACCOUNT_TYPE,
              entity_id: account.account_type_id ?? "",
              counterparty_can_execute: isMemberOfOrg ? undefined : true,
            },
            account.is_test ? ProcessStatus.Draft : ProcessStatus.Published,
          ),
        );
        setPrograms(await accountPrograms.loadByAccountId(account.id));
        setCategories(
          viewer == AccountProfileViewer.Admin && isMemberOfOrg
            ? await processCategories.byOrganization(organization.id)
            : undefined,
        );
      }
    })();
  }, [account, isMemberOfOrg, organization, viewer]);

  return (
    account &&
    organization &&
    accountTypeProcesses &&
    programs && {
      account: account,
      organization: organization,
      accountTypeProcesses: accountTypeProcesses,
      programs: programs,
      userType: viewer,
      categories: categories,
      setAccount,
    }
  );
};

export default useOrganizationAccountPage;
