import OrganizationBillingState from "../model/OrganizationBillingState";
import { environment } from "../util";
import { useGlobalOrganizationContext } from "./useGlobalOrganizationContext";

const DEFINED_PRODUCT_FEATURES: {
  [key: string]: Array<string>;
} = {
  Connect: ["process.tracker"],
  Work: [
    "roles.administration",
    "roles.view",
    "process.assigned",
    "process.administration",
    "process.test",
  ],
  CRM: [
    "process.accountView",
    "accountType.administration",
    "process.administration",
    "process.test",
    "process.category",
  ],
};

// Proxy method so that we can use this in contexts that react doesn't
// like to allow us to use hooks in
export const getFeature = (
  feature: string,
  defaultValue?: boolean,
): boolean => {
  // TODO - store features in organization settings - driven through Stripe subscription
  const dv =
    environment.features[feature] !== undefined
      ? environment.features[feature]
      : defaultValue;
  if (environment.features[feature]) {
    return true;
  }
  const localState = localStorage.getItem(`runway.${feature}`);
  if (localState === null) {
    return dv !== undefined ? dv : false;
  }
  return localState.toLowerCase() === "true";
};

export const getCachedFeatures = (
  organizationBillingState: OrganizationBillingState,
) => {
  if (organizationBillingState?.cached) {
    return DEFINED_PRODUCT_FEATURES[organizationBillingState.product_flag!];
  }
  return [];
};

export default (feature: string, defaultValue?: boolean): boolean => {
  const { organization, organizationBillingState } =
    useGlobalOrganizationContext();
  if (organization?.config) {
    const featureConfig = organization.config.features;
    if (featureConfig && featureConfig[feature] !== undefined) {
      return featureConfig[feature];
    }
  }

  if (organizationBillingState?.cached) {
    // get hardcoded feature flags from the organization as Stripe is disconnected
    const productFeatures =
      DEFINED_PRODUCT_FEATURES[organizationBillingState.product_flag!];
    if (productFeatures && productFeatures.includes(feature)) {
      return true;
    }
  }

  if (organization?.features) {
    // implementation for billing features through Stripe
    if (organization.features.includes(feature)) {
      return true;
    }
  }

  return getFeature(feature, defaultValue);
};
