import WebSocketMessage, {
  WebSocketMessageType,
} from "../../model/WebSocketMessage";
import { currentUserId } from "../auth";
import { handleCancelNotification } from "./cancelNotificationHandler";
import { handleCurrentlyViewing } from "./currentlyViewingHandler";
import { handleNotification } from "./notificationHandler";
import { handleTinyNotification } from "./tinyNotificationHandler";
import { handleTracking } from "./trackingHandler";

export class WebSocketGlobalState {
  private static connection?: boolean;
  private static ws?: WebSocket;
  public static getWebSocket = () => WebSocketGlobalState.ws;
  public static anonymousIdentity = true;
  static setWebSocket = (ws: WebSocket) => {
    WebSocketGlobalState.ws = ws;
  };
}

const messageTypesToReceive = [
  WebSocketMessageType.PUSH_NOTIFICATION,
  WebSocketMessageType.TINY_NOTIFICATION,
  WebSocketMessageType.IDENTITY,
  WebSocketMessageType.IDENTITY_ERROR,
  WebSocketMessageType.TRACKING,
  WebSocketMessageType.KILL,
  WebSocketMessageType.WELCOME,
  WebSocketMessageType.PUSH_NOTIFICATION_CANCEL,
  WebSocketMessageType.CURRENTLY_VIEWING,
];

const handlers: {
  [key: string]: (message: WebSocketMessage) => Promise<void>;
} = {
  PUSH_NOTIFICATION: handleNotification,
  TINY_NOTIFICATION: handleTinyNotification,
  PUSH_NOTIFICATION_CANCEL: handleCancelNotification,
  TRACKING: handleTracking,
  CURRENTLY_VIEWING: handleCurrentlyViewing,
};

/**
 * Sets up the websocket connection and adds it to the static class
 */
export const setupWebSocket = (ws: WebSocket) => {
  WebSocketGlobalState.setWebSocket(ws);
  ws.onmessage = (event) => {
    // parse to WebSocketMessage
    try {
      const message = JSON.parse(event.data);
      handleWebSocketMessage(message);
    } catch (e) {
      console.error("Error parsing websocket message", e);
    }
  };
};

/**
 * Validate a websocket message that is received from the server
 * - If no message type is set, the message is invalid
 * - If the message type is not one we can receive, then the message is invalid
 */
export const validateWebSocketMessage = (message: WebSocketMessage) => {
  if (!message.type) {
    return false;
  }
  if (!messageTypesToReceive.includes(message.type)) {
    return false;
  }
  return true;
};

/**
 * Creates a WebSocketMessage object that can be sent to the server
 */
export const createWebSocketMessage = (
  type: WebSocketMessageType,
  data: object,
) => {
  let userIdToUse;
  try {
    userIdToUse = currentUserId();

    // check if we are now logged in somehow
    // if we are, we need to send the identity message again
    const oldAnonymous = WebSocketGlobalState.anonymousIdentity;
    WebSocketGlobalState.anonymousIdentity = false;
    if (oldAnonymous != WebSocketGlobalState.anonymousIdentity) {
      WebSocketGlobalState.getWebSocket()?.send(
        JSON.stringify(
          createWebSocketMessage(WebSocketMessageType.IDENTITY, {}),
        ),
      );
    }
  } catch (e) {
    // if there is a failed attempt to get the user_id, we are anonymous
    userIdToUse = "anonymous";
    // if we are now anonymous, we need to send the identity message again eventually
    WebSocketGlobalState.anonymousIdentity = true;
  }
  const message: WebSocketMessage = {
    type,
    data,
    user_id: userIdToUse,
  };
  return message;
};

/**
 * Handles a websocket message that is received from the server
 * Gets the appropriate handler for the message type and calls it
 */
export const handleWebSocketMessage = (message: WebSocketMessage) => {
  // check for validation of the message
  if (!validateWebSocketMessage(message) || !message.type) return;
  const handler = handlers[message.type?.toString()];
  if (!handler) {
    return;
  }
  // invoke the handler
  handler(message);
};
