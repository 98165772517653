export enum WebSocketMessageType {
  WELCOME = "WELCOME",
  TINY_NOTIFICATION = "TINY_NOTIFICATION",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  PUSH_NOTIFICATION_CANCEL = "PUSH_NOTIFICATION_CANCEL",
  PUSH_NOTIFICATION_INTERACTION = "PUSH_NOTIFICATION_INTERACTION",
  IDENTITY = "IDENTITY",
  IDENTITY_ERROR = "IDENTITY_ERROR",
  TRACKING = "TRACKING",
  CURRENTLY_VIEWING = "CURRENTLY_VIEWING",
  KILL = "KILL",
}

export type WebSocketNotificationData = {
  title: string;
  body: string;
  icon?: string;
  organization_id?: string;
  process_id?: string;
  process_execution_id?: string;
  type?: string;
  id: string;
};

export type WebSocketTrackingListen = {
  type: string;
  subject_id: string;
};

export type WebSocketTrackingData = {
  process_id: string;
  process_execution_id: string;
  task_id: string;
  task_execution_id: string;
  state: string;
  data?: string;
};

export type WebSocketCurrentlyViewingData = {
  process_execution_id: string;
  user_id: string;
};

export type WebSocketNotificationCancelData = {
  id: string;
};

export type WebSocketTinyNotificationData = {
  type: string;
  message: string;
};

export default class WebSocketMessage {
  public type?: WebSocketMessageType;
  public data?: object;
  public user_id?: string;
}
