/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardArrowRight, PlayCircle } from "@mui/icons-material";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import { useEffect, useState } from "react";
import { Process, ProcessExecution } from "../../../../model/Process";

interface ProcessQuickDropdownProps {
  processes: Array<Process>;
  handleStart: (process: Process) => void;
  sx?: SxProps<Theme>;
  lastProcessExecution?: ProcessExecution;
}

export const ProcessQuickList = ({
  processes,
  handleStart,
  ...props
}: ProcessQuickDropdownProps) => {
  const [selectedProcess, setSelectedProcess] = useState<Process | undefined>(
    undefined,
  );
  const [inputValue, setInputValue] = useState<string>(
    selectedProcess?.name || "",
  );
  const [validInput, setValidInput] = useState<boolean>(false);
  const [minLengthCheck, setMinLengthCheck] = useState<number>(-1);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const setAutocomplete = (state: boolean) => {
    setOpen(state);
  };
  const handleClick = () => {
    startProcess();
  };
  const startProcess = () => {
    // Find if there is a flight plan with entered name and prioritize.
    if (inputValue != selectedProcess?.name) {
      const process = processes.find((process) => {
        return process.name == inputValue;
      });
      if (process) handleStart(process);
    }

    // Else if selected process matches the current input value
    if (selectedProcess && selectedProcess.name == inputValue)
      handleStart(selectedProcess);
  };

  useEffect(() => {
    for (let i = 0; i < processes.length; i++) {
      if (processes[i].name.length < minLengthCheck || minLengthCheck == -1) {
        setMinLengthCheck(processes[i].name.length);
      }
    }
  }, [processes]);

  // Loads in recommended process
  useEffect(() => {
    if (props.lastProcessExecution) {
      const process = processes.find((process) => {
        return process.meta == props.lastProcessExecution?.process_id;
      });

      if (process) {
        setSelectedProcess(process);
        setInputValue(process.name);
      }
    } else {
      setSelectedProcess(undefined);
      setInputValue("");
    }
  }, [processes]);

  // Saves performance by only checking validity if length of string is correct
  useEffect(() => {
    if (inputValue.length < minLengthCheck) setValidInput(false);
    else
      setValidInput(
        processes.find((process) => {
          return process.name == inputValue;
        }) != undefined,
      );
  }, [searchOpen, inputValue, processes]);

  return (
    <>
      {!searchOpen && (
        <Box>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setSearchOpen(true);
              setAutocomplete(true);
            }}
            endIcon={<KeyboardArrowRight />}
          >
            Select Flight Plan
          </Button>
        </Box>
      )}
      {searchOpen && (
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            minWidth: 150,
            maxWidth: 250,
            m: 0,
            flexGrow: 1,
          }}
        >
          <>
            <Autocomplete
              freeSolo
              size="small"
              open={open}
              onOpen={() => setAutocomplete(true)}
              options={processes}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event, newValue, reason) => {
                if (typeof newValue === "string") return;
                if (newValue && newValue.meta) {
                  setSelectedProcess(newValue);
                } else {
                  setSelectedProcess(undefined);
                }
                setAutocomplete(false);
                if (reason == "selectOption" && newValue != null) {
                  handleStart(newValue);
                }
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return option.name;
              }}
              renderOption={(props, option) => {
                const { ...optionProps } = props;
                return (
                  <li {...optionProps} key={option.meta || ""}>
                    {option.name}
                  </li>
                );
              }}
              value={selectedProcess ?? null}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  placeholder="Select Flight Plan"
                  {...params}
                />
              )}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              sx={{
                height: 10,
                flexGrow: 1,
                fieldset: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
            />
            <Button
              variant="contained"
              disabled={!validInput}
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                boxShadow: 0,
                height: "40px",
                mt: 0,
                minWidth: 0,
                px: 1,
              }}
              onClick={handleClick}
            >
              <PlayCircle />
            </Button>
          </>
        </Box>
      )}
    </>
  );
};
