import { Box, TextField } from "@mui/material";
import { useAtom } from "jotai";
import { createContext, useContext, useEffect, useState } from "react";
import { AccountTypeAPI } from "../../../../../api";
import { SubmitButton } from "../../../../../components/elements";
import { useGlobalOrganizationContext } from "../../../../../hooks/useGlobalOrganizationContext";
import { authenticated } from "../../../../../lib/auth";
import { Account, Organization } from "../../../../../model";
import { AccountType } from "../../../../../model/AccountType";
import AccountSelectTaskWrapper from "../../../../accounts/components/AccountSelectWrapper";
import { publicPageAtom } from "../../../../organization/state/publicPageAtom";
import {
  TaskCreateAccount,
  TaskCreateAccountData,
  TaskCreateAccountExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../types";

enum AccountDisplay {
  EMAIL_PROMPT = 0,
  ACCOUNT_SELECTOR = 1,
}

interface CreateAccountExecutionContextProps {
  setActionBarVisible: (visible: boolean) => void;
  executeAfterCreated: (account: Account) => Promise<void>;
  accountType: AccountType | undefined;
}

const CreateAccountExecutionContext = createContext<
  CreateAccountExecutionContextProps | undefined
>(undefined);
export const useAccountExecutionContext = () => {
  const context = useContext(CreateAccountExecutionContext);
  if (!context) {
    console.error(
      "Error loading CreateAccountExecutionContext! (CreateAccountExecutiobComponent)",
    );
    return undefined;
  }
  return context;
};

const CreateAccountExecutionComponent = ({
  task,
  taskExecution,
  isDisabled,
  children,
  ...props
}: TaskExecutionComponentProps<
  TaskCreateAccount,
  TaskCreateAccountExecution
>) => {
  const { organization } = useGlobalOrganizationContext();
  const [publicPage] = useAtom(publicPageAtom);
  const [account, setAccount] = useState<Account | undefined>();
  const [accountType, setAccountType] = useState<AccountType | undefined>();
  const [accountEmail, setAccountEmail] = useState<string | undefined>();
  const [accountDisplay, setAccountDisplay] = useState<
    AccountDisplay | undefined
  >();
  const [actionBarVisible, setActionBarVisible] = useState<boolean>(true);

  const onExecute = async () => {
    const data = {
      ...taskExecution,
      task_id: task.id,
      data: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        account: account ?? {
          primary_email: accountEmail?.trim(),
        },
      },
      state: TaskExecutionState.Completed,
    };
    props.onExecute?.(data);
  };

  const executeAfterCreated = async (account: Account) => {
    const data = {
      ...taskExecution,
      task_id: task.id,
      data: {
        account: account,
      },
      state: TaskExecutionState.Completed,
    };
    await props.onExecute?.(data);
  };

  const createAccountExecutionContext: CreateAccountExecutionContextProps = {
    setActionBarVisible: setActionBarVisible,
    executeAfterCreated: executeAfterCreated,
    accountType: accountType,
  };

  useEffect(() => {
    if (taskExecution?.data?.account) {
      const account = taskExecution?.data?.account;
      setAccount(account as Account);
      setAccountEmail(
        (accountEmailVal) => accountEmailVal ?? account.primary_email,
      );
    }
  }, [accountEmail, taskExecution?.data?.account]);

  useEffect(() => {
    if (task.data?.account) {
      const account = task.data?.account;
      setAccountEmail(
        (accountEmailVal) => accountEmailVal ?? account.primary_email,
      );
    }
  }, [task.data?.account]);

  useEffect(() => {
    const loggedIn = !!authenticated();
    if (!organization) {
      return;
    }

    if (task.data?.accountTypeKey) {
      AccountTypeAPI.byKey(organization?.id, task.data.accountTypeKey).then(
        (accountType) => {
          setAccountType(accountType);
        },
      );
    }
    setAccountDisplay(
      loggedIn && !publicPage
        ? AccountDisplay.ACCOUNT_SELECTOR
        : AccountDisplay.EMAIL_PROMPT,
    );
  }, [task.data?.accountTypeKey, organization, publicPage]);
  const actions = (
    <SubmitButton
      variant="contained"
      size="small"
      sx={{ m: 1 }}
      disabled={
        // disabled when its manually set, account isnt selected, or task is completed
        isDisabled ||
        (!account && !accountEmail) ||
        taskExecution?.completed_at != null
      }
      onSubmit={onExecute}
    >
      Continue
    </SubmitButton>
  );
  let content;
  if ((accountDisplay as AccountDisplay) === AccountDisplay.EMAIL_PROMPT) {
    content = (
      <CreateAccountExecutionContext.Provider
        value={createAccountExecutionContext}
      >
        <TextField
          fullWidth
          label="Email"
          name="email"
          disabled={isDisabled}
          required={true}
          onChange={(event) => {
            setAccountEmail(event.target.value);
            props.onValueChange &&
              props.onValueChange({
                ...task,
                data: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  accountTypeKey: task.data!.accountTypeKey,
                  account: {
                    ...task.data?.account,
                    primary_email: event.target.value,
                  },
                },
              });
          }}
          variant="outlined"
          type="text"
          InputProps={{ readOnly: isDisabled }}
          value={accountEmail}
          inputProps={{ style: { fontSize: "0.875rem" } }}
          sx={{ bgcolor: "common.white" }}
        />
      </CreateAccountExecutionContext.Provider>
    );
  } else if (accountDisplay === AccountDisplay.ACCOUNT_SELECTOR) {
    content = organization && (
      <CreateAccountExecutionContext.Provider
        value={createAccountExecutionContext}
      >
        <Box style={{ minWidth: "300px" }}>
          <AccountSelectTaskWrapper
            accountType={accountType}
            organization={organization}
            taskView={props.view}
            onCreate={executeAfterCreated}
            onSelect={(option) => {
              if (option) {
                let account: Account = option.data as Account;

                if (option.type !== "account") {
                  const externalOrganization = option.data as Organization;

                  if (accountType) {
                    account = {
                      account_name: externalOrganization.name,
                      account_type_id: accountType.id,
                      external_org_id: externalOrganization.id,
                      org_id: organization.id,
                    } as Account;
                  }
                }

                setAccount(account);

                const data: TaskCreateAccountData = {
                  accountTypeKey: task.data!.accountTypeKey,
                  account: account,
                };

                props.onValueChange?.({
                  ...task,
                  data: data,
                });
              } else {
                setAccount(undefined);
              }
            }}
            value={account}
            disabled={taskExecution?.completed_at != undefined}
          />
        </Box>
      </CreateAccountExecutionContext.Provider>
    );
  }
  if (children) {
    // TODO - restore functionality from below. Had to disable to make sure continue button shows up for portal create account flow
    //   actionBarVisible ? { content, actions } : { content });
    return children({ content, actions });
  }
  return (
    <CreateAccountExecutionContext.Provider
      value={createAccountExecutionContext}
    >
      {content}
      {actionBarVisible && actions}
    </CreateAccountExecutionContext.Provider>
  );
};

export default CreateAccountExecutionComponent;
