import {
  CheckCircleOutline,
  CircleOutlined,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  Mail,
  NotificationImportant,
} from "@mui/icons-material";
import { Box, Collapse, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { HumanReadableTimestamp } from "../../../../components";
import {
  NotificationEventType,
  ProcessExecutionTracking,
} from "../../../../model/NotificationEvent";
import { ProgressBarStage } from "./TaskExecutionTracker";

const eventsWithDetails = [NotificationEventType.SeenAssigned];

export const TaskExecutionTrackerStage = ({
  progressBarStage,
  isError,
  isTriggered,
  trigger,
  isEntireError,
  trackerEventsContext,
}: {
  progressBarStage: ProgressBarStage;
  isError: boolean;
  isTriggered: boolean;
  trigger?: ProcessExecutionTracking;
  isEntireError: boolean;
  trackerEventsContext?: ProcessExecutionTracking[]; // allows to make determininations on the additional information to show
}) => {
  const [timestampString, setTimestampString] = useState<string | undefined>(
    undefined,
  );

  const [showDetails, setShowDetails] = useState(false);

  const doesEventExistInContext = useCallback(
    (eventType: NotificationEventType) => {
      return (
        trackerEventsContext &&
        trackerEventsContext.some((event) => event.state == eventType)
      );
    },
    [trackerEventsContext],
  );

  useEffect(() => {
    if (trigger && trigger.timestamp) {
      setTimestampString(trigger.timestamp.toLocaleString());
    }
  }, [trigger, isTriggered]);

  return (
    <>
      <Box
        sx={
          progressBarStage.progress < 100
            ? {
                position: "absolute",
                top: 0,
                left: `${progressBarStage.progress}%`,
                maxWidth: "15%",
              }
            : {
                position: "absolute",
                top: 0,
                right: 0,
                textAlign: "right",
              }
        }
      >
        <Tooltip title={progressBarStage.tooltip(isTriggered)}>
          <Box>
            {isError && (
              <ErrorOutline
                sx={(theme) => ({
                  backgroundColor: isEntireError
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                  color: "white",
                  height: 24,
                  width: 24,
                  borderRadius: 12,
                })}
              />
            )}
            {!isError && (
              <>
                {isTriggered ? (
                  <CheckCircleOutline
                    sx={(theme) => ({
                      backgroundColor: isEntireError
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                      color: "white",
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                    })}
                  />
                ) : (
                  <CircleOutlined
                    sx={(theme) => ({
                      backgroundColor: isTriggered
                        ? isEntireError
                          ? theme.palette.error.main
                          : theme.palette.primary.main
                        : "transparent",
                      color: "white",
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                    })}
                  />
                )}
              </>
            )}
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography variant="body2" fontWeight="bold">
                  {progressBarStage.label(isTriggered, isError)}
                </Typography>
                {trigger?.state &&
                eventsWithDetails.includes(trigger?.state) ? (
                  showDetails ? (
                    <ExpandMore onClick={() => setShowDetails(!showDetails)} />
                  ) : (
                    <ExpandLess onClick={() => setShowDetails(!showDetails)} />
                  )
                ) : (
                  <>
                    {progressBarStage.icon ? (
                      <Box marginLeft={0.5}>{progressBarStage.icon}</Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>

              {trigger && (
                <Collapse in={showDetails}>
                  <>
                    {trigger.state == NotificationEventType.SeenAssigned &&
                      doesEventExistInContext(NotificationEventType.Open) && (
                        <Box display={"flex"} alignItems={"center"}>
                          <Mail fontSize={"small"} />
                          <Typography
                            variant="body2"
                            fontSize={12}
                            marginLeft={0.5}
                          >
                            By Email
                          </Typography>
                        </Box>
                      )}
                    {trigger.state == NotificationEventType.SeenAssigned &&
                      doesEventExistInContext(
                        NotificationEventType.NotificationClick,
                      ) && (
                        <Box display={"flex"} alignItems={"center"}>
                          <NotificationImportant fontSize={"small"} />
                          <Typography
                            variant="body2"
                            fontSize={12}
                            marginLeft={0.5}
                          >
                            By Notification
                          </Typography>
                        </Box>
                      )}
                  </>
                </Collapse>
              )}
            </Box>
          </Box>
        </Tooltip>
        <Box>
          {timestampString && trigger && (
            <HumanReadableTimestamp
              timestamp={trigger.timestamp}
              variant="caption"
              format="time"
            />
          )}
        </Box>
      </Box>
    </>
  );
};
