import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Chip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { AccountTypeAPI } from "../../../api";
import { AccountDto } from "../../../model/Account";
import { FieldValue } from "../../../model/FieldDefinition";
import {
  FormElementType,
  FormExecutionType,
  SelectionOption,
} from "../../task";
import TaskFieldDefinitionFormComponent from "../../task/components/task-types/form/task-form-elements/TaskFieldDefinitionFormComponent";
import useUpdatedAccountFields from "../hooks/useUpdatedAccountFields";

type AccountFieldsAccordionProps = {
  accountDto: AccountDto;
};
const AccountFieldsAccordion: FC<AccountFieldsAccordionProps> = ({
  accountDto,
}) => {
  const [updatedFieldDefs, setUpdatedFieldDefs] = useState<
    Array<{
      fieldDefId: string;
      elementId: string;
      value: FieldValue | undefined;
    }>
  >([]);
  const [fieldDefinitionInfo, setFieldDefinitionInfo] = useState<
    Array<{
      id: string;
      label: string;
      elements: Array<{
        id: string;
        type?: FormElementType;
        valueLabel?: string;
        execution?: FormExecutionType;
      }>;
      accordionOpen: boolean;
    }>
  >([]);

  const { updateAccountFieldValues } = useUpdatedAccountFields({
    accountDto,
    setUpdatedFieldDefs,
  });

  const getFieldDefValueLabel = (
    elementType: FormElementType | undefined,
    elementValue: FieldValue | undefined,
  ) => {
    switch (elementType) {
      case FormElementType.Selection:
      case FormElementType.Radio:
        return (elementValue as SelectionOption)?.name;
      case FormElementType.Autocomplete:
      case FormElementType.Text:
        return elementValue as string;
      case FormElementType.Checkbox:
        return (elementValue as boolean) ? "True" : "False";
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (accountDto.account_type_id) {
      AccountTypeAPI.getFieldDefinitions(accountDto.account_type_id).then(
        ({ selected, available }) => {
          setFieldDefinitionInfo(
            selected.map((fieldDefId) => {
              const fieldDef = available[fieldDefId];

              const accountFieldDefValue = accountDto.fields
                ? accountDto.fields[fieldDefId]
                  ? accountDto.fields[fieldDefId].value
                  : undefined
                : undefined;

              return {
                id: fieldDef.id,
                label: fieldDef.label,
                elements: fieldDef.data.elements.map((element) => {
                  const elementValue = accountFieldDefValue
                    ? accountFieldDefValue[element.id]
                    : undefined;

                  return {
                    id: element.id,
                    type: element.type,
                    valueLabel: getFieldDefValueLabel(
                      element.type,
                      elementValue,
                    ),
                    execution: {
                      ...element,
                      value: elementValue,
                    } as FormExecutionType,
                  };
                }),
                accordionOpen: false,
              };
            }),
          );
        },
      );
    }
  }, [accountDto]);

  return (
    <Box>
      {fieldDefinitionInfo.map(({ id, label, elements }, index) => (
        <Accordion
          disableGutters
          sx={{
            boxShadow: 2,
          }}
          key={index}
          onChange={(e, expanded) =>
            setFieldDefinitionInfo((prev) => {
              prev[index].accordionOpen = expanded;
              return prev;
            })
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              [`& .${accordionSummaryClasses.content}`]: {
                overflow: "hidden",
                alignItems: "center",
              },
            }}
          >
            <Typography
              sx={{
                flexShrink: 0,
                height: "fit-content",
              }}
            >
              {label}
            </Typography>
            {!fieldDefinitionInfo[index].accordionOpen &&
              elements.length == 1 &&
              elements[0].valueLabel && (
                <Chip
                  label={
                    <Typography
                      sx={{
                        fontSize: "small",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {elements[0].valueLabel}
                    </Typography>
                  }
                  sx={{
                    ml: 1,
                    overflow: "hidden",
                  }}
                />
              )}
          </AccordionSummary>
          <AccordionDetails>
            <TaskFieldDefinitionFormComponent
              field_definition_id={id}
              id={id}
              name={label}
              label={label}
              disabled={false}
              onValueChange={({ elementId, value: newExecution }) => {
                const newValue = (newExecution as { value?: FieldValue }).value;
                setFieldDefinitionInfo((prev) => {
                  prev[index].elements = prev[index].elements.map((e) => {
                    if (e.id == elementId)
                      return {
                        ...e,
                        execution: newExecution,
                        valueLabel: getFieldDefValueLabel(e.type, newValue),
                      };
                    else return e;
                  });
                  return prev;
                });
                const newFieldDefValues = updatedFieldDefs.filter(
                  (e) => !(e.fieldDefId == id && e.elementId == elementId),
                );
                newFieldDefValues.push({
                  fieldDefId: id,
                  elementId: elementId,
                  value: newValue,
                });
                setUpdatedFieldDefs(newFieldDefValues);
                updateAccountFieldValues(newFieldDefValues);
              }}
              execution={{ value: elements.map((e) => e.execution) }}
              formExecutionComponentProps={{}}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AccountFieldsAccordion;
