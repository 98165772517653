import {
  ArrowRightAlt,
  Cancel,
  CheckCircle,
  HourglassBottom,
  QuestionMarkOutlined,
  Schedule,
  WarningOutlined,
} from "@mui/icons-material";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import {
  StripeOrganizationSubscription,
  StripeProductType,
  StripeSubscriptionStatus,
} from "../../../model/OrganizationBillingState";

const SUBSCRIPTION_STATUS_CHIPS = {
  [StripeSubscriptionStatus.ACTIVE]: () => (
    <Chip label="Active, Paid" color="success" icon={<CheckCircle />} />
  ),
  [StripeSubscriptionStatus.TRIALING]: () => (
    <Chip label="Active, On Trial" color="success" icon={<HourglassBottom />} />
  ),
  [StripeSubscriptionStatus.PAUSED]: () => (
    <Tooltip title="Your subscription has been paused, which may impact your ability to access features.">
      <Chip label="Paused" color="warning" icon={<WarningOutlined />} />
    </Tooltip>
  ),
  [StripeSubscriptionStatus.CANCELED]: () => (
    <Tooltip title="This subscription has been canceled, and will remain active until the end of the billing cycle.">
      <Chip label="Active, Canceled" color="error" icon={<Schedule />} />
    </Tooltip>
  ),
  [StripeSubscriptionStatus.UNPAID]: () => (
    <Tooltip title="This subscription has not been paid for this billing cycle yet. Please check your payment information!">
      <Chip label="Active, Unpaid" color="warning" icon={<HourglassBottom />} />
    </Tooltip>
  ),
  [StripeSubscriptionStatus.PAST_DUE]: () => (
    <Tooltip title="This subscription is past due.">
      <Chip
        label="Active, Past Due"
        color="warning"
        icon={<WarningOutlined />}
      />
    </Tooltip>
  ),
  [StripeSubscriptionStatus.INCOMPLETE]: () => (
    <Tooltip title="Something went wrong when processing your subscription.">
      <Chip label="Incomplete" color="warning" icon={<WarningOutlined />} />
    </Tooltip>
  ),
  [StripeSubscriptionStatus.INCOMPLETE_EXPIRED]: () => (
    <Chip label="Incomplete, Expired" color="error" icon={<Cancel />} />
  ),
};

const OrganizationSubscriptionCard = ({
  subscription,
}: React.PropsWithChildren<{
  subscription: StripeOrganizationSubscription;
}>) => {
  // return the proper status chip based on the subscription status
  // if cannot find one based on the status, return a default chip (with unknown)
  const StatusChip =
    SUBSCRIPTION_STATUS_CHIPS[subscription.status] ||
    (() => (
      <Chip label="Unknown" color="default" icon={<QuestionMarkOutlined />} />
    ));

  const start_date = useMemo(() => {
    if (subscription.start_date) {
      return new Date(subscription.start_date).toLocaleDateString();
    }
    return "";
  }, [subscription.start_date]);

  const end_date = useMemo(() => {
    if (subscription.end_date) {
      return new Date(subscription.end_date).toLocaleDateString();
    }
    return "";
  }, [subscription.end_date]);

  const price = useMemo(() => {
    return `$${(subscription.amount / 100).toFixed(2)}`;
  }, [subscription.amount]);

  const subscription_name = useMemo(() => {
    if (subscription.products.length === 0) {
      return "Runway Subscription";
    }
    return subscription.products
      .map(
        (product) =>
          `Runway${product == StripeProductType.Unknown ? "" : ` ${product}`}`,
      )
      .join(", ");
  }, [subscription.products]);

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      p={2}
      sx={{
        my: 1,
        border: 1,
        borderColor: (theme) => theme.palette.divider,
        borderRadius: 1,
      }}
    >
      <Box>
        <Typography variant={"h6"} fontWeight={"bold"}>
          {subscription_name}
        </Typography>
        {subscription.start_date && (
          <Box display={"flex"} alignItems={"center"}>
            <Typography display={"block"} variant={"body2"} marginRight={0.5}>
              Current Billing Period: {start_date}
            </Typography>
            <ArrowRightAlt />

            <Typography display={"block"} variant={"body2"} marginLeft={0.5}>
              {end_date}
            </Typography>
          </Box>
        )}
        {subscription.description && (
          <Typography display={"block"} variant="caption">
            {subscription.description}
          </Typography>
        )}
      </Box>
      <Box alignContent={"end"} textAlign={"right"}>
        <StatusChip />
        {subscription.products.includes(StripeProductType.Connect) ? (
          <Tooltip title="This subscription is metered, and the price may vary.">
            <Typography
              display={"block"}
              variant={"h6"}
              fontWeight={"bold"}
              marginTop={1}
            >
              Metered Usage
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            display={"block"}
            variant={"h6"}
            fontWeight={"bold"}
            marginTop={1}
          >
            {price}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default OrganizationSubscriptionCard;
