import { useCallback, useEffect, useState } from "react";
import Processes from "../../../../../api/processes";
import {
  fetchUsers,
  UserOrInvitedUser,
} from "../../../../../components/core/Selection/UserSelection";
import UserAssignment, {
  doesUserHaveTargetRole,
  UserAssignmentStruct,
} from "../../../../../components/core/UserAssignment/UserAssignment";

import {
  TaskAssignment,
  TaskAssignmentExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../types";
import { DEFAULT_RENDER } from "../TaskBody";

const AssignmentExecutionComponent = ({
  task,
  taskExecution,
  isDisabled,
  onExecute,
  children = DEFAULT_RENDER,
}: TaskExecutionComponentProps<TaskAssignment, TaskAssignmentExecution>) => {
  const targetRoleId = task.data?.targetRoleId ?? "";

  const [selectedUsers, setSelectedUsers] = useState<
    Array<Partial<UserOrInvitedUser> & { id: string; hasTargetRoleId: boolean }>
  >([]);
  const [assignees, setAssignees] = useState<string[]>();

  const populateSelectedUsers = useCallback(async () => {
    const process = await Processes.get(task.process_id);
    const users = (await fetchUsers(process.org_id)).filter((u) =>
      task.execution_data?.assignees?.includes(u.id),
    );

    setSelectedUsers(
      users.map((user: UserOrInvitedUser) => ({
        ...user,
        invitationId: user.invitationId,
        hasTargetRoleId: doesUserHaveTargetRole(user, targetRoleId),
      })),
    );
  }, [task.execution_data?.assignees, task.process_id, targetRoleId]);

  useEffect(() => {
    populateSelectedUsers();
  }, [populateSelectedUsers, task.execution_data?.assignees]);

  useEffect(() => {
    if (taskExecution?.data?.assignees) {
      setAssignees(taskExecution?.data?.assignees);
    }
  }, [taskExecution?.data?.assignees]);

  return children({
    content: (
      <UserAssignment
        assignees={assignees}
        selectedUsers={selectedUsers}
        targetRoleId={targetRoleId}
        isDisabled={isDisabled}
        onExecute={function (userAssignment: UserAssignmentStruct): void {
          onExecute &&
            onExecute({
              ...taskExecution,
              task_id: task.id,
              state: TaskExecutionState.Completed,
              data: userAssignment,
            });
        }}
      />
    ),
  });
};

export default AssignmentExecutionComponent;
