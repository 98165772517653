import WebSocketMessage, {
  WebSocketTrackingData,
} from "../../model/WebSocketMessage";
import eventEmitter from "../event";

/**
 * Handles a tiny notification message that is received from the server
 * The tiny notification requires the React frontend
 * Calls the react event handler with the message
 */
export const handleTracking = async (message: WebSocketMessage) => {
  let data: WebSocketTrackingData;

  try {
    data = message.data as WebSocketTrackingData;
  } catch (error) {
    return;
  }

  eventEmitter.emit("websocket:tracking", data);
};
