import { DialogContentText, TextField } from "@mui/material";
import Dialog from "../../../components/Dialog";
import { FC, useState } from "react";
import { useAlert } from "../../../lib/alert";
import { Organization } from "../../../model";
import Invitation, { InvitationType } from "../../../model/Invitation";
import Invitations from "../../../api/invitations";
import { getInvitationDisplayData } from "../../invitations/InvitationDisplay";
import { useGlobalUserContext } from "../../../hooks/useGlobalUserContext";

type InvitationDialogProps = {
  open: boolean;
  organization: Organization;
  accountId?: string;
  setInvitationDialogOpen?: (open: boolean) => void;
  handleInvitation?: (invitation: Invitation) => void;
};
const InvitationDialog: FC<InvitationDialogProps> = (props) => {
  const { handleRejectionWithError } = useAlert();
  const { user } = useGlobalUserContext();

  const [invitedUserEmailAddress, setInvitedUserEmailAddress] =
    useState<string>("");

  return (
    <Dialog
      open={props.open}
      title={"Invite Users"}
      confirmText={"Invite"}
      cancelText={"Cancel"}
      handleConfirm={(): Promise<void> => {
        if (!props.organization) {
          return Promise.resolve();
        }

        const invitation = new Invitation();
        invitation.organization_id = props.organization.id;
        invitation.target_user_email = invitedUserEmailAddress.toLowerCase();
        if (props.accountId) {
          invitation.account_id = props.accountId;
          invitation.type = InvitationType.Account;
        } else {
          invitation.type = InvitationType.Member;
        }
        invitation.display = getInvitationDisplayData(user, props.organization);

        return Invitations.create(invitation).then(() => {
          if (props.setInvitationDialogOpen) {
            props.setInvitationDialogOpen(false);
          }
          props.handleInvitation?.(invitation);
          setInvitedUserEmailAddress("");
        }, handleRejectionWithError("Failed to create invitation"));
      }}
      handleCancel={function (): void {
        if (props.setInvitationDialogOpen) {
          props.setInvitationDialogOpen(false);
        }
      }}
    >
      <DialogContentText>
        Enter the email address of the user to invite.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="email-address"
        label="Email Address"
        type="text"
        fullWidth
        variant="standard"
        value={invitedUserEmailAddress}
        onChange={(e) => setInvitedUserEmailAddress(e.target.value as string)}
      />
    </Dialog>
  );
};

export default InvitationDialog;
