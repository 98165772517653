import { ChatMessage, EmailChatMessage, TrimmedEmailLine } from "./model";

const trimLine = (line: string) => {
  let i = 0;
  for (; i < line.length; i++) {
    const chr = line.charAt(i);
    if (chr !== ">" && chr !== " ") {
      break;
    }
  }

  return { quoted: i !== 0, trimmedLine: line.substring(i).trim() };
};

const findDuplicateOrQuotedLines = (
  lines: string[],
  previousLines: Map<string, TrimmedEmailLine[]>,
): TrimmedEmailLine[] => {
  return lines.map((line) => {
    // 1. trim each line to remove quoted ">"
    const { quoted, trimmedLine } = trimLine(line);

    // 2. check if the line has already been seen
    const seen = previousLines.has(trimmedLine);

    // 3. return processed lines for future comparisons
    return { line, trimmedLine, seen, quoted };
  });
};

export default (messages: ChatMessage[]): EmailChatMessage[] => {
  const scrubbedMessages = [];
  const previousLines = new Map<string, TrimmedEmailLine[]>();
  for (const msg of messages) {
    if (msg.data?.text) {
      const trimmedLines = findDuplicateOrQuotedLines(
        msg.data.text.split("\n"),
        previousLines,
      );
      scrubbedMessages.push({ ...msg, linesOfText: trimmedLines });
      trimmedLines.forEach((line) => {
        if (line.trimmedLine.length === 0) {
          return;
        }

        let previousDuplicateLines = previousLines.get(line.trimmedLine);
        if (!previousDuplicateLines) {
          previousLines.set(line.trimmedLine, (previousDuplicateLines = []));
        }
        previousDuplicateLines.push(line);
      });
    } else {
      scrubbedMessages.push(msg);
    }
  }
  return scrubbedMessages;
};
