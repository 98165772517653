import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import { CloseRounded } from "@mui/icons-material";
import { useAtom } from "jotai";
import { useIntl } from "react-intl";
import { AccountTypeAPI } from "../../../api";
import processCategories from "../../../api/processCategories";
import Dialog from "../../../components/Dialog";
import { IconSelector } from "../../../components/core/IconPicker";
import useFeature from "../../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { AccountType } from "../../../model/AccountType";
import { ACCOUNT_TYPE } from "../../../model/EntityRef";
import { Process } from "../../../model/Process";
import { ProcessCategory } from "../../../model/ProcessCategory";
import { accountTypesAtom } from "../../organization/state/accountTypesAtom";
import messages from "../messages";
import ProcessCategorySelect from "./ProcessCategorySelect";
import ProcessExecutorDetailsEditComponent from "./ProcessExecutorDetailsEditComponent";

export type ProcessDetailsEditDialogProps = {
  open: boolean;
  handleConfirm: (processDetails: Partial<Process>) => Promise<void>;
  handleCancel: () => void;
  process: Partial<Process>;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  showSubjectField?: boolean;
};

const ProcessDetailsEditDialog = ({
  open,
  handleConfirm,
  handleCancel,
  process,
  title,
  confirmText = "Save",
  cancelText = "Cancel",
  showSubjectField = true,
}: ProcessDetailsEditDialogProps) => {
  const executorFeature = useFeature("executors");
  const { formatMessage } = useIntl();
  const { organization } = useGlobalOrganizationContext();
  const [accountTypes, setAccountTypes] = useAtom(accountTypesAtom);
  const [name, setName] = useState(process.name);
  const [description, setDescription] = useState(process.description);
  const [icon, setIcon] = useState(process.icon);
  const [counterpartyCanExecute, setCounterpartyCanExecute] = useState<
    boolean | undefined
  >(true);
  const [accountType, setAccountType] = useState<AccountType | undefined>(
    undefined,
  );
  const [categoryList, setCategoryList] = useState<ProcessCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ProcessCategory>();
  const processMsg = formatMessage(messages.process.props);
  const dialogTitle = title ?? `Edit ${processMsg} Details`;

  const canTaskBeAssigned = useFeature("process.assigned");

  useEffect(() => {
    setName(process.name);
    setDescription(process.description);
    setIcon(process.icon);
    if (canTaskBeAssigned) {
      setAccountType(
        process.entity_id
          ? accountTypes?.find(
              (accountType) => accountType.id === process.entity_id,
            )
          : undefined,
      );
    }
    setSelectedCategory(undefined); // resets to undefined to make sure the query box starts empty
    setCounterpartyCanExecute(
      process.entity_id ? process.counterparty_can_execute : true,
    );
    if (process.category_id) {
      processCategories
        .get(process.category_id)
        .then((category) => setSelectedCategory(category));
    }
  }, [
    process.name,
    process.description,
    process.icon,
    process.entity_id,
    process.category_id,
    process.counterparty_can_execute,
    accountTypes,
  ]);
  useEffect(() => {
    if (!accountTypes && organization?.id && canTaskBeAssigned) {
      AccountTypeAPI.byOrganizationId(organization?.id).then((accountTypes) => {
        setAccountTypes(accountTypes);
      });
    }
  }, [accountTypes, organization?.id, setAccountTypes, canTaskBeAssigned]);

  useEffect(() => {
    if (organization)
      processCategories.byOrganization(organization.id).then((categories) => {
        setCategoryList(categories);
      });
  }, [organization]);

  const createNewCategory = async (
    categoryLabel: string,
    parentCategoryId?: string,
  ) => {
    if (
      categoryList.findIndex((category) => category.label === categoryLabel) !=
      -1
    )
      return false;

    if (organization) {
      const newCategory = await processCategories.create({
        label: categoryLabel,
        org_id: organization.id,
        parent_id: parentCategoryId,
      } as ProcessCategory);

      const updatedCategories = categoryList.slice();
      updatedCategories.push(newCategory);

      setCategoryList(updatedCategories);
      setSelectedCategory(newCategory);

      return true;
    }
  };

  return (
    <Dialog
      open={open}
      title={dialogTitle}
      fullWidth={true}
      confirmText={confirmText}
      cancelText={cancelText}
      handleConfirm={async () => {
        handleConfirm({
          name,
          org_id: organization?.id,
          description,
          icon,
          entity_id: accountType?.id,
          entity_type: accountType ? ACCOUNT_TYPE : undefined,
          counterparty_can_execute: counterpartyCanExecute,
          category_id: selectedCategory ? selectedCategory.id : undefined,
        });
      }}
      maxWidth="xs"
      handleCancel={() => {
        setName(process.name);
        setDescription(process.description);
        handleCancel();
      }}
    >
      <Box display="flex" flexDirection="column" sx={{ gap: "16px" }}>
        <TextField
          autoFocus
          margin="dense"
          id="process-name"
          label={`${formatMessage(messages.process.props)} Name*`}
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value as string)}
        />

        <IconSelector
          defaultIcon={process.icon}
          onChange={(iconName) => {
            setIcon(iconName);
          }}
        />
        <ProcessCategorySelect
          categories={categoryList}
          defaultCategory={selectedCategory}
          onChange={(category) => setSelectedCategory(category)}
          createNew={createNewCategory}
        />
        <TextField
          margin="dense"
          id="process-description"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value as string)}
        />
        {showSubjectField &&
          canTaskBeAssigned &&
          (accountTypes?.length || 0) > 0 && (
            <FormControl fullWidth variant="standard" key={accountType?.id}>
              <InputLabel>Account Type</InputLabel>
              <Select
                variant="standard"
                onChange={(event) =>
                  setAccountType(
                    accountTypes?.find(
                      (at) => (event.target.value as string) === at.id,
                    ),
                  )
                }
                value={accountType?.id}
                displayEmpty={true}
                renderValue={(value) =>
                  accountTypes?.find((at) => at.id === value)?.name ?? ""
                }
                endAdornment={
                  accountType && (
                    <IconButton
                      sx={{ mr: 3 }}
                      size="small"
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onClick={() => {
                        setAccountType(accountType);
                      }}
                    >
                      <CloseRounded />
                    </IconButton>
                  )
                }
              >
                {accountTypes?.map((accountType) => (
                  <MenuItem key={accountType.id} value={accountType.id}>
                    {accountType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        {showSubjectField && canTaskBeAssigned && (
          <FormGroup>
            <FormControlLabel
              label={`${
                accountType?.name ?? "Account"
              } can start ${processMsg}`}
              control={
                <Checkbox
                  disabled={!accountType}
                  checked={counterpartyCanExecute}
                  onChange={(evt) => {
                    setCounterpartyCanExecute(evt.target.checked);
                  }}
                />
              }
            />
          </FormGroup>
        )}
        {process.id && executorFeature && (
          <ProcessExecutorDetailsEditComponent processId={process.id} />
        )}
      </Box>
    </Dialog>
  );
};
export default ProcessDetailsEditDialog;
