import EmailIcon from "@mui/icons-material/Email";
import { Box, Link, Paper, Typography } from "@mui/material";
import { AccountProfileViewer } from "./AccountProfilePage";
import OrganizationAccountPage from "./OrganizationAccountPage";
import useOrganizationAccountPage from "./hooks/useOrganizationAccountPage";

type AccountPageProps = {
  viewer: AccountProfileViewer;
};

export const AccountPage = ({ viewer }: AccountPageProps) => {
  const accountPageContext = useOrganizationAccountPage(viewer);
  if (!accountPageContext) {
    return <></>;
  }

  const { account } = accountPageContext;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 220,
          alignItems: "center",
          padding: 4,
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: "20%",
            display: "flex",
            flexDirection: "row",
            flex: 1,
            borderRadius: 2,
            alignItems: "center",
            overflow: "auto",
            backgroundColor: (theme) => theme.palette.grey[100],
            "@media print": {
              overflow: "visible",
              border: "none",
              boxShadow: "unset",
            },
            zIndex: 12,
          }}
          elevation={3}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                alignItems: "left",
                flexDirection: "column",
                display: "flex",
                ml: 3,
              }}
            >
              <Typography variant="h2" sx={{ marginBottom: 0 }}>
                {account.account_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "left",
                  marginTop: 1,
                }}
              >
                <EmailIcon sx={{ marginRight: 0.5 }} />
                <Link
                  href={`mailto:${account.primary_email}`}
                  sx={{
                    textDecoration: "none",
                    color: "primary.main",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ margin: 0 }}>
                    {account.primary_email}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box>
        <OrganizationAccountPage viewer={viewer} />
      </Box>
    </Box>
  );
};

export default AccountPage;
