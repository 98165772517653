import {
  AddCircle,
  DeleteOutlined,
  Send,
  SyncAltOutlined,
} from "@mui/icons-material";
import { Box, Button, Chip, Tooltip } from "@mui/material";
import {
  DataGridProps,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import debounce from "lodash.debounce";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { OrganizationAPI } from "../../../api";
import Invitations from "../../../api/invitations";
import Roles from "../../../api/roles";
import { CommonDatagridWrapper } from "../../../components/elements";
import DatagridToolbar from "../../../components/elements/datagrid/DatagridToolbar";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";
import ToolbarButton from "../../../components/elements/datagrid/ToolbarButton";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import { Organization, Role, User } from "../../../model";
import Invitation from "../../../model/Invitation";
import {
  invitationProvider,
  userProvider,
} from "../../../providers/OrganizationMemberProvider";
import { CombinedUserAndInvitationModel } from "../../../types";
import RoleAutocompleteComponent from "./RoleAutocompleteComponent";

type CommonOrganizationUsersProps = {
  organizationId: string;
  invitationDialogOpen?: boolean;
  isDirty?: Date;
  onOpenInvitationDialog?: () => void;
  onDataLoaded?: (members: Array<User>, invitations: Array<Invitation>) => void;
  allowInvitation?: boolean;
};

const CommonOrganizationUsers: FC<CommonOrganizationUsersProps> = ({
  onDataLoaded,
  ...props
}) => {
  const { organization, refreshOrganizations } = useGlobalOrganizationContext();
  const isAdmin = useIsAdmin();
  const isOwner = useMemo(
    () => organization?.owner_id === currentUserId(),
    [organization],
  );
  const allowInvitation =
    (props.allowInvitation === undefined || props.allowInvitation) && isAdmin
      ? true
      : false;

  // TODO: Need to pull column definition out of the fields that the
  // user object exposes by API
  const usersColumns = [
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      hideable: false,
      flex: 2,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box
            component={"span"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              "&:hover": {
                overflow: "auto",
                textOverflow: "clip",
              },
            }}
          >
            {params.row.email || params.row.target_user_email}
          </Box>
        );
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: () => {
        return "Pending";
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ width: 1 }}>
            {params.row.target_user_email ? (
              <Tooltip title="An invitation is still pending">
                <Chip label={"Pending"} sx={{ width: 1 }} />
              </Tooltip>
            ) : (
              <>
                {params.row.id == organization?.owner_id ? (
                  <Tooltip title="This member is the owner of the organization">
                    <Chip color="info" label={"Owner"} sx={{ width: 1 }} />
                  </Tooltip>
                ) : (
                  <Chip color="success" label="Member" sx={{ width: 1 }} />
                )}
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: () => {
        return "Loading";
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <RoleAutocompleteComponent
            userId={params.row.id}
            isInvitation={params.row.target_user_email ? true : false}
            organizationId={props.organizationId}
            assignedRoles={params.row.roles}
            organizationRoles={organizationRoles}
            disabled={!isAdmin}
          />
        );
      },
    },
  ];

  const { success, handleRejectionWithError } = useAlert();

  const [usersAndInvitationsCombined, setUsersAndInvitationsCombined] =
    useState<Array<CombinedUserAndInvitationModel>>([]);

  const [userSelectionModel, setUserSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [numRowsSelected, setNumRowsSelected] = useState<number>(0);

  const [isLoaded, setIsLoaded] = useState(false);

  const [organizationRoles, setOrganizationRoles] = useState<Array<Role>>([]);

  const removeSelection = () => {
    setIsLoaded(false);

    const promises = userSelectionModel.map((id: GridRowId) => {
      // TODO: Remove quadratic
      const found = usersAndInvitationsCombined.find(
        (item) => item.id === id.toString(),
      );

      if (!found) {
        console.warn(
          "Unable to find matching model object for invitation or user id: " +
            id.toString(),
        );
        return;
      }

      if ("email" in found) {
        return userProvider.removeFromOrganization(
          props.organizationId,
          id.toString(),
        );
      } else if ("target_user_email" in found) {
        return invitationProvider.delete(id.toString());
      } else {
        console.warn(
          "Unable to detect type of invitation or user id: " + id.toString(),
        );
      }
    });
    Promise.all(promises).then(() => {
      reload();
    }, handleRejectionWithError("Failed to load invitations and users for organization"));
  };

  const transferOwnership = () => {
    const user = userSelectionModel[0];
    const found = usersAndInvitationsCombined.find(
      (item) => item.id === user.toString(),
    );

    if (!found) {
      console.warn(
        "Unable to find matching model object for invitation or user id: " +
          user.toString(),
      );
      return;
    }

    // This is an invitation, do nothing
    if ("target_user_email" in found || !organization) {
      return;
    }

    // This is a user, transfer ownership
    const updatedOrganization: Organization = {
      ...organization,
      owner_id: found.id,
    };

    OrganizationAPI.update(updatedOrganization).then(() => {
      success("Successfully transferred ownership");
      refreshOrganizations();
      organization.owner_id = found.id;
    }, handleRejectionWithError("Failed to transfer ownership"));
  };

  const handleResendUserInvite = () => {
    const promises = userSelectionModel.map((id: GridRowId) => {
      // TODO: Remove quadratic
      const found = usersAndInvitationsCombined.find(
        (item) => item.id === id.toString(),
      );

      if (!found) {
        console.warn(
          "Unable to find matching model object for invitation or user id: " +
            id.toString(),
        );
        return;
      }

      if ("target_user_email" in found) {
        return Invitations.resend(id.toString());
      }
    });
    Promise.all(promises).then(() => {
      success("Successfully resent all invitations to pending users");
    }, handleRejectionWithError("Failed to resend all invitations"));
  };

  /**
   * The idea here is to first see if there are any users selected, then see if any are pending. We want to allow it so if a user is selected that is pending and one that has accpeted, we can still click to resend the invitations so we don't have a blocker. The .handleResendUserInvite will then actually handle who gets an invitation.
   * @returns boolean
   */
  const includesInvitableUsers = () => {
    const hasUsers = !!userSelectionModel.length;
    const users = new Map<string, CombinedUserAndInvitationModel>(
      usersAndInvitationsCombined.map((user) => [user.id, user]),
    );
    const hasInvitableUser = userSelectionModel.some((id) => {
      const foundUser = users.get(id.toString());
      if (!foundUser) return false;
      return "target_user_email" in foundUser;
    });

    return hasUsers && hasInvitableUser;
  };

  const finishLoading = useCallback(
    (invitations: Array<Invitation>, users: Array<User>) => {
      let usersAndInvitations: Array<CombinedUserAndInvitationModel> = [];

      // Ordering is important here, we want invitations to appear first
      usersAndInvitations = usersAndInvitations.concat(invitations);
      usersAndInvitations = usersAndInvitations.concat(users);

      setUsersAndInvitationsCombined(usersAndInvitations);
      setIsLoaded(true);
      if (onDataLoaded) {
        onDataLoaded(users, invitations);
      }
    },
    [onDataLoaded],
  );

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          Promise.all([
            invitationProvider.fetch(props.organizationId),
            userProvider.fetch(props.organizationId),
            Roles.byOrganization(props.organizationId),
          ]).then((result) => {
            reload.cancel();
            const [invitations, users, organizationRoles] = result;
            setOrganizationRoles(organizationRoles);
            finishLoading(invitations, users);
          }, handleRejectionWithError("Failed to load invitations and users for organization"));
        },
        5000,
        { leading: true },
      ),
    [props.organizationId, finishLoading, handleRejectionWithError],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    if (props.isDirty && props.organizationId) {
      reload();
    }
  }, [props.organizationId, reload, props.isDirty]);

  // determine if the transfer ownership button should be enabled given a selection model
  const canTransferOwnership = useMemo(() => {
    // do not enable if more than 1 row
    if (numRowsSelected !== 1) return false;
    const selectedUser = usersAndInvitationsCombined.find(
      (item) => item.id === userSelectionModel[0],
    );
    // do not enable if there is an error finding the user
    if (!selectedUser) return false;
    // do not enable if the selected user is the current user
    if (currentUserId() === selectedUser.id) return false;
    // otherwise, can transfer ownership
    return true;
  }, [numRowsSelected, userSelectionModel, usersAndInvitationsCombined]);

  const deleteBtn = (
    <ToolbarButton
      dataCy="delete-member-btn"
      startIcon={<DeleteOutlined />}
      onClick={() => removeSelection()}
      textContent="Delete"
      key="deleteBtn"
    />
  );

  const transferBtn = (
    <ToolbarButton
      dataCy="transfer-ownership-btn"
      startIcon={<SyncAltOutlined />}
      disabled={!canTransferOwnership}
      onClick={() => transferOwnership()}
      textContent="Transfer Ownership"
      key="transferBtn"
    />
  );

  const resendBtn = (
    <ToolbarButton
      dataCy="resend-invite-btn"
      startIcon={<Send />}
      disabled={includesInvitableUsers() ? false : true}
      onClick={() => handleResendUserInvite()}
      textContent="Resend Invitation"
      key="resendBtn"
    />
  );

  const adminProps: Partial<DataGridProps> = {
    onRowSelectionModelChange: (newSelectionModel) => {
      setUserSelectionModel(newSelectionModel);
      setNumRowsSelected(newSelectionModel.length);
    },
    rowSelectionModel: userSelectionModel,
    checkboxSelection: true,
  };

  const loaded = (
    <StyledDataGrid
      slots={{ toolbar: DatagridToolbar }}
      slotProps={{
        toolbar: {
          countMsg: `${usersAndInvitationsCombined.length} Members`,
          numSelected: numRowsSelected,
          selectedBtns: isAdmin ? (
            <>
              {isOwner && transferBtn}
              {resendBtn}
              {deleteBtn}
            </>
          ) : (
            <></>
          ),
        },
      }}
      disableColumnSelector
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
      rows={usersAndInvitationsCombined}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columns={usersColumns}
      {...(isAdmin ? adminProps : {})}
    />
  );

  return (
    <CommonDatagridWrapper
      isLoaded={isLoaded}
      loaded={loaded}
      title="Members"
      PageHeaderProps={{
        actions: allowInvitation && (
          <Button
            variant="contained"
            onClick={() => {
              if (props.onOpenInvitationDialog) {
                props.onOpenInvitationDialog();
              }
            }}
          >
            <AddCircle sx={{ mr: "0.25em" }} />
            Invite Member
          </Button>
        ),
      }}
    />
  );
};

export default CommonOrganizationUsers;
