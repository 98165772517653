import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountsAPI from "../../api/accounts";
import { AccountDto } from "../../model/Account";
import { AccountProfileViewer } from "./AccountProfilePage";
import useOrganizationAccountPage from "./hooks/useOrganizationAccountPage";
type AccountDetailsPageProps = {
  viewer: AccountProfileViewer;
};

const AccountDetailsPage = ({ viewer }: AccountDetailsPageProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [tempAccount, setTempAccount] = useState<AccountDto>();
  const accountPageContext = useOrganizationAccountPage(viewer);

  useEffect(() => {
    if (accountPageContext && !tempAccount) {
      setTempAccount(accountPageContext.account);
      setIsChecked(accountPageContext.account.is_test || false);
    }
  });

  if (!accountPageContext) {
    return <></>;
  }

  const { account, setAccount } = accountPageContext;

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTempAccount((prevAccount) =>
      prevAccount ? { ...prevAccount, [name]: value } : prevAccount,
    );
  };

  const handleEdit = () => {
    setTempAccount(account);
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (tempAccount) {
      setAccount({ ...tempAccount, is_test: isChecked });
      await AccountsAPI.update({ ...tempAccount, is_test: isChecked });
    }

    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsChecked(account.is_test || false);
    setTempAccount(account);

    setIsEditing(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Typography variant="h6" sx={{ ml: 3, mt: 1, fontWeight: "bold" }}>
          Account Details
        </Typography>
        {isEditing ? (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Button
              sx={{ mt: 0.5, width: "71px" }}
              variant="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              sx={{
                mt: 0.5,
                width: "71px",
                color: "white",
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              variant="outlined"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        ) : (
          <Button
            sx={{
              mt: 0.5,
              width: "71px",
              color: "white",
              backgroundColor: (theme) => theme.palette.primary.main,
              "&:hover": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
            variant="outlined"
            onClick={handleEdit}
          >
            Edit
          </Button>
        )}
      </Box>
      <Box sx={{ marginBottom: 4, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              Account
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="account_name"
              value={tempAccount?.account_name}
              onChange={handleTextChange}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: !isEditing }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                },
                width: "400px",
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="primary_email"
              value={tempAccount?.primary_email}
              onChange={handleTextChange}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: !isEditing }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                },
                width: "400px",
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              Account Type
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1" sx={{ ml: "14px", mt: 1 }}>
              {account.account_type_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              Test Account
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Switch
              color={isEditing ? "primary" : "default"}
              disabled={!isEditing}
              checked={isEditing ? isChecked : account?.is_test}
              onChange={handleSwitchChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AccountDetailsPage;
