import { Box, Button, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { Organization } from "../../model";
import CreateOrganizationComponent from "./components/CreateOrganizationComponent";

const CreateOrganizationPage: FC<unknown> = () => {
  const { organization, setOrganization, refreshOrganizations } =
    useGlobalOrganizationContext();
  const [newOrganization, setNewOrganization] = useState<
    Organization | undefined
  >();
  const navigate = useNavigate();

  // state for tracking whether or not the wizard is completed
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    setOrganization(undefined);
  }, [setOrganization, organization]);

  if (organization) {
    return <></>;
  }

  return (
    <Box sx={{ maxWidth: "60%", margin: "auto", mt: 2 }}>
      <CreateOrganizationComponent
        onCreate={(organization) => {
          setNewOrganization(organization);
          refreshOrganizations();
          setCompleted(true);
        }}
      />
      {completed && (
        <Box sx={{ margin: "auto" }}>
          <Box sx={{ my: 4 }}>
            <Typography>Your organization was created successfully!</Typography>
            <Button
              sx={{ my: 2 }}
              variant="contained"
              onClick={() => {
                setOrganization(newOrganization);
                navigate("/user/organization/members");
              }}
            >
              Invite Your Team
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreateOrganizationPage;
