import { Box, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ManageOrganizationMenu from "../../../features/organization/components/ManageOrganizationMenu";
import useFeature from "../../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import eventEmitter from "../../../lib/event";
import { showNotification } from "../../../lib/notification";
import { createNotificationInteraction } from "../../../lib/websocket/notificationHandler";
import { NotificationDestination } from "../../../model/EventData";
import {
  WebSocketNotificationData,
  WebSocketTinyNotificationData,
} from "../../../model/WebSocketMessage";
import PATHS from "../../navigation/_paths";
import AccountButton from "../top-bar/components/AccountButton";
import { OrganizationTab } from "./OrganizationTab";

export const OrganizationSelector = () => {
  const canCreateOrganization = useFeature("organization.create");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    organization,
    organizations,
    setOrganization,
    accountListIsMemberOf,
  } = useGlobalOrganizationContext();

  const { info } = useAlert();

  // handle a notification whenever it is received
  // handles here so that it can get the organization icon
  eventEmitter.on(
    "websocket:taskNotification",
    (notificationData: WebSocketNotificationData) => {
      if (document.hasFocus()) {
        // send a tiny notification if the user is already active on the page
        info(notificationData.body);
        return;
      }

      // get the icon for the notification
      // the default is the Runway favicon
      let icon = "/favicon.ico";
      if (notificationData.organization_id) {
        const org = organizations.find(
          (org) => org.id === notificationData.organization_id,
        );
        if (org && org.theme?.shortLogoUrl) {
          icon = org.theme?.shortLogoUrl;
        }
      }

      if (notificationData.type == "task") {
        // also show a tiny notification
        const tinyNotificationData: WebSocketTinyNotificationData = {
          type: "INFO",
          message: notificationData.body,
        };

        eventEmitter.emit("websocket:tinyNotification", tinyNotificationData);
      }

      // show notification natively
      showNotification(
        notificationData.title,
        notificationData.body,
        notificationData.id,
        icon,
        () => {
          createNotificationInteraction("CLICKED", notificationData.id);
          eventEmitter.emit("websocket:notificationClicked", {
            organization_id: notificationData.organization_id,
            process_execution_id: notificationData.process_execution_id,
            process_id: notificationData.process_id,
          });
        },
        () => {
          createNotificationInteraction("SHOWN", notificationData.id);
        },
        () => {
          createNotificationInteraction("CLOSED", notificationData.id);
        },
      );
    },
  );

  // handle the notification being clicked for a page redirect
  // tries to get the running process execution and task
  eventEmitter.on(
    "websocket:notificationClicked",
    (data: NotificationDestination) => {
      if (data.organization_id) {
        // try to get the organization referenced in the notification
        const org = organizations.find(
          (org) => org.id === data.organization_id,
        );
        if (org) {
          setOrganization(org);
        }

        if (data.process_execution_id && data.process_id) {
          navigate(
            PATHS.EXECUTION.linkTo(data.process_id, data.process_execution_id),
          );
          window.focus();
        }
      }
    },
  );

  return (
    <Box
      id="organization-selector"
      sx={{
        displayPrint: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={organization?.key ?? false}
        TabIndicatorProps={{ hidden: true }}
        textColor={"inherit"}
        sx={{
          flex: 1,
          "& .MuiTab-root": {
            "&:first-of-type": {
              mt: 1,
            },
          },
        }}
      >
        {organizations.map((organization) => (
          <OrganizationTab
            key={organization.id}
            value={organization.key}
            organization={organization}
            onClick={() => {
              setOrganization(organization);
              if (
                !pathname.startsWith("/user/organization/") ||
                pathname.search(
                  /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[4][0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/,
                ) >= 0
              ) {
                navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
              }
            }}
          />
        ))}
        {accountListIsMemberOf.map((account) => {
          if (!account.organization) return;
          return (
            <OrganizationTab
              key={account.organization.id}
              value={account.organization.key}
              organization={account.organization}
              onClick={() => {
                setOrganization(account.organization);
                navigate(PATHS.ACCOUNT_PORTAL.linkTo(account.id));
              }}
            />
          );
        })}
      </Tabs>
      {canCreateOrganization && <ManageOrganizationMenu />}
      <AccountButton />
    </Box>
  );
};
