import { CheckCircleOutline, HourglassBottom } from "@mui/icons-material";
import { Box, Chip, SxProps, Tooltip, Typography } from "@mui/material";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import useFeature from "../../../hooks/useFeature";
import eventEmitter from "../../../lib/event";
import {
  NotificationEventType,
  ProcessExecutionTracking,
} from "../../../model/NotificationEvent";
import { ProcessExecution } from "../../../model/ProcessExecution";
import { WebSocketTrackingData } from "../../../model/WebSocketMessage";
import TaskExecutionMiniTracker from "../../task/components/execution/TaskExecutionMiniTracker";
import messages from "../messages";

/**
 * Type defined to show a certain status in the chip
 * Includes a tooltip ReactElement to display custom information from the process passed in
 */
type ProcessExecutionStatusType = {
  icon: JSX.Element;
  color:
    | "default"
    | "success"
    | "warning"
    | "error"
    | "primary"
    | "secondary"
    | "info";
  bgcolor: string;
  title: string;
  tooltip: ReactElement;
};

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    complete: true;
  }
}

const ProcessExecutionStatusChip = ({
  process,
  onClick,
  sx,
}: {
  process: ProcessExecution;
  onClick?: () => void;
  sx?: SxProps;
}) => {
  const intl = useIntl();
  const status = useMemo(() => {
    return process.state;
  }, [process]);

  const executionMsg = intl.formatMessage(messages.execution.props);
  const inProgressExecutionsMsg = intl.formatMessage(
    messages.inProgressExecutions.props,
  );
  const completedExecutionsMsg = intl.formatMessage(
    messages.completedExecutions.props,
  );

  const [trackingUpdates, setTrackingUpdates] = useState<
    ProcessExecutionTracking[]
  >([]);

  const allowRequestTracking = useFeature("process.tracker");

  /**
   * Initializes the notification_events in the chip
   */
  useEffect(() => {
    // move process.notification_events to trackingUpdates
    // There's a chance that the process has the old notification_events model
    // will just remove all events from original
    if (
      process.notification_events &&
      Array.isArray(process.notification_events)
    ) {
      const trackingUpdates: ProcessExecutionTracking[] = [];
      for (const tracking of process.notification_events) {
        trackingUpdates.push({
          timestamp: new Date(tracking.timestamp),
          state: tracking.eventType,
        });
      }

      setTrackingUpdates(trackingUpdates);
    }
  }, [process.notification_events]);

  // TODO: pass down tracking information to be shown in ProcessExecutionStatusChip Tooltip
  eventEmitter.on("websocket:tracking", (data: WebSocketTrackingData) => {
    if (data.process_execution_id === process.id) {
      const currentTimeStamp = new Date();

      // check if the state is already in the tracking updates
      const foundState = trackingUpdates.find(
        (trackingUpdate) => trackingUpdate.state === data.state,
      );

      const notificationEventState: NotificationEventType =
        data.state as NotificationEventType;

      // if the state is not found, add it to the tracking updates
      if (foundState) return;
      if (!notificationEventState) return;

      setTrackingUpdates([
        ...trackingUpdates,
        {
          timestamp: currentTimeStamp,
          state: notificationEventState,
        },
      ]);
    }
  });

  /**
   * Gets the current process execution status info based on the status
   * Uses the ProcessExecutionStatusType to define the various types that can be placed into the chip
   * Current possible values are "IN_PROGRESS" and "COMPLETED"
   */
  const currentProcessExecutionStatus = useMemo(() => {
    const availableProcessExecutionStatusTypes: {
      [key: string]: ProcessExecutionStatusType;
    } = {
      IN_PROGRESS: {
        icon: <HourglassBottom />,
        bgcolor: "default",
        color: "default",
        title: inProgressExecutionsMsg,
        tooltip: (
          <>
            <Box display="flex" justifyContent={"center"} padding={1}>
              <HourglassBottom />
              <Typography paddingLeft={1} fontWeight="bold">
                {executionMsg} In-Progress
              </Typography>
            </Box>
            {allowRequestTracking && (
              <TaskExecutionMiniTracker notification_events={trackingUpdates} />
            )}

            {/* {sortTrackingUpdates(trackingUpdates).map(
              (trackingUpdate, index) =>
                trackingElements[trackingUpdate.state] && (
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={1}
                    key={index}
                  >
                    {trackingElements[trackingUpdate.state]}
                    <HumanReadableTimestamp
                      variant="body2"
                      timestamp={trackingUpdate?.timestamp}
                    />
                  </Box>
                ),
            )} */}
          </>
        ),
      },
      COMPLETED: {
        icon: <CheckCircleOutline sx={{ color: "#ffffff" }} />,
        bgcolor: "complete",
        color: "success",
        title: completedExecutionsMsg,
        tooltip: (
          <>
            <Box display="flex" justifyContent={"center"} padding={1}>
              <CheckCircleOutline />
              <Typography paddingLeft={1} fontWeight="bold">
                {executionMsg} {completedExecutionsMsg}
              </Typography>
            </Box>
          </>
        ),
      },
    };

    if (!status) return undefined;

    return availableProcessExecutionStatusTypes[status];
  }, [
    allowRequestTracking,
    completedExecutionsMsg,
    executionMsg,
    inProgressExecutionsMsg,
    status,
    trackingUpdates,
  ]);

  return (
    <>
      {/* Defines a tooltip to place around the label depending on the state of the process
        TODO: Include tracking information and custom tooltips to do so
    */}
      {currentProcessExecutionStatus && (
        <Tooltip title={currentProcessExecutionStatus.tooltip}>
          <Chip
            icon={currentProcessExecutionStatus.icon}
            component="a"
            onClick={onClick}
            label={currentProcessExecutionStatus.title}
            color={currentProcessExecutionStatus.color}
            sx={{
              bgcolor: (theme) => {
                if (currentProcessExecutionStatus.bgcolor === "default") return;
                if (currentProcessExecutionStatus.bgcolor === "complete")
                  return theme.palette.progress.complete;
              },
              paddingLeft: "4px",
              ...sx,
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
export default ProcessExecutionStatusChip;
