import { useDroppable } from "@dnd-kit/core";
import { AddCircleOutline, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { ProcessAPI } from "../../../../../api";
import PATHS from "../../../../../components/navigation/_paths";
import { useAlert } from "../../../../../lib/alert";
import { ACCOUNT_TYPE } from "../../../../../model/EntityRef";
import { Process, ProcessStatus } from "../../../../../model/Process";
import { ProcessCategory } from "../../../../../model/ProcessCategory";
import { scrollbarStyles } from "../../../../../util/customStyles";
import CreateProcessDialog from "../../../../process/components/CreateProcessDialog";
import { AccountPageContext } from "../../../AccountProfilePage";
import ProcessCard from "./ProcessCard";

const ServicesPanel = ({
  cards,
  categories,
}: {
  cards: Process[];
  categories: Map<string, ProcessCategory>;
}) => {
  const { setNodeRef, isOver } = useDroppable({ id: "services" });
  const [query, setQuery] = useState<string>("");
  const [categorySelected, setCategorySelected] = useState<ProcessCategory>();
  const [processCreateDialogOpen, setProcessCreateDialogOpen] =
    useState<boolean>(false);

  const { organization, account } = useContext(AccountPageContext);
  const { info, handleRejectionWithError } = useAlert();
  const navigate = useNavigate();

  const displayedCards = useMemo(() => {
    return cards.filter((card) => card.name.indexOf(query) !== -1);
  }, [cards, query]);

  const categoryAccordions = (shownCategories: ProcessCategory[]) =>
    shownCategories.map((category) => {
      const childCategories = Array.from(categories.values()).filter(
        (c) => c.parent_id == category.id,
      );
      return (
        <Accordion
          disableGutters={true}
          defaultExpanded={true}
          sx={{
            bgcolor: "transparent",
            boxShadow: "none",
            border: "none",
            mb: 2,
          }}
          key={category.id}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.25em" }}>
              {category.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 0 }}>
            {childCategories.length > 0 && categoryAccordions(childCategories)}
            {displayedCards
              .filter((process) => (process.category_id ?? "") == category.id)
              .map((process) => {
                return (
                  <ProcessCard
                    card={process}
                    currentPanel="services"
                    key={process.id}
                  />
                );
              })}
            <Button
              variant="contained"
              startIcon={<AddCircleOutline />}
              sx={{
                width: "30vw",
                height: "40px",
                my: "0.5vh",
                bgcolor: (theme) => theme.palette.background.paper,
                color: (theme) => theme.palette.text.secondary,
                border: "1px solid darkgray",
                borderRadius: "7px",
                boxShadow: "none",
                textTransform: "none",
                justifyContent: "left",
                "&:hover": {
                  bgcolor: (theme) => theme.palette.grey[100],
                  color: (theme) => theme.palette.text.primary,
                  borderColor: "black",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                setCategorySelected(category);
                setProcessCreateDialogOpen(true);
              }}
            >
              Create new process in this category
            </Button>
          </AccordionDetails>
        </Accordion>
      );
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: (theme) =>
          isOver ? "#D5E0E7" : theme.palette.background.drawer,
        height: "100%",
        maxHeight: "540px",
        overflow: "auto",
        p: 2,
        borderRadius: 3,
        transition: "bgcolor, .1s",
        mr: "10%",
        ml: 2,
      }}
      ref={setNodeRef}
    >
      <Typography variant="h2">{"Services Panel"}</Typography>
      <TextField
        sx={{ width: "100%", mt: 1 }}
        variant="outlined"
        size="small"
        placeholder="Search"
        onChange={(event) => setQuery(event?.target.value)}
      />
      <Box
        sx={{
          overflow: "auto",
          flex: 1,
          mt: 2,
          ...scrollbarStyles,
        }}
      >
        {categoryAccordions(
          Array.from(categories.values()).filter(
            (category) => !category.parent_id,
          ),
        )}
      </Box>
      <CreateProcessDialog
        open={processCreateDialogOpen}
        categoryId={categorySelected?.id}
        showSubjectField={false}
        handleConfirm={function (process): Promise<void> {
          if (!organization) {
            info("You must create an organization before creating a process");
            return Promise.resolve();
          }

          const request: Partial<Process> = {
            ...process,
            status: ProcessStatus.Unpublished,
          };
          return ProcessAPI.create(request as Process, {
            entity_type: ACCOUNT_TYPE,
            entity_id: account?.account_type_id ?? "",
            counterparty_can_execute: true,
          }).then((result) => {
            navigate(PATHS.PROCESS.linkTo(result.id));
          }, handleRejectionWithError("Could not load process page"));
        }}
        handleCancel={function (): void {
          setProcessCreateDialogOpen(false);
        }}
      />
    </Box>
  );
};

export default ServicesPanel;
