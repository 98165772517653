import { Button, Grid, Paper, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "../../messages";

import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";

type AccountProfileProgramBlankProps = {
  accountName: string;
  onButtonClick: () => void;
};

const AccountProfileProgramBlank = ({
  accountName,
  onButtonClick,
}: AccountProfileProgramBlankProps) => {
  const intl = useIntl();
  const { organization, isMember } = useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);
  const programName = intl.formatMessage(messages.account.program.props);

  return (
    <Grid container>
      <Paper
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
          width: "40%",
          minWidth: "250px",
          margin: "auto",
          mt: 10,
          padding: 4,
          textAlign: "center",
        }}
      >
        {isMemberOfOrg ? (
          <>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "1.1em" }}
            >
              Get started by creating a new {programName} for {accountName}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                onButtonClick();
              }}
              sx={{ backgroundColor: "primary.main", marginTop: 2 }}
            >
              Create New Plan
            </Button>
          </>
        ) : (
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", fontSize: "1.1em" }}
          >
            {accountName} does not have a {programName} yet
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default AccountProfileProgramBlank;
