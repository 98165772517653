import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { CardTitle } from "../../../../components/elements";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import { NotificationEventType } from "../../../../model/NotificationEvent";
import { TaskExecutionState } from "../../../task";
import { useProcessExecutionContext } from "../../hooks";

const CannotViewCurrentTaskCard = () => {
  const { isMember } = useGlobalOrganizationContext();
  const {
    taskExecutions,
    pollNotificationEvents,
    notificationEvents,
    process,
  } = useProcessExecutionContext();
  useEffect(() => {
    const intervalId = pollNotificationEvents();
    return () => clearInterval(intervalId);
  }, [pollNotificationEvents]);

  const submissionMessage = useMemo(() => {
    const lastFinishedExecution = [...taskExecutions]
      .filter((ex) => !!ex)
      .reverse()
      .find((execution) => {
        return execution?.state != TaskExecutionState.InProgress;
      });
    return lastFinishedExecution?.task?.data?.submission;
  }, [taskExecutions]);

  let title = submissionMessage?.title;
  const isOrgMember = isMember(process?.org_id);
  if (!title) {
    title = isOrgMember
      ? "Pending Task"
      : "Thank You! Your request has been submitted.";
  }
  let message = submissionMessage?.message;
  if (!message) {
    message = isOrgMember
      ? "We're waiting for someone else to complete this task. Check back later or wait for an update to be sent to your inbox."
      : "We have received your information and will handle your request shortly. Updates will be sent to your inbox.";
  }

  const hasTaskBeenSeen = useMemo(() => {
    return notificationEvents?.some(
      (event) => event.eventType === NotificationEventType.SeenAssigned,
    );
  }, [notificationEvents]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      alignItems={"center"}
      textAlign={"center"}
      sx={{ p: 2 }}
    >
      <CardTitle
        variant="h2"
        component="h1"
        icon={isOrgMember ? "Lock" : "CheckCircle"}
      >
        {title}
      </CardTitle>

      <Typography>{message}</Typography>
      {isOrgMember && (
        <>
          <Divider sx={{ m: 1, width: "100%" }} />
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <Typography sx={{ mr: 1 }}>{"Status: "}</Typography>
            <Typography
              color={hasTaskBeenSeen ? "primary.main" : "warning.main"}
            >
              {hasTaskBeenSeen ? "In Progress" : "Waiting..."}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
export default CannotViewCurrentTaskCard;
