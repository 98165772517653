import { Link } from "@mui/material";
import { FC, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import Processes from "../../../api/processes";
import PATHS from "../../../components/navigation/_paths";
import OrganizationScopedPage from "../../../components/OrganizationScopedPage";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import useFeature from "../../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import OrganizationProcesses from "./OrganizationProcesses";
import ProcessListActionBar from "./ProcessListActionBar";

const ProcessPage: FC<unknown> = () => {
  useDisplayOptions({ title: "Processes" });

  // State to manage currently selected organization for page operations
  const { organization } = useGlobalOrganizationContext();

  const fetchProcessesForOrganization = useCallback(() => {
    if (!organization?.id) {
      return Promise.resolve([]);
    }

    return Processes.byOrganization(organization.id);
  }, [organization?.id]);

  const accountTypeAdministration = useFeature("accountType.administration");

  return (
    <OrganizationScopedPage
      category="processes"
      displayOrganizationActionMenu={false}
    >
      {organization && (
        <OrganizationProcesses
          fetch={fetchProcessesForOrganization}
          currentOrganization={organization}
          PageHeaderProps={{
            actions: <ProcessListActionBar />,
          }}
          extraColumns={
            accountTypeAdministration
              ? [
                  {
                    field: "entity_type_name",
                    headerName: `Account Type`,
                    flex: 1,
                    minWidth: 150,
                    renderCell: (params) =>
                      params.row.entity_type_name ? (
                        <Link
                          underline="hover"
                          key={params.row.id}
                          component={RouterLink}
                          to={PATHS.ACCOUNTS_TYPE_PROCESSES.linkTo(
                            params.row.entity_type_key,
                          )}
                          sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                          {params.row.entity_type_name}
                        </Link>
                      ) : (
                        <></>
                      ),
                  },
                ]
              : []
          }
        />
      )}
    </OrganizationScopedPage>
  );
};

export default ProcessPage;
