import { useMemo } from "react";
import useFeature from "../../../hooks/useFeature";
import { AccountProgram } from "../../../model/AccountProgram";
import { AccountProfileViewer } from "../AccountProfilePage";

export const useAccountProgramTab = (
  viewer: AccountProfileViewer,
  programs?: AccountProgram[],
) => {
  const programsFF = useFeature("program");
  const showProgramsTab = useMemo(() => {
    if (!programsFF) {
      return false;
    }
    switch (viewer) {
      case AccountProfileViewer.User:
        if (programs) {
          return (
            programs.filter((program) => {
              return program.visible;
            }).length > 0
          );
        } else {
          return true;
        }
      case AccountProfileViewer.Admin:
        return true;
    }
  }, [programs, programsFF, viewer]);
  return {
    showProgramsTab,
  };
};
