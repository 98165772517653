import { Box } from "@mui/material";
import { AccountProfileViewer } from "../AccountProfilePage";
import useOrganizationAccountPage from "../hooks/useOrganizationAccountPage";
import AccountFieldsAccordion from "./AccountFieldsAccordion";

type AccountFieldsPageProps = {
  viewer: AccountProfileViewer;
};
const AccountFieldsPage = ({ viewer }: AccountFieldsPageProps) => {
  const accountPageContext = useOrganizationAccountPage(viewer);
  if (!accountPageContext) {
    return <></>;
  }

  const { account } = accountPageContext;

  return (
    <Box sx={{ padding: 4 }}>
      <AccountFieldsAccordion accountDto={account} />
    </Box>
  );
};

export default AccountFieldsPage;
