import { Button, CircularProgress, Grid } from "@mui/material";
import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router";
import { AccountAPI, ProcessAPI, ProcessExecutionAPI } from "../../../../api";
import accountPrograms from "../../../../api/accountPrograms";
import processCategories from "../../../../api/processCategories";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import { Organization } from "../../../../model";
import { AccountDto } from "../../../../model/Account";
import { AccountProgram } from "../../../../model/AccountProgram";
import { ACCOUNT_TYPE } from "../../../../model/EntityRef";
import {
  ExecutionState,
  Process,
  ProcessStatus,
} from "../../../../model/Process";
import { ProcessCategory } from "../../../../model/ProcessCategory";
import { AccountProfileViewer } from "../../AccountProfilePage";
import AccountProfileProgramBlank from "./AccountProgramBlank";
import AccountProgramEditor from "./AccountProgramEditor";
import ProcessBox from "./ProcessBox";

type ProgramCardInfo = {
  id: string;
  name: string;
  description?: string;
  completedTasks: number;
  totalTasks: number;
  visibleToUser: boolean;
  status?: ExecutionState;
  processId: string;
  executionId?: string;
};

export type AccountProgramPageContextType = {
  account: AccountDto;
  organization: Organization;
  accountTypeProcesses: Process[];
  programs: AccountProgram[];
  userType: AccountProfileViewer;
  categories?: ProcessCategory[];
  dispatch: (newState: Partial<AccountProgramPageContextType>) => void;
};

export const AccountPageContext =
  React.createContext<AccountProgramPageContextType>(
    {} as AccountProgramPageContextType,
  );

type AccountProgramPageProps = {
  viewer: AccountProfileViewer;
};

const reducer = (
  state: AccountProgramPageContextType | undefined,
  action: object,
) => {
  return { ...state, ...action } as AccountProgramPageContextType | undefined;
};

const AccountProgramPage: FC<AccountProgramPageProps> = ({ viewer }) => {
  const [programCards, setProgramCards] = useState<ProgramCardInfo[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountDto>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [accountPageContext, dispatch] = useReducer(reducer, undefined);
  const subjectId = useParams().subjectId;
  const { organization, setOrganization, accountListIsMemberOf, isMember } =
    useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);

  const load = useCallback(async () => {
    const account =
      subjectId && ((await AccountAPI.get(subjectId)) as AccountDto);

    if (account && accountListIsMemberOf) {
      if (!organization) {
        const org =
          account.organization ??
          accountListIsMemberOf.find((a) => account.id === a.id)?.organization;
        setOrganization(org);
      } else {
        setAccount(account);
        dispatch({
          account: account,
          organization: organization,
          accountTypeProcesses: await ProcessAPI.byEntity(
            organization.id,
            {
              entity_type: ACCOUNT_TYPE,
              entity_id: account.account_type_id ?? "",
              counterparty_can_execute: isMemberOfOrg ? undefined : true,
            },
            account.is_test ? ProcessStatus.Draft : ProcessStatus.Published,
          ),
          programs: await accountPrograms.loadByAccountId(account.id),
          userType: viewer,
          categories:
            viewer == AccountProfileViewer.Admin && isMemberOfOrg
              ? await processCategories.byOrganization(organization.id)
              : undefined,
          dispatch: dispatch,
        });
      }
    }
  }, [
    accountListIsMemberOf,
    isMemberOfOrg,
    organization,
    setOrganization,
    subjectId,
    viewer,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const loadCardInformation = useCallback(async () => {
    const newProgramCards: ProgramCardInfo[] = [];

    if (!accountPageContext) {
      return;
    }
    for (const program of accountPageContext.programs) {
      if (program.execution_id) {
        const execution = await ProcessExecutionAPI.getDto(
          program.execution_id,
        );
        newProgramCards.push({
          id: program.id,
          name: execution.process_name,
          description: execution.process_description,
          completedTasks: parseInt(execution.completed_tasks ?? "0"),
          totalTasks: parseInt(execution.total_tasks ?? "0"),
          visibleToUser: program.visible,
          status: execution.state,
          processId: execution.process_id,
          executionId: execution.id,
        });
      } else {
        const process = accountPageContext.accountTypeProcesses.find(
          (p) => p.meta === program.process_meta,
        );
        if (process) {
          const tasks = await ProcessAPI.getTasks(process.id);
          newProgramCards.push({
            id: program.id,
            name: process.name,
            description: process.description,
            completedTasks: 0,
            totalTasks: tasks.length,
            visibleToUser: program.visible,
            processId: process.id,
          });
        }
      }
    }

    setProgramCards(newProgramCards);
    setIsLoaded(true);
  }, [accountPageContext]);

  useEffect(() => {
    loadCardInformation();
  }, [loadCardInformation]);

  if (!(accountPageContext && account)) {
    return <></>;
  }
  if (!isEditing) {
    return (
      <AccountPageContext.Provider value={accountPageContext}>
        <Grid style={{ textAlign: "center", height: "100%" }} sx={{ ml: -1 }}>
          {isLoaded ? (
            programCards.length > 0 ? (
              <ul style={{ listStyleType: "none" }}>
                {programCards.map((card) => (
                  <li key={card.id}>
                    <ProcessBox
                      name={card.name}
                      description={card.description}
                      completedTasks={card.completedTasks}
                      totalTasks={card.totalTasks}
                      visibleToUser={card.visibleToUser}
                      userType={accountPageContext.userType}
                      status={card.status}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <AccountProfileProgramBlank
                onButtonClick={() => setIsEditing(true)}
                accountName={account.account_name ?? "this account"}
              />
            )
          ) : (
            <CircularProgress sx={{ mt: 25 }} />
          )}
        </Grid>
        {programCards.length > 0 && (
          <Button
            variant="contained"
            onClick={() => {
              setIsEditing(true);
            }}
            sx={{ backgroundColor: "primary.main", ml: 4 }}
          >
            Edit Programs
          </Button>
        )}
      </AccountPageContext.Provider>
    );
  } else {
    return (
      <AccountPageContext.Provider value={accountPageContext}>
        <Grid style={{ textAlign: "center", height: "100%" }} sx={{ m: 2 }}>
          <AccountProgramEditor />
        </Grid>
        <Button
          variant="contained"
          onClick={() => {
            setIsEditing(false);
          }}
          sx={{ backgroundColor: "primary.main", ml: 4 }}
        >
          View Programs
        </Button>
      </AccountPageContext.Provider>
    );
  }
};

export default AccountProgramPage;
