import { Task, TaskExecution } from "../features/task";
import API from "./base";

const share = (taskExecutionId: string): Promise<TaskExecution> => {
  return new Promise((resolve, reject) => {
    API.post(`/task-executions/${taskExecutionId}/share`).then(
      (result) => resolve(result.data.payload as TaskExecution),
      reject,
    );
  });
};

const unshare = (taskExecutionId: string): Promise<TaskExecution> => {
  return new Promise((resolve, reject) => {
    API.delete(`/task-executions/${taskExecutionId}/share`).then(
      (result) => resolve(result.data.payload as TaskExecution),
      reject,
    );
  });
};

const listSharedByProcessExecutionId = (
  processExecutionId: string,
): Promise<TaskExecution[]> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/task-executions/byProcessExecutionId/${processExecutionId}/share`,
    ).then((result) => {
      resolve(result.data.payload as TaskExecution[]);
    }, reject);
  });
};

const getShared = (taskExecutionId: string): Promise<TaskExecution> => {
  return new Promise((resolve, reject) => {
    API.get(`/task-executions/${taskExecutionId}/share`).then(
      (result) => resolve(result.data.payload as TaskExecution),
      reject,
    );
  });
};

const getSharedTask = (taskExecutionId: string): Promise<Task> => {
  return new Promise((resolve, reject) => {
    API.get(`/task-executions/${taskExecutionId}/share/task`).then(
      (result) => resolve(result.data.payload as Task),
      reject,
    );
  });
};

const remindTaskAccount = (taskExecutionId: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    API.post(`/task-executions/${taskExecutionId}/remind`).then(
      (result) => resolve(result.data.payload as boolean),
      reject,
    );
  });
};

export default {
  share: share,
  unshare: unshare,
  remindTaskAccount: remindTaskAccount,
  listSharedByProcessExecutionId: listSharedByProcessExecutionId,
  getShared: getShared,
  getSharedTask: getSharedTask,
};
