import {
  Business,
  Close,
  SearchOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo } from "react";
import { Account, Organization } from "../../../../model";
import { AccountType } from "../../../../model/AccountType";
import { useAccountExecutionContext } from "../../../task/components/task-types/account/CreateAccountExecutionComponent";
import { getAccountName } from "../../utils";
import { AccountCreateSimpleForm } from "../AccountCreateSimpleForm";
import {
  AccountSelectTaskContext,
  AccountSelectTaskOption,
} from "../AccountSelectWrapper";

const AccountSelectExpanded = ({
  setSelected,
  accountType,
  organization,
  addAccounts,
  findAccount,
  onCreateAccount,
  allAccounts,
  onCreate,
}: {
  setSelected: (option?: AccountSelectTaskOption) => void;
  accountType?: AccountType;
  organization: Organization;
  addAccounts: (newAccounts: Account[]) => void;
  findAccount: (accountChecked: Account) => Account | undefined;
  onCreateAccount?: (account: Account) => void;
  allAccounts?: Account[];
  onCreate?: (account: Account) => Promise<void>;
}) => {
  const { selected } = useContext(AccountSelectTaskContext);
  const automaticallyExecute = true;
  const createAccountContext = useAccountExecutionContext();
  console.log(createAccountContext);
  const { options, disabled, query, setQuery } = useContext(
    AccountSelectTaskContext,
  );

  useEffect(() => {
    createAccountContext?.setActionBarVisible(options.length > 0);
  }, [selected, options.length, createAccountContext]);

  return (
    <Container disableGutters>
      {(selected && <OptionSelectedCard setSelected={setSelected} />) || (
        <TextField
          InputProps={{
            startAdornment: <SearchOutlined sx={{ mr: 1 }} />,
            endAdornment: query && (
              <Tooltip title="Clear Search">
                <IconButton onClick={() => setQuery("")}>
                  <Close />
                </IconButton>
              </Tooltip>
            ),
          }}
          placeholder="Search Directory"
          fullWidth
          onChange={(event) => setQuery(event.target.value)}
          sx={{ mb: "1rem" }}
          value={query ?? ""}
          disabled={disabled}
        />
      )}

      {(options.length > 0 && <OptionsList onSelect={setSelected} />) || (
        <AccountCreateSimpleForm
          accountType={accountType}
          organizationId={organization.id}
          account={{
            account_name: query?.trim(),
            primary_email: query && query.indexOf("@") > 0 ? query?.trim() : "",
          }}
          onClose={() => {}}
          onCreate={(account) => {
            const existingAccount = findAccount(account);
            if (!existingAccount) {
              onCreateAccount && onCreateAccount(account);
              setQuery(getAccountName(account));
              addAccounts([account]);
            }
            setSelected({
              type: "account",
              data: account,
              label: getAccountName(account),
            });
            addAccounts([account]);
            if (automaticallyExecute && onCreate) onCreate(account);
          }}
          setSelected={setSelected}
          query={query}
          setQuery={setQuery}
          allAccounts={allAccounts}
        />
      )}
    </Container>
  );
};

// Displays the selected option as a card or a button to create a new account
const OptionSelectedCard = ({
  setSelected,
}: {
  setSelected: (option?: AccountSelectTaskOption) => void;
}) => {
  const { selected, disabled } = useContext(AccountSelectTaskContext);

  const getOrganizationContent = () => {
    const organization = selected!.data as Organization;
    return (
      <>
        <img
          src={organization.theme?.shortLogoUrl}
          style={{ width: "5rem", marginRight: "1rem" }}
        />
        <Box>
          <Typography variant="inherit">{selected!.label}</Typography>
          {/* commented out since we don't have an organization's address available
          
          <Typography
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.grey[600] }}
          >
            Hancock, MI
          </Typography> */}
        </Box>
      </>
    );
  };

  const getAccountContent = () => {
    const account = selected!.data as Account;
    const hasEmail = account.primary_email && account.primary_email.length > 0;
    return (
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box>
          <Typography variant="inherit">{selected!.label}</Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: (theme) => theme.palette.grey[600],
            }}
          >
            {hasEmail ? account.primary_email : "No email provided"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <IconButton
            onClick={() => setSelected(undefined)}
            size="small"
            disabled={disabled}
            sx={{ aspectRatio: "1/1" }}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const content = selected && (
    <Paper sx={{ border: "1px solid black", height: "6rem", p: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        {selected.type === "organization"
          ? getOrganizationContent()
          : getAccountContent()}
      </Box>
    </Paper>
  );

  return (
    <Container disableGutters sx={{ mb: "1rem", height: "6rem" }}>
      {content}
    </Container>
  );
};

const OptionsList = ({
  onSelect,
}: {
  onSelect: (option?: AccountSelectTaskOption) => void;
}) => {
  const { selected, options, disabled } = useContext(AccountSelectTaskContext);

  const getOptionIcon = (option: AccountSelectTaskOption) => {
    if (option.type === "organization") {
      return (
        <Tooltip title="Organization">
          <Business />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Account">
          <SupervisorAccountOutlined />
        </Tooltip>
      );
    }
  };
  const content = useMemo(() => {
    return options.map((option, index) => {
      const lastElement = index === options.length - 1;

      if (option.data.id != selected?.data.id) {
        return (
          <ListItem
            divider={!lastElement}
            disableGutters
            disablePadding
            key={option.data.id}
          >
            <ListItemButton
              onClick={() => onSelect(option)}
              disabled={disabled}
              key={option.data.id}
            >
              <ListItemIcon>{getOptionIcon(option)}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </ListItemButton>
          </ListItem>
        );
      } else {
        return (
          <ListItem
            sx={{
              bgcolor: (theme) => theme.palette.background.paper,
              mt: "-1px",
              borderTop: "1px solid black",
              borderBottom: !lastElement ? "1px solid black" : 0,
            }}
            secondaryAction={
              <IconButton
                onClick={() => onSelect(undefined)}
                size="small"
                disabled={disabled}
              >
                <Close />
              </IconButton>
            }
            key={selected.data.id}
          >
            <ListItemIcon>{getOptionIcon(selected)}</ListItemIcon>
            <ListItemText>{selected.label}</ListItemText>
          </ListItem>
        );
      }
    });
  }, [disabled, onSelect, options, selected]);

  return (
    <List
      sx={{
        bgcolor: "background.menu",
        border: "1px solid black",
        borderRadius: ".25rem",
        py: 0,
        maxHeight: "40vh",
        overflowY: "scroll",
      }}
    >
      {content}
    </List>
  );
};

export default AccountSelectExpanded;
