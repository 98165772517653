export enum BillingState {
  VALID = "VALID",
  LAPSED = "LAPSED",
  INVALID = "INVALID",
}

export type StripeOrganizationSubscription = {
  id: string;
  start_date: Date | null;
  end_date: Date | null;
  status: StripeSubscriptionStatus;
  amount: number;
  products: Array<StripeProductType>;
  description: string;
};

export enum StripeSubscriptionStatus {
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  TRIALING = "TRIALING",
  ACTIVE = "ACTIVE",
  PAST_DUE = "PAST_DUE",
  CANCELED = "CANCELED",
  UNPAID = "UNPAID",
  PAUSED = "PAUSED",
}

export type StripeCustomerStatus = {
  id: string;
  balance: number; // positive (in debt) or negative (credit)
  name: string;
  deliquent: boolean;
  deleted: boolean;
  email: string;
  phone: string;
  subscriptions?: Array<StripeOrganizationSubscription>;
};

export enum StripeProductType {
  Connect = "Connect",
  Work = "Work",
  CRM = "CRM",
  Unknown = "Unknown",
}

export type StripeMeterData = {
  start_date: Date;
  end_date: Date;
  amount: number;
  checked: Date;
  // allow price to be undefined if we cannot find the price
  price?: number;
  show_summary_warning: boolean;
};

export default class OrganizationBillingState {
  public organization_id: string = "";
  public billing_state: BillingState = BillingState.VALID;
  public lapsed_sense?: Date | null;
  public last_checked?: Date | null;
  public stripe_id?: string;
  public stripe_subscription?: StripeOrganizationSubscription | null;
  public product_flag: StripeProductType | null = null;
  public cached?: boolean;
}
