import { Navigate, Route, Routes } from "react-router";
import { PageContainer } from "../components/layout";
import { AccountProfilePage } from "../features/accounts";
import AccountDetailsPage from "../features/accounts/AccountDetailsPage";
import AccountPage from "../features/accounts/AccountPage";
import {
  AccountProfileSubpage,
  AccountProfileViewer,
} from "../features/accounts/AccountProfilePage";
import AccountFieldsPage from "../features/accounts/components/AccountFieldsPage";
import OrganizationAccountFlights from "../features/accounts/components/OrganizationAccountFlights";
import AccountProgramPage from "../features/accounts/components/program/AccountProgramPage";
import { useAccountProgramTab } from "../features/accounts/hooks/useAccountProgramTab";
import NotFound from "../features/app/NotFound";
import useFeature from "../hooks/useFeature";

type AccountPageRoutesProps = {
  viewer: AccountProfileViewer;
};

export const AccountPageRoutes = ({ viewer }: AccountPageRoutesProps) => {
  const newAccountPage = useFeature("newAccountPage");
  const { showProgramsTab } = useAccountProgramTab(viewer);

  if (newAccountPage) {
    return (
      <Routes>
        <Route
          path=":subjectId"
          element={
            <PageContainer variant="full" GridProps={{ rowGap: 0 }}>
              <AccountPage viewer={viewer} />
            </PageContainer>
          }
        >
          <Route
            path="flights"
            element={<OrganizationAccountFlights viewer={viewer} />}
          />
          <Route
            path="profile"
            element={<AccountDetailsPage viewer={viewer} />}
          />
          {showProgramsTab && (
            <Route
              path="programs"
              element={<AccountProgramPage viewer={viewer} />}
            />
          )}
          <Route
            path="fields"
            element={<AccountFieldsPage viewer={viewer} />}
          />
          <Route path="" element={<Navigate to={"flights"} replace={true} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path=":subjectId">
        <Route
          path="overview"
          element={
            <AccountProfilePage
              view={AccountProfileSubpage.Overview}
              viewer={viewer}
            />
          }
        />
        <Route
          path="program"
          element={
            <AccountProfilePage
              view={AccountProfileSubpage.ProgramViewer}
              viewer={viewer}
            />
          }
        />
        {viewer === AccountProfileViewer.Admin && (
          <Route
            path="program/editor"
            element={
              <AccountProfilePage
                view={AccountProfileSubpage.ProgramEditor}
                viewer={viewer}
              />
            }
          />
        )}
        <Route path="" element={<Navigate to={"overview"} replace={true} />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
