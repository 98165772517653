export enum NotificationEventType {
  Bounce = "Bounce",
  Complaint = "Complaint",
  Delivery = "Delivery",
  Send = "Send",
  Reject = "Reject",
  Open = "Open",
  Click = "Click",
  RenderingFailure = "Rendering Failure",
  DeliveryDelay = "DeliveryDelay",
  Subscription = "Subscription",
  SeenGeneral = "SeenGeneral",
  SeenAssigned = "SeenAssigned",
  Progress = "Progress",
  Downloaded = "Downloaded",
  Completed = "Completed",
  NotificationClick = "NotificationClick",
}

export type ProcessExecutionTracking = {
  timestamp: Date;
  state: NotificationEventType;
};
