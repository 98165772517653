import { NoAccounts } from "@mui/icons-material";
import { Button, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { AccountAPI, InvitationAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useGlobalUserContext } from "../../../hooks/useGlobalUserContext";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import { Account } from "../../../model";
import { AccountType } from "../../../model/AccountType";
import { InvitationType } from "../../../model/Invitation";
import { getInvitationDisplayData } from "../../invitations/InvitationDisplay";
import { AccountSelectTaskOption } from "./AccountSelectWrapper";

interface AccountDirectoryProps {
  organizationId: string;
  accountType?: AccountType;
  account?: Partial<Account>;
  onClose?: () => void;
  onCreate?: (account: Account) => void;
  onConfirm?: () => void;
  query?: string;
  allAccounts?: Account[];
  setSelected?: (option?: AccountSelectTaskOption) => void;
  setQuery?: (newQuery: string) => void;
}

export const AccountCreateSimpleForm: FC<AccountDirectoryProps> = ({
  accountType,
  account,
  ...props
}) => {
  const sendInvitation = false;
  const validateBeforePopulation = true; // Preference, if set to true, the email field will only populate automatically if it matches email regex
  const { handleRejectionWithError } = useAlert();
  const { user } = useGlobalUserContext();
  const { organization } = useGlobalOrganizationContext();
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    account?.primary_email,
  );
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isValidEmail(props.query || "") || !validateBeforePopulation) {
      setEmailAddress(props.query);
    } else {
      setEmailAddress("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query]);

  const isValidEmail = (email: string) => {
    const regex: RegExp = /^[\w-+\\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    const isValid = regex.test(email) as boolean;

    if (!isValid && (emailAddress || emailAddress != "")) {
      setErrorText("Please enter a valid email address.");
    } else if (validateBeforePopulation) {
      setErrorText("");
    }

    return isValid;
  };

  const isCurrentEmailValid = useMemo(() => {
    return isValidEmail(emailAddress || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress]);

  const matchingEmailAddress = useMemo(() => {
    if (isCurrentEmailValid && emailAddress != "") {
      const result = props.allAccounts?.find(
        (acc) => acc.primary_email == emailAddress,
      );
      return result;
    } else {
      return undefined;
    }
  }, [emailAddress, isCurrentEmailValid, props.allAccounts]);

  const createAccount = useCallback(async () => {
    if (!accountType) {
      const errorText = "Account type must be specified";
      setErrorText(errorText);
      throw new Error(errorText);
    }

    let account: Account;
    try {
      account = await AccountAPI.create({
        id: "",
        org_id: props.organizationId,
        account_type_id: accountType?.id ?? uuid(),
        account_type_key: accountType?.key,
        account_type: accountType.name,
        account_name: emailAddress, // TEMPORARY
        primary_email: emailAddress,
        is_test: false,
      });
    } catch (e) {
      handleRejectionWithError("Failed to create account");
      throw e;
    }

    try {
      if (!emailAddress || !organization || !sendInvitation) {
        return;
      }

      await InvitationAPI.create({
        id: "",
        target_user_id: "",
        creator_user_id: currentUserId(),
        organization_id: props.organizationId,
        target_user_email: emailAddress,
        type: InvitationType.Account,
        account_id: account.id,
        display: getInvitationDisplayData(user, organization),
      });
      return;
    } catch (e) {
      handleRejectionWithError(`Failed to invite user ${emailAddress}!"`);
      throw e;
    } finally {
      setEmailAddress("");
      setErrorText(undefined);
      props.onClose?.();
      props.onCreate?.(account);
    }
  }, [
    props,
    accountType,
    emailAddress,
    handleRejectionWithError,
    organization,
    sendInvitation,
    user,
  ]);

  return (
    <Box
      sx={{
        textAlign: "center",
        mx: 10,
        my: 2,
      }}
    >
      <Box sx={{ transition: "all 0.2s ease-in-out" }}>
        {/* Account Not Found Display */}
        {!(matchingEmailAddress && emailAddress) && (
          <Box sx={{ my: 2 }}>
            <NoAccounts sx={{ fontSize: "100px" }} color="disabled" />
            <Divider sx={{ my: 2 }} />

            <Typography variant="h5" color="GrayText">
              {props.allAccounts?.length == 0
                ? `Oops! No ${accountType?.name.toLowerCase() || "account"}(s) found in your directory.`
                : `Sorry, we couldn't find this ${accountType?.name.toLowerCase() || "account"} in Runway.`}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {props.allAccounts?.length == 0
                ? `You can add one now and send it directly to their inbox below.`
                : `If you know their email, enter it below to send this request directly to their inbox.`}
            </Typography>
          </Box>
        )}

        {/* If user exists */}
        {matchingEmailAddress && emailAddress && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px dashed lightgrey",
              borderRadius: ".25rem",
              p: "1rem",
              my: 2,
            }}
          >
            <Typography color="GrayText" sx={{ mb: 2 }}>
              {`An ${accountType && accountType?.name + " "}account already exists with the email ${emailAddress}.`}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                props.setSelected &&
                  props.setSelected({
                    type: "account",
                    data: matchingEmailAddress,
                    label:
                      matchingEmailAddress.account_name ||
                      matchingEmailAddress.primary_email ||
                      emailAddress ||
                      "",
                  });
                props.setQuery && props.setQuery(emailAddress);
              }}
            >
              Select Account
            </Button>
          </Box>
        )}
      </Box>

      {/* Text Field Input */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          mx: 5,
        }}
      >
        <TextField
          margin="dense"
          id="email"
          label="Email Address"
          type="text"
          color={
            isCurrentEmailValid ||
            !emailAddress ||
            emailAddress == "" ||
            matchingEmailAddress != undefined
              ? "primary"
              : "error"
          }
          sx={{ minWidth: "200px", flexGrow: 1 }}
          variant="standard"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value as string);
          }}
          size="small"
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "auto",
            flex: 1,
            minWidth: "170px",
            alignSelf: "center",
            justifyContent: "center",
            mx: 2,
          }}
        >
          <Button
            variant="contained"
            size="small"
            disabled={!isCurrentEmailValid || matchingEmailAddress != undefined}
            color={isCurrentEmailValid ? "primary" : "inherit"}
            onClick={() => {
              createAccount();
              props.onConfirm && props.onConfirm();
            }}
            sx={{ my: 1 }}
          >
            {"Add " + (accountType?.name || "Account")}
          </Button>
        </Box>
      </Box>

      <Typography color="error">{errorText}</Typography>
    </Box>
  );
};
