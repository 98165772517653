import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { HumanReadableTimestamp } from "../../components";
import PdfViewer from "../../components/core/PdfViewer/PdfViewer";
import ReactQuillViewer from "../../components/core/ReactQuill/ReactQuillViewer";
import { UploadFileComponent } from "../../components/core/UploadFile";
import { UserIcon } from "../../components/elements";
import { useGlobalUserContext } from "../../hooks/useGlobalUserContext";
import { User } from "../../model";
import chatToHtml from "./chatToHtml";
import DownloadableAttachment from "./DownloadableAttachment";
import { EmailChatMessage } from "./model";

export type MessageProps = {
  message: EmailChatMessage;
  messageOnly?: boolean;
};

const Message = memo(function Message({
  message,
  messageOnly = false,
}: MessageProps) {
  // TODO - load users ahead of time
  const { user } = useGlobalUserContext();
  const msg = useMemo(() => chatToHtml(message), [message]);

  const sender: Partial<User> = useMemo(() => {
    // TODO - get full user object for other users
    return message.user_id === user.id ? user : { email: message.user_email };
  }, [message, user]);

  return (
    <Box display="flex" flexDirection="row">
      {!messageOnly && <UserIcon user={sender as User} size="medium" />}
      <Box
        flex={1}
        pl={0.5}
        display="flex"
        flexDirection="column"
        sx={{
          "& .ql-editor": {
            pt: 0,
          },
        }}
      >
        {!messageOnly && (
          <span>
            <Typography
              variant="body1"
              fontWeight="bold"
              display="inline"
              component="span"
              sx={{ mr: 1 }}
            >
              {sender.first_name
                ? `${sender.first_name} ${sender.last_name}`
                : sender.email}
            </Typography>
            {message.created_at && (
              <HumanReadableTimestamp
                timestamp={message.created_at}
                variant="subtitle2"
                format="time"
              />
            )}
          </span>
        )}
        <Box sx={messageOnly ? { ml: 6 } : {}}>
          <ReactQuillViewer
            key={message.id}
            value={msg ?? ""}
            variant="regular"
            collapseHeight={-1}
          />
          {message.data?.attachments && message.data.attachments.length > 0 && (
            <Box sx={{ mb: 1 }}>
              <UploadFileComponent
                renderer={(file) => {
                  return file.contentType?.includes("pdf") ? (
                    <PdfViewer
                      source={`${
                        process.env.REACT_APP_REST_API
                      }/files/${encodeURIComponent(file.key)}`}
                      scale={0.25}
                      fallback={<DownloadableAttachment file={file} />}
                    />
                  ) : (
                    <DownloadableAttachment file={file} />
                  );
                }}
                disabled={true}
                files={message.data?.attachments}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
});
export default Message;
