import WebSocketMessage, {
  WebSocketTinyNotificationData,
} from "../../model/WebSocketMessage";
import eventEmitter from "../event";

/**
 * Handles a tiny notification message that is received from the server
 * The tiny notification requires the React frontend
 * Calls the react event handler with the message
 */
export const handleTinyNotification = async (message: WebSocketMessage) => {
  let tinyNotificationData: WebSocketTinyNotificationData;
  try {
    tinyNotificationData = message.data as WebSocketTinyNotificationData;
  } catch (error) {
    console.error("handleTinyNotification", error);
    return;
  }

  eventEmitter.emit("websocket:tinyNotification", tinyNotificationData);
};
