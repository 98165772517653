import { AxiosRequestConfig } from "axios";
import { Filter, PagedResponse, Pagination, ResponseEnvelope } from ".";
import { TaskExecution } from "../features/task";
import { ExecutionState } from "../model/Process";
import {
  ProcessExecution,
  TaskNotificationEvent,
} from "../model/ProcessExecution";
import { AssignedRoleUserDto } from "../types";
import API from "./base";

const start = (
  processId: string,
  configuration: Partial<ProcessExecution>,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.post(`/process-executions/${processId}/start`, configuration).then(
      (result) => resolve(result.data.payload as ProcessExecution),
      reject,
    );
  });
};

const submitTask = (
  processExecutionId: string,
  taskId: string,
  taskExecution: TaskExecution,
): Promise<TaskExecution> => {
  return API.post(
    `/process-executions/${processExecutionId}/tasks/${taskId}/submit`,
    taskExecution,
  ).then((result) => {
    return result.data.payload as TaskExecution;
  });
};

const completeTask = (
  processExecutionId: string,
  taskId: string,
): Promise<TaskExecution> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/process-executions/${processExecutionId}/tasks/${taskId}/complete`,
    ).then((result) => {
      resolve(result.data.payload as TaskExecution);
    }, reject);
  });
};

const getTaskTrackingInfo = (
  processExecutionId: string,
  taskId: string,
): Promise<TaskNotificationEvent[]> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/process-executions/${processExecutionId}/task/${taskId}/tracking`,
    ).then((result) => {
      resolve(result.data.payload as TaskNotificationEvent[]);
    }, reject);
  });
};

const complete = (processExecutionId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(`/process-executions/${processExecutionId}/complete`).then(
      (result) => {
        resolve(result.data.payload);
      },
      reject,
    );
  });
};

const getTaskExecutions = (
  processExecutionId: string,
): Promise<Array<TaskExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/${processExecutionId}/taskExecutions`).then(
      (result) => {
        resolve(result.data.payload as Array<TaskExecution>);
      },
      reject,
    );
  });
};

const getTaskExecution = (
  processExecutionId: string,
  taskId: string,
): Promise<TaskExecution> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/${processExecutionId}/tasks/${taskId}`).then(
      (result) => {
        resolve(result.data.payload as TaskExecution);
      },
      reject,
    );
  });
};

const bySubjectId = (subjectId: string): Promise<Array<ProcessExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/bySubjectId/${subjectId}`).then((result) => {
      resolve(result.data.payload as Array<ProcessExecution>);
    }, reject);
  });
};

const byUserId = (
  userId: string,
  pagination?: Pagination,
  hide?: string[],
): Promise<ResponseEnvelope<Array<ProcessExecution>>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/byUserId/${userId}`, {
      params: {
        hide,
        ...pagination,
      },
    }).then((result) => {
      resolve(result.data as ResponseEnvelope<Array<ProcessExecution>>);
    }, reject);
  });
};

const byUserIdAndState = (
  userId: string,
  state: ExecutionState,
  organizationId?: string,
  pagination?: Pagination,
  hide?: string[],
): Promise<Array<ProcessExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/byUserId/${userId}`, {
      params: {
        hide,
        organizationId,
        ...pagination,
        state,
      },
    }).then((result) => {
      resolve(result.data.payload as Array<ProcessExecution>);
    }, reject);
  });
};

const byOrganizationId = (
  organizationId: string,
  filters?: Filter[],
  pagination?: Pagination,
  config?: AxiosRequestConfig,
): Promise<PagedResponse<ProcessExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/byOrganizationId/${organizationId}`, {
      params: {
        filters,
        ...pagination,
      },
      ...config,
    }).then((result) => {
      if (config?.format === "xlsx") {
        resolve(result.data as PagedResponse<ProcessExecution>);
      } else {
        resolve(result.data.payload as PagedResponse<ProcessExecution>);
      }
    }, reject);
  });
};

const byOrganizationThreads = (
  organizationId: string,
  filters?: Filter[],
  pagination?: Pagination,
  config?: AxiosRequestConfig,
): Promise<PagedResponse<ProcessExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/byOrganizationId/${organizationId}/byThread`, {
      params: {
        filters,
        ...pagination,
      },
      ...config,
    }).then((result) => {
      if (config?.format === "xlsx") {
        resolve(result.data as PagedResponse<ProcessExecution>);
      } else {
        resolve(result.data.payload as PagedResponse<ProcessExecution>);
      }
    }, reject);
  });
};

const getDto = (processExecutionId: string): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/${processExecutionId}/dto`).then((result) => {
      resolve(result.data.payload as ProcessExecution);
    }, reject);
  });
};

const getStepExecutions = (
  processExecutionId: string,
): Promise<Array<ProcessExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/${processExecutionId}/steps`).then(
      (result) => {
        resolve(result.data.payload as Array<ProcessExecution>);
      },
      reject,
    );
  });
};

const getAssignedRoleUsers = (
  processExecutionId: string,
): Promise<Array<AssignedRoleUserDto>> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/${processExecutionId}/assignedRoleUsers`).then(
      (result) => {
        resolve(result.data.payload as Array<AssignedRoleUserDto>);
      },
      reject,
    );
  });
};

const deleteProcessExecution = (processExecutionId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete(`/process-executions/${processExecutionId}`).then(() => {
      resolve();
    }, reject);
  });
};

const retryErrorTask = (
  processExecutionId: string,
  taskId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/process-executions/${processExecutionId}/tasks/${taskId}/retryErrorTask`,
    ).then(() => {
      resolve();
    }, reject);
  });
};

const update = (
  processExecutionId: string,
  processExecution: Partial<ProcessExecution>,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.put(`/process-executions/${processExecutionId}`, processExecution).then(
      (result) => {
        resolve(result.data.payload as ProcessExecution);
      },
      reject,
    );
  });
};

const assign = (
  processExecutionId: string,
  taskId: string,
  userId: string,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/process-executions/${processExecutionId}/tasks/${taskId}/assign/${userId}`,
    ).then((result) => {
      resolve(result.data.payload as ProcessExecution);
    }, reject);
  });
};

const unassign = (
  processExecutionId: string,
  taskId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/process-executions/${processExecutionId}/tasks/${taskId}/unassign`,
    ).then(() => {
      resolve();
    }, reject);
  });
};

const assignProcessExecution = (
  processExecutionId: string,
  userId: string,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.post(`/process-executions/${processExecutionId}/assign/${userId}`).then(
      (result) => {
        resolve(result.data.payload as ProcessExecution);
      },
      reject,
    );
  });
};

const getUnreadCount = (organizationId: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    API.get(`/process-executions/unread/${organizationId}`).then((result) => {
      resolve(result.data.payload as number);
    }, reject);
  });
};

const getCurrentTaskNotificationEvents = (
  processExecutionId: string,
): Promise<Array<TaskNotificationEvent>> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/process-executions/${processExecutionId}/notificationEvents`,
    ).then((result) => {
      resolve(result.data.payload);
    }, reject);
  });
};

export default {
  byOrganizationThreads,
  start: start,
  submitTask: submitTask,
  completeTask: completeTask,
  complete: complete,
  getTaskExecutions: getTaskExecutions,
  getTaskExecution: getTaskExecution,
  byOrganizationId: byOrganizationId,
  bySubjectId: bySubjectId,
  byUserId: byUserId,
  byUserIdAndState: byUserIdAndState,
  getDto: getDto,
  update: update,
  getStepExecutions: getStepExecutions,
  getAssignedRoleUsers: getAssignedRoleUsers,
  deleteProcessExecution: deleteProcessExecution,
  retryErrorTask: retryErrorTask,
  assign: assign,
  unassign: unassign,
  assignProcessExecution,
  getUnreadCount,
  getCurrentTaskNotificationEvents,
  getTaskTrackingInfo,
};
