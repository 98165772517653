import { CheckCircle } from "@mui/icons-material";
import { Grid } from "@mui/material";
import pluralize from "pluralize";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import Processes from "../../api/processes";
import { PageContainer } from "../../components/layout";
import PATHS from "../../components/navigation/_paths";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { ACCOUNT_TYPE } from "../../model/EntityRef";
import { ProcessStatus } from "../../model/Process";
import OrganizationProcesses from "../process/components/OrganizationProcesses";
import ProcessListActionBar from "../process/components/ProcessListActionBar";
import messages from "../process/messages";
import useAccountType from "./hooks/useAccountType";

const AccountTypeProcessListPage = () => {
  const { organization } = useGlobalOrganizationContext();
  const accountType = useAccountType();
  const intl = useIntl();
  const processes = intl.formatMessage(messages.processes.props);
  const title = `${accountType?.name} ${processes}`;
  useDisplayOptions({ title });

  const entityId = accountType?.id;
  const fetchProcessesByAccountType = useCallback(() => {
    if (!entityId || !organization?.id) {
      return Promise.resolve([]);
    }
    return Processes.byEntity(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organization!.id,
      {
        entity_type: ACCOUNT_TYPE,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        entity_id: entityId,
      },
      ProcessStatus.Draft,
    );
  }, [entityId, organization]);
  if (!accountType?.id || !organization?.id) {
    return <></>;
  }
  return (
    <PageContainer variant="full">
      <Grid item xs={12}>
        {organization && (
          <OrganizationProcesses
            currentOrganization={organization}
            fetch={fetchProcessesByAccountType}
            PageHeaderProps={{
              actions: <ProcessListActionBar />,
              links: [
                PATHS.ACCOUNTS,
                {
                  title: pluralize.plural(accountType.name ?? ""),
                  link: PATHS.ACCOUNTS_BY_TYPE.linkTo(accountType.key),
                },
              ],
            }}
            title={`${accountType?.name} ${intl.formatMessage(
              messages.processes.props,
            )}`}
            extraColumns={[
              {
                field: "counterparty_can_execute",
                headerName: `${accountType?.name} Can Start`,
                minWidth: 150,
                flex: 1,
                align: "center",
                renderCell: (params) =>
                  params.row.counterparty_can_execute ? (
                    <CheckCircle sx={{ color: "text.secondary" }} />
                  ) : (
                    <></>
                  ),
              },
            ]}
          />
        )}
      </Grid>
    </PageContainer>
  );
};
export default AccountTypeProcessListPage;
