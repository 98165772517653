import { Box, Button, Popover } from "@mui/material";
import { useMemo } from "react";
import useFeature from "../../../../hooks/useFeature";
import { TaskType, TaskTypeOption, TaskTypes } from "../../types";

const TaskTypeMenu = ({
  open,
  onClose,
  onSelectTaskType,
  anchorEl,
}: {
  open: boolean;
  onClose: () => void;
  onSelectTaskType: (taskType: TaskTypeOption) => void;
  anchorEl: Element | null | undefined;
}) => {
  const canViewRoles = useFeature("roles.view");
  const canManageAccountTypes = useFeature("accountType.administration");

  const taskTypesToShow = useMemo(() => {
    const roleAssociatedTaskTypes = [
      TaskType.Assignment,
      TaskType.UserAssignment,
    ];
    const accountTypeAssociatedTaskTypes = [
      TaskType.Invitation,
      TaskType.CreateAccount,
    ];

    return TaskTypes.filter((taskType) => {
      if (taskType.disabled) return false;
      if (!canViewRoles && roleAssociatedTaskTypes.includes(taskType.type))
        return false;
      if (
        !canManageAccountTypes &&
        accountTypeAssociatedTaskTypes.includes(taskType.type)
      )
        return false;
      return true;
    });
  }, [canManageAccountTypes, canViewRoles]);

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginRight: "16px",
          width: "400px",
          maxWidth: "400px",
          flexWrap: "wrap",
        }}
      >
        {taskTypesToShow.map((taskType) => {
          return (
            <Box key={taskType.type}>
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "16px",
                  marginTop: "16px",
                  marginBottom: "16px",
                  maxWidth: "100px",
                  width: "100px",
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
                size="large"
                onClick={() => {
                  onClose();
                  onSelectTaskType(taskType);
                }}
              >
                <taskType.icon fontSize="large" />
                <Box sx={{ flex: "1", fontSize: "10px" }}>{taskType.title}</Box>
              </Button>
            </Box>
          );
        })}
      </Box>
    </Popover>
  );
};
export default TaskTypeMenu;
