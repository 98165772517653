import {
  Close,
  ExpandLess,
  ExpandMore,
  Lock,
  LockOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProcessExecutionAPI } from "../../../../api";
import ReactQuillViewer from "../../../../components/core/ReactQuill/ReactQuillViewer";
import useFeature from "../../../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import {
  useGlobalRoleContext,
  userCanCompleteTask,
} from "../../../../hooks/useGlobalRoleContext";
import { useGlobalUserContext } from "../../../../hooks/useGlobalUserContext";
import { useAlert } from "../../../../lib/alert";
import { currentUserId } from "../../../../lib/auth";
import {
  createWebSocketMessage,
  WebSocketGlobalState,
} from "../../../../lib/websocket/websocket";
import { WebSocketMessageType } from "../../../../model/WebSocketMessage";
import {
  TaskBody,
  TaskExecution,
  TaskExecutionRenderProps,
} from "../../../task";
import RetryTaskButton from "../../../task/components/execution/RetryTaskButton";
import { TaskExecutionTracker } from "../../../task/components/execution/TaskExecutionTracker";
import { useTaskExecutionEditingState } from "../../../task/hooks/useTaskExecutionEditingState";
import { useProcessExecutionContext } from "../../hooks";
import { ProcessExecutionViewState } from "../../hooks/useProcessExecution";
import CannotViewCurrentTaskCard from "./CannotViewCurrentTaskCard";
import TaskExecutionStepper from "./TaskExecutionStepper";
import TaskOptionsButton from "./TaskOptionsButton";
const TaskExecutionCard = ({
  taskExecution,
  retryErrorTask,
}: {
  taskExecution: TaskExecution;
  retryErrorTask?: () => void;
}) => {
  const {
    processExecution,
    executeTask,
    isLocked: isTaskExecutionLocked,
    setSelectedTaskExecution,
    currentTaskExecution,
    process,
  } = useProcessExecutionContext();
  const { isMember } = useGlobalOrganizationContext();
  const isOrgMember = isMember(process?.org_id);
  useTaskExecutionEditingState(taskExecution, taskExecution?.task);

  const canTaskBeAssigned = useFeature("process.assigned");
  const { editable: isTaskEditable } = useTaskExecutionEditingState(
    taskExecution,
    taskExecution?.task,
  );
  const [isLocked, setIsLocked] = useState<boolean>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { user } = useGlobalUserContext();
  const { roles } = useGlobalRoleContext();

  const data = taskExecution.data;

  const roleToCompleteTask = useMemo(() => {
    return taskExecution.task?.role;
  }, [taskExecution]);

  const [dataExpanded, setDataExpanded] = useState<boolean>(false);

  const canCompleteTask = useMemo(() => {
    if (!roleToCompleteTask) return false;

    const canComplete = userCanCompleteTask(
      taskExecution,
      roleToCompleteTask,
      user,
      roles,
    );

    return canComplete;
  }, [taskExecution, roleToCompleteTask, user, roles]);

  useEffect(() => {
    isTaskExecutionLocked(taskExecution).then((locked) => {
      setIsLocked(locked);
      setShowAlert(locked);
    });
  }, [isTaskExecutionLocked, taskExecution]);

  const allowRequestTracking = useFeature("process.tracker");
  const useRequestTracking =
    (data?.requestTracking && allowRequestTracking) || false;

  return (
    <TaskBody
      variant="execution"
      view="expanded"
      isShortTaskForm={true}
      task={taskExecution.task!}
      taskExecution={taskExecution}
      processExecution={processExecution!}
      onExecute={executeTask} //hasConfirmation ? confirmExecution : onExecute
      isDisabled={isLocked || !isTaskEditable}
    >
      {({ actions, title, content }: TaskExecutionRenderProps) => {
        return (
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            overflow={"hidden"}
            position={"relative"}
            py={2}
          >
            <Box
              sx={{
                display: showAlert ? undefined : "none",
                backgroundColor: "rgba(0,0,0,0.5)",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <Alert
                icon={<LockOutlined />}
                severity={"warning"}
                sx={{ m: 1 }}
                action={
                  <>
                    <Button
                      variant={"contained"}
                      color={"warning"}
                      size={"small"}
                      sx={{ mb: 0.5, mr: 1 }}
                      onClick={() =>
                        setSelectedTaskExecution(currentTaskExecution)
                      }
                    >
                      {"Return to Current Task"}
                    </Button>
                    <IconButton
                      size={"small"}
                      onClick={() => setShowAlert(false)}
                    >
                      <Close fontSize={"small"} />
                    </IconButton>
                  </>
                }
              >
                {"This task is not yet available."}
              </Alert>
            </Box>

            <Box flex={0} display="flex" px={3} alignItems={"start"}>
              <Box flex={1} display={"flex"} alignItems={"center"}>
                {(isLocked || !isTaskEditable) && (
                  <Lock
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                      mr: 1,
                    }}
                    fontSize="small"
                  />
                )}
                <Typography variant="h4" component="h2" sx={{ flex: 1 }}>
                  {title ?? taskExecution?.task?.name}
                </Typography>
                {canCompleteTask && useRequestTracking && (
                  <Typography variant="caption">
                    {"This task's progress is being tracked"}
                  </Typography>
                )}
              </Box>
              {isOrgMember && canTaskBeAssigned && (
                <TaskOptionsButton
                  taskExecution={taskExecution}
                  canBeAssigned={canTaskBeAssigned}
                />
              )}
            </Box>
            <Box flex={1} px={3} py={2} overflow={"auto"}>
              {/* Show the content automatically if there is not request tracking */}
              {(canCompleteTask || !useRequestTracking) && content}

              {!canCompleteTask && useRequestTracking && (
                <>
                  {content && (
                    <>
                      <Box display={"flex"} alignItems={"center"} padding={1}>
                        <Typography
                          variant="h6"
                          component="h3"
                          fontWeight={"bold"}
                          sx={{ flex: 1 }}
                        >
                          {"Task Data"}
                        </Typography>
                        <IconButton
                          size={"small"}
                          onClick={() => setDataExpanded(!dataExpanded)}
                        >
                          {dataExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </Box>
                      <Collapse in={dataExpanded}>
                        <Box padding={1} paddingBottom={2}>
                          {content}
                        </Box>
                      </Collapse>
                      <Divider
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                        }}
                      />
                    </>
                  )}

                  <TaskExecutionTracker
                    processExecution={processExecution}
                    taskId={taskExecution.task_id}
                  />
                </>
              )}

              {taskExecution.task?.description && (
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: "#E2EFF3",
                    color: "#343434",
                    padding: "5px",
                    marginTop: "10px",
                    fontSize: "13px",
                    px: 2,
                  }}
                >
                  <ReactQuillViewer
                    value={taskExecution.task?.description}
                    variant="regular"
                    collapseHeight={-1}
                  />
                </Paper>
              )}
            </Box>
            {(canCompleteTask || !useRequestTracking) && (
              <Box flex={0} px={3} mt={1}>
                {taskExecution.error ? (
                  <RetryTaskButton
                    onClick={() => retryErrorTask && retryErrorTask()}
                  />
                ) : (
                  actions
                )}
              </Box>
            )}
          </Box>
        );
      }}
    </TaskBody>
  );
};

const TaskExecutionPaper = styled(Paper)(({ theme }) => {
  return {
    marginBottom: theme.spacing(2),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    display: "flex",
  };
});

const ProcessExecutionScreen = ({
  showStepper = true,
}: {
  showStepper?: boolean;
}) => {
  const { success, error } = useAlert();

  const {
    getContextTaskExecution,
    selectedTaskExecution,
    currentTaskExecution,
    reload,
    taskExecutions,
    processExecution,
    viewState,
  } = useProcessExecutionContext();

  const retryErrorTask = useCallback(
    async (s: TaskExecution) => {
      if (!s.task_id) {
        error(`Failed to submit task: missing task_id for task ${s.id}`);
        return;
      } else if (!s.process_execution_id) {
        error(
          `Failed to submit task: missing process_execution_id for task ${s.id}`,
        );
      }

      ProcessExecutionAPI.retryErrorTask(s.process_execution_id!, s.task_id)
        .then(() => {
          success("Task was successfully completed on retry.");
          reload();
        })
        .catch((e) => {
          console.error(e);
          error("Failed to complete task on retry.");
        })
        .finally(() => {});
    },
    [error, reload, success],
  );

  const context = useMemo(() => {
    const contextTaskExecution = getContextTaskExecution(selectedTaskExecution);
    if (contextTaskExecution) {
      return contextTaskExecution;
    }
    if (selectedTaskExecution?.task?.is_context) {
      return selectedTaskExecution;
    }
  }, [selectedTaskExecution, getContextTaskExecution]);

  const showContextAsCurrentTask = useMemo(() => {
    return !!selectedTaskExecution && context === selectedTaskExecution;
  }, [context, selectedTaskExecution]);
  const canViewNextTask =
    !processExecution?.next_task_id ||
    !!taskExecutions.find((te) => {
      return te?.task?.id === processExecution?.next_task_id;
    });

  useEffect(() => {
    const interval = setInterval(() => {
      if (!window.location.pathname.includes(processExecution?.id as string)) {
        clearInterval(interval);
        return;
      }

      const message = createWebSocketMessage(
        WebSocketMessageType.CURRENTLY_VIEWING,
        {
          process_execution_id: processExecution?.id,
          user_id: currentUserId(),
        },
      );
      WebSocketGlobalState.getWebSocket()?.send(JSON.stringify(message));
    }, 1000);

    return () => clearInterval(interval);
  });

  // Case 1. If current task is a contextual task (inbound form, outbound form, email), show full screen
  // Case 2. If current task is not a contextual task get current context for execution and display task in bottom
  return (
    <Grid
      container
      item
      flex={1}
      flexDirection="row"
      flexWrap={"unset"}
      height={"100%"}
    >
      {showStepper && (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              px: 2,
              py: 1,
              overflow: "auto",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <TaskExecutionStepper orientation="vertical" />
          </Box>
        </Box>
      )}
      <Grid container item flex={1} flexDirection="column" flexWrap={"unset"}>
        <TaskExecutionPaper
          sx={{
            flexGrow: 0,
            flexShrink: 1,
            mt: showContextAsCurrentTask ? 2 : 1,
            background: showContextAsCurrentTask ? undefined : "transparent",
            display: !context ? "none" : undefined,
            border: (theme) => {
              if (!showContextAsCurrentTask) {
                return `1px dashed ${theme.palette.grey[400]}`;
              }
            },
            outline: (theme) => {
              if (context?.id == currentTaskExecution?.id) {
                return `2px solid ${theme.palette.grey[600]}`;
              }
            },
          }}
          elevation={
            showContextAsCurrentTask
              ? selectedTaskExecution?.id == currentTaskExecution?.id
                ? 5
                : 1
              : 0
          }
        >
          {!!context && (
            <TaskExecutionCard
              taskExecution={context}
              retryErrorTask={retryErrorTask.bind(this, context)}
            />
          )}
        </TaskExecutionPaper>
        {!!selectedTaskExecution && (!context || canViewNextTask) && (
          <TaskExecutionPaper
            sx={{
              flexShrink: context ? 0 : 1,
              maxHeight: context ? "50%" : undefined,
              display: showContextAsCurrentTask ? "none" : undefined,
              mt: !context ? 2 : 0,
              outline: (theme) => {
                if (selectedTaskExecution?.id == currentTaskExecution?.id) {
                  return `2px solid ${theme.palette.grey[600]}`;
                }
              },
            }}
            elevation={
              selectedTaskExecution?.id == currentTaskExecution?.id ? 5 : 1
            }
          >
            <TaskExecutionCard
              taskExecution={selectedTaskExecution}
              retryErrorTask={retryErrorTask.bind(this, selectedTaskExecution)}
            />
          </TaskExecutionPaper>
        )}
        {viewState === ProcessExecutionViewState.Loaded && !canViewNextTask && (
          <TaskExecutionPaper>
            <CannotViewCurrentTaskCard />
          </TaskExecutionPaper>
        )}
      </Grid>
    </Grid>
  );
  // Display current task full screen when:
  //   - this is the first task
  //   - this task is a contextual task
  //   - there is no context task
  // If context exists, display context above and current task below
};
export default ProcessExecutionScreen;
