import MuiStep from "@mui/material/Step";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { AddCircle, Cancel, CheckCircle } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";

type AddStepButtonProps = {
  onAddStep: (title: string) => Promise<void>;
};

const AddStepButton = ({ onAddStep }: AddStepButtonProps) => {
  const [addingStep, setAddingStep] = useState(false);
  const [stepTitle, setStepTitle] = useState("");
  const close = () => {
    setAddingStep(false);
    setStepTitle("");
  };
  const saveAndClose = async (newStepTitle?: string) => {
    if (newStepTitle) {
      await onAddStep(newStepTitle);
    }
    close();
  };
  return (
    <MuiStep key="addStep" active={false}>
      {!addingStep && (
        <>
          <Grid container>
            <Button
              onClick={() => setAddingStep(true)}
              sx={(theme) => {
                return {
                  left: -8,
                  borderRadius: 3,
                  padding: theme.spacing(0.5, 1),
                  textTransform: "unset",
                  mt: 1,
                };
              }}
            >
              <AddCircle sx={{ mr: 1 }} />
              {"Step"}
            </Button>
          </Grid>
        </>
      )}
      {addingStep && (
        <TextField
          sx={{ mt: 1 }}
          name="stepTitle"
          size="small"
          variant="standard"
          placeholder="Enter Step Name"
          value={stepTitle}
          onChange={(e) => setStepTitle(e.target.value)}
          fullWidth={true}
          autoFocus={true}
          onKeyDown={async (e) => {
            if (e.key === "Escape") {
              close();
            }
            if (e.key === "Enter") {
              await saveAndClose((e.target as HTMLInputElement).value);
            }
          }}
          onBlur={() => {
            if (!stepTitle) {
              close();
            }
          }}
          helperText={"Add a new step. Press [Enter] to save."}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={(e) => {
                      close();
                      // Prevent the onClick of parent element
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    size="small"
                    sx={{ padding: "2px", mr: "5px", mb: "10px" }}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save">
                  <IconButton
                    onClick={async (e) => {
                      await saveAndClose(stepTitle);
                      // Prevent the onClick of parent element
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    sx={{ padding: "2px", mb: "10px" }}
                  >
                    <CheckCircle />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
    </MuiStep>
  );
};
export default AddStepButton;
