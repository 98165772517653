import {
  ContentCopyOutlined,
  DeleteOutline,
  ExitToAppOutlined,
  Info,
} from "@mui/icons-material";
import {
  Checkbox,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  Confirmation,
  Submission,
  Task,
  TaskBody,
  TaskType,
  TaskTypeOption,
  TaskTypes,
} from "../..";
import { TaskAPI } from "../../../../api";
import ReactQuillEditor from "../../../../components/core/ReactQuill/ReactQuillEditor";
import Dialog from "../../../../components/Dialog";
import useFeature from "../../../../hooks/useFeature";
import { useProcessRoleContext } from "../../../process/hooks";
import { useTaskContext } from "../../hooks";
import MoveTaskDialog from "./MoveTaskDialog";
import ProcessBuilderRoleSelect from "./ProcessBuilderRoleSelect";
import { TaskConfirmationFields } from "./TaskConfirmationFields";
import { TaskSubmissionFields } from "./TaskSubmissionFields";

type TaskSideSectionContainerProps = {
  task: Task;
};

const DEFAULT_CONFIRMATION: Confirmation = {
  title: "Are you sure?",
  body: "",
  cancelLabelText: "No",
  continueLabelText: "Yes",
};
const DEFAULT_SUBMISSION: Submission = {
  title: "Thank you for submitting your request!",
  message:
    "We have received your information and somebody will respond shortly.",
};
const TaskSideSectionContainer = ({ task }: TaskSideSectionContainerProps) => {
  const taskContext = useTaskContext();
  const { roles } = useProcessRoleContext();
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [confirmTaskDeleteDialogOpen, setConfirmDeleteTaskDialogOpen] =
    useState(false);

  const [confirmation, setConfirmation] = useState(
    task.data?.confirmation ?? DEFAULT_CONFIRMATION,
  );

  const [submission, setSubmission] = useState(
    task.data?.submission ?? DEFAULT_SUBMISSION,
  );

  const titleRef = useRef<HTMLInputElement>();
  useEffect(() => {
    titleRef.current?.focus();
  }, [task.id]);

  useEffect(() => {
    setConfirmation(task.data?.confirmation ?? DEFAULT_CONFIRMATION);
    setSubmission(task.data?.submission ?? DEFAULT_SUBMISSION);
  }, [task.data?.confirmation, task.data?.submission]);

  const deleteTask = async () => {
    await TaskAPI.delete(task);
    await taskContext.fetchTasks(task.process_id);
    taskContext.setSelectedTask(undefined);
  };

  const duplicateTask = async () => {
    const newTask = await TaskAPI.duplicate(task);
    await taskContext.fetchTasks(task.process_id);

    newTask.role = roles.find((role) => role.id == newTask.execution_role_id);
    taskContext.setSelectedTask(newTask);
  };

  const allowRequestTracking = useFeature("process.tracker");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item flex={1}>
          <TextField
            select
            disabled
            fullWidth
            id="task-type"
            name="type"
            value={task.type}
            label="Task Type"
            onChange={() => {
              // Set selected task type to value, probably wiping most/all other task data in the process
            }}
          >
            {TaskTypes.map((type: TaskTypeOption) => {
              return (
                <MenuItem key={type.type} value={type.type}>
                  {type.title}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        <Grid item alignSelf="center">
          <Tooltip title={"Move Task"}>
            <IconButton
              onClick={() => {
                setShowMoveDialog(true);
              }}
            >
              <ExitToAppOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Duplicate Task"}>
            <IconButton onClick={() => duplicateTask()}>
              <ContentCopyOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete Task"}>
            <IconButton onClick={() => setConfirmDeleteTaskDialogOpen(true)}>
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={titleRef}
            fullWidth
            onChange={(event) =>
              taskContext.setValue({ name: event.target.value })
            }
            name="name"
            value={task.name}
            label={"Task Title"}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ProcessBuilderRoleSelect
            onChange={(role) => {
              taskContext.setValue({ role });
            }}
            value={
              taskContext.selectedTask?.execution_role_id
                ? taskContext.selectedTask?.role
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ReactQuillEditor
            id={task.id || ""}
            onChange={(value) => {
              taskContext.setValue({ description: value });
            }}
            value={task.description || "<p><br></p>"}
            placeholder="Task Description"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!task.is_context}
                onChange={(e) => {
                  taskContext.setValue({ is_context: e.target.checked });
                }}
              />
            }
            label={"Pinned"}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <TaskBody variant="side-section" task={task} />

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="task-identifier"
            name="identifier"
            value={task.task_identifier_name}
            onChange={(event) =>
              taskContext.setValue({ identifier: event.target.value })
            }
            label="Task Identifier"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      "Optional identifier used to reference this task's data in another task."
                    }
                    placement={"bottom"}
                    arrow
                  >
                    <Info />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            {task.type == TaskType.Form && (
              <FormControlLabel
                control={<Checkbox checked={!!task.data?.share} />}
                onChange={(event: React.BaseSyntheticEvent) => {
                  taskContext.setValue({
                    data: {
                      share: event.target.checked ? true : undefined,
                    },
                  });
                }}
                label="Share Result Automatically"
              />
            )}
            <FormControlLabel
              control={<Checkbox checked={!!task.data?.confirmation} />}
              onChange={(event: React.BaseSyntheticEvent) => {
                taskContext.setValue({
                  data: {
                    confirmation: event.target.checked
                      ? confirmation
                      : undefined,
                  },
                });
              }}
              label="Requires Confirmation"
            />
            {task.data?.confirmation && (
              <TaskConfirmationFields
                confirmation={task.data.confirmation}
                onUpdate={(confirmation: Confirmation) => {
                  taskContext.setValue({
                    data: { confirmation: confirmation },
                  });
                  setConfirmation(confirmation);
                }}
              />
            )}
            <FormControlLabel
              control={<Checkbox checked={!!task.data?.submission} />}
              onChange={(event: React.BaseSyntheticEvent) => {
                taskContext.setValue({
                  data: {
                    submission: event.target.checked ? submission : undefined,
                  },
                });
              }}
              label="Submission Message"
            />
            {task.data?.submission && (
              <TaskSubmissionFields
                submission={task.data.submission}
                onUpdate={(submission: Submission) => {
                  taskContext.setValue({
                    data: { submission },
                  });
                  setSubmission(submission);
                }}
              />
            )}
            {allowRequestTracking && (
              <FormControlLabel
                control={<Checkbox checked={!!task.data?.requestTracking} />}
                onChange={(event: React.BaseSyntheticEvent) => {
                  taskContext.setValue({
                    data: {
                      requestTracking: event.target.checked ? true : undefined,
                    },
                  });
                }}
                label="Enable Request Tracker"
              />
            )}
          </FormGroup>
        </Grid>
      </Grid>

      <Dialog
        open={confirmTaskDeleteDialogOpen}
        title={"Delete Task?"}
        confirmText={"Yes, Delete"}
        cancelText={"Cancel"}
        handleConfirm={(): Promise<void> => {
          deleteTask();
          setConfirmDeleteTaskDialogOpen(false);
          return Promise.resolve();
        }}
        handleCancel={function (): void {
          setConfirmDeleteTaskDialogOpen(false);
        }}
      >
        <DialogContentText>
          {"Are you sure you want to delete this task?"}
        </DialogContentText>
      </Dialog>
      <MoveTaskDialog
        open={showMoveDialog}
        task={task}
        onClose={() => setShowMoveDialog(false)}
        onMove={() => {
          taskContext.fetchTasks(task.process_id);
        }}
      />
    </>
  );
};

export default TaskSideSectionContainer;
