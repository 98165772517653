import { DialogContentText } from "@mui/material";
import { FC } from "react";
import Dialog from "../../components/Dialog";

export type LeaveOrganizationDialogProps = {
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
  title?: string;
  confirmText?: string;
};
const LeaveOrganizationDialog: FC<LeaveOrganizationDialogProps> = ({
  title = "Leave Organization Dialog",
  confirmText = "Leave",
  ...props
}) => {
  return (
    <Dialog
      open={props.open}
      title={title}
      confirmText={confirmText}
      cancelText={"Cancel"}
      maxWidth="xs"
      fullWidth={true}
      handleConfirm={(): Promise<void> => {
        props.onSave && props.onSave();
        return Promise.resolve();
      }}
      handleCancel={function (): void {
        props.onClose && props.onClose();
      }}
    >
      <DialogContentText>
        Are you sure you want to leave this organization?
      </DialogContentText>
      <DialogContentText>
        You cannot rejoin this organization unless you are invited again.
      </DialogContentText>
    </Dialog>
  );
};

export default LeaveOrganizationDialog;
