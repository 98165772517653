import {
  Box,
  Button,
  IconButton,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Organizations from "../../../api/organizations";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import keyify from "../../../lib/keyify";
import { Organization } from "../../../model";
import UpdateOrganization from "../components/UpdateOrganization";

type CreateOrganizationProps = {
  onCreate: (organization: Organization) => void;
  disabled?: boolean;
  organization?: Organization;
  hideStepper?: boolean;
  buttonAlignment?: "left" | "right";
  buttonText?: string;
  emailDisabled?: boolean;
};

enum Steps {
  SELECT_EXISTING_ORGANIZATION,
  ENTER_WEBSITE,
  CONFIRM_DETAILS,
  DONE,
}

const pastelColors = [
  "#FFB6C1", // Light Pink
  "#FFDAB9", // Peach Puff
  "#B0E0E6", // Powder Blue
  "#ADD8E6", // Light Blue
  "#E6E6FA", // Lavender
  "#FFDEAD", // Moccasin
  "#D3D3D3", // Light Gray
  "#FFC0CB", // Pink
  "#F0E68C", // Khaki
  "#E0FFFF", // Light Cyan
  "#AFEEEE", // Pale Turquoise
  "#DB7093", // Pale Violet Red
  "#FFE4E1", // Misty Rose
  "#FAD02E", // Pastel Yellow
  "#87CEFA", // Light Sky Blue
  "#BDB76B", // Dark Khaki
  "#FFA07A", // Light Salmon
  "#DDA0DD", // Plum
];

function stringToHashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function getGradientFromName(name: string) {
  const hash = stringToHashCode(name);
  const index1 = Math.abs(hash) % pastelColors.length;
  const index2 = (index1 + 1 + Math.abs(hash >> 5)) % pastelColors.length; // Use a different part of the hash for the second index
  return `linear-gradient(to top right, ${pastelColors[index1]}, ${pastelColors[index2]})`;
}

const CreateOrganizationComponent: FC<CreateOrganizationProps> = (props) => {
  const { error } = useAlert();

  const setActiveStepByStepAvailability = useCallback(
    (step: Steps) => {
      if (props.hideStepper) {
        switch (step) {
          case Steps.SELECT_EXISTING_ORGANIZATION: {
            setActiveStep(0);
            break;
          }
          case Steps.ENTER_WEBSITE: {
            setActiveStep(1);
            break;
          }
          case Steps.CONFIRM_DETAILS: {
            setActiveStep(2);
            break;
          }
          default: {
            setActiveStep(3);
          }
        }
      } else {
        switch (step) {
          case Steps.ENTER_WEBSITE: {
            setActiveStep(0);
            break;
          }
          case Steps.CONFIRM_DETAILS: {
            setActiveStep(1);
            break;
          }
          default: {
            setActiveStep(2);
          }
        }
      }
    },
    [props.hideStepper],
  );

  // Integer state to track the current active step
  const [activeStep, setActiveStep] = useState<number>(0);

  const [availableOrganizations, setAvailableOrganizations] = useState<
    Organization[]
  >([]);

  // State to track the entered website
  const [website, setWebsite] = useState<string>("");

  // State to hold the organization name
  const [name, setName] = useState<string | undefined>(
    props.organization?.name ?? "",
  );

  // State to holdt he organization description
  const [description, setDescription] = useState<string | undefined>(
    props.organization?.description ?? "",
  );

  // State to hold the organization logo
  const [logo, setLogo] = useState<string | undefined>(
    props.organization?.theme?.shortLogoUrl ?? "",
  );

  // State to hold the organization's long logo
  const [longLogo, setLongLogo] = useState<string | undefined>(
    props.organization?.theme?.longLogoUrl ?? "",
  );

  // State to hold the organization's favicon
  const [favicon, setFavicon] = useState<string | undefined>(
    props.organization?.theme?.faviconUrl ?? "",
  );

  // State to hold error for website input
  const [websiteError, setWebsiteError] = useState<string | undefined>();

  // State to hold error for data confirmation
  const [dataError, setDataError] = useState<string | undefined>();

  useEffect(() => {
    const userId = currentUserId();

    if (!userId || !props.hideStepper) {
      setActiveStepByStepAvailability(Steps.ENTER_WEBSITE);
      return;
    }

    Organizations.byOwner(userId).then(
      (organizations) => {
        if (organizations.length === 0) {
          setActiveStepByStepAvailability(Steps.ENTER_WEBSITE);
          return;
        }
        setAvailableOrganizations(organizations);
      },
      (err) => {
        setActiveStepByStepAvailability(Steps.ENTER_WEBSITE);
        console.error(err);
        error("Could not load existing organizations");
      },
    );
  }, [error, props.hideStepper, setActiveStepByStepAvailability]);

  const handleWebsiteSubmit = async () => {
    if (!website) {
      setActiveStepByStepAvailability(Steps.CONFIRM_DETAILS);
      return;
    }

    try {
      const organization: Organization =
        await Organizations.fromWebsite(website);

      setName(organization.name);
      setDescription(organization.description);
      setLogo(organization.theme?.shortLogoUrl);
      setLongLogo(organization.theme?.longLogoUrl);
      setFavicon(organization.theme?.faviconUrl);

      setActiveStepByStepAvailability(Steps.CONFIRM_DETAILS);
      setWebsiteError(undefined);
    } catch (err) {
      console.error(err);
      setWebsiteError(
        "Failed to fetch information from website, did you type the url correctly?",
      );
    }
  };

  const handleCreate = async (organization: Organization) => {
    const created = await Organizations.create(organization);
    setActiveStepByStepAvailability(Steps.DONE);
    setDataError(undefined);
    props.onCreate(created);
  };

  const stepZero = (
    <Box>
      <Typography sx={{ py: 1 }} variant="body1">
        Select your organization below to continue setup or{" "}
        <Link
          sx={{
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
          onClick={() => setActiveStepByStepAvailability(Steps.ENTER_WEBSITE)}
        >
          Create a New Organization
        </Link>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {availableOrganizations.map((organization) => {
          return (
            <Box key={organization.id} sx={{ display: "flex" }}>
              <Tooltip title={organization.name} placement="right" arrow>
                <IconButton onClick={() => props.onCreate(organization)}>
                  {organization.theme?.shortLogoUrl ? (
                    <div
                      style={{
                        backgroundImage: `url('${organization.theme?.shortLogoUrl}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        width: "64px",
                        height: "64px",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        background: getGradientFromName(organization.name),
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  const stepOne = (
    <Box>
      <Typography>
        If your organization has a website, enter it here. We&apos;ll pull your
        logo and other information to automatically pre-populate some fields in
        the next step. If you don&apos;t have a website, you can skip this step.
      </Typography>
      <Box sx={{ my: 3 }}>
        <TextField
          fullWidth
          label={"Website"}
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          disabled={props.disabled}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: props.buttonAlignment === "right" ? "right" : "left",
        }}
      >
        {website ? (
          <Button variant="contained" onClick={handleWebsiteSubmit}>
            {props.buttonText ?? "Submit"}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={handleWebsiteSubmit}
            disabled={props.disabled}
          >
            Skip
          </Button>
        )}
      </Box>
      {websiteError && (
        <Box sx={{ my: 2 }}>
          <Typography color="error">{websiteError}</Typography>
        </Box>
      )}
    </Box>
  );

  const stepTwo = (
    <Box>
      <Typography sx={{ pb: 2 }}>
        {website
          ? `We automatically pulled this information from your organization's website.  Are these details correct?`
          : `Please enter your organization's details below.  All details besides name are optional and you can change them later.`}
      </Typography>
      <UpdateOrganization
        disabled={props.disabled}
        buttonAlignment={props.buttonAlignment}
        buttonText={props.buttonText}
        emailDisabled={props.emailDisabled}
        organization={{
          id: uuid(),
          owner_id: uuid(),
          name: name ?? "",
          key: keyify(name ?? ""),
          description: description,
          theme: {
            shortLogoUrl: logo,
            longLogoUrl: longLogo,
            faviconUrl: favicon,
          },
        }}
        isCreatingOrganization={true}
        onError={() => setDataError("error")}
        onSubmit={handleCreate}
      />
    </Box>
  );

  if (props.hideStepper) {
    return activeStep === 0 ? (
      <Box>
        <Typography variant="h1">Select An Existing Organization</Typography>
        {stepZero}
      </Box>
    ) : activeStep === 1 ? (
      <Box>
        <Typography variant="h1">Your Organization&#39;s Website</Typography>
        {stepOne}
      </Box>
    ) : (
      <Box>
        <Typography variant="h1">Confirm Details</Typography>
        {stepTwo}
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "auto" }}>
      <Typography variant="h1">Create A New Organization</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step
          key={0}
          onClick={() =>
            props.disabled &&
            setActiveStepByStepAvailability(Steps.ENTER_WEBSITE)
          }
        >
          <StepLabel error={!!websiteError}>
            Enter Your Organization&apos;s Website
          </StepLabel>
          <StepContent>{stepOne}</StepContent>
        </Step>
        <Step
          key={1}
          onClick={() => {
            props.disabled &&
              setActiveStepByStepAvailability(Steps.CONFIRM_DETAILS);
          }}
        >
          <StepLabel error={!!dataError}>
            Confirm Your Organization&apos;s Details
          </StepLabel>
          <StepContent>{stepTwo}</StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export default CreateOrganizationComponent;
