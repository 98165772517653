import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  DialogContentText,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import Dialog from "../../../components/Dialog";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import Role from "../../../model/Role";
import {
  invitationProvider,
  userProvider,
} from "../../../providers/OrganizationMemberProvider";

type RoleAutocompleteComponentType = {
  userId: string;
  organizationId: string;
  isInvitation: boolean;
  organizationRoles: Array<Role>;
  assignedRoles: Array<Role>;
  disabled?: boolean;
};

type RoleStatePreUnassignType = {
  role: Role;
  oldRoles: Array<Role> | undefined;
};

const MAX_ROLES_DISPLAYED = 3;

const RoleAutocompleteComponent: FC<RoleAutocompleteComponentType> = ({
  userId,
  organizationId,
  isInvitation,
  organizationRoles,
  assignedRoles,
  disabled = false,
}) => {
  const { error, success } = useAlert();

  // state that keeps track of selected roles for member
  const [selectedRoles, setSelectedRoles] = useState<Array<Role> | undefined>(
    assignedRoles,
  );
  const provider = isInvitation ? invitationProvider : userProvider;

  const isCurrentUserId = userId === currentUserId();

  const [removeAdminDialogShow, setRemoveAdminDialogShow] = useState(false);
  const [roleStatePreUnassign, setRoleStatePreUnassign] = useState<
    RoleStatePreUnassignType | undefined
  >();

  const unassignRole = (
    role: Role,
    oldRoles: Array<Role> | undefined,
    delayed: boolean,
  ) => {
    provider.unassignRole(role.id, userId).then(
      () => {
        if (delayed) {
          const newRoles = oldRoles?.filter((r) => r.id !== role.id);
          setSelectedRoles(newRoles);
        }
        success("Successfully unassigned role from member");
      },
      (err) => {
        console.error(`Error: unassigning role from member -- ${err}`);
        error("Error when unassigning role from member");
        setSelectedRoles(oldRoles);
      },
    );
  };

  const unassignAllRoles = useCallback(() => {
    selectedRoles?.map((role, index) => {
      provider.unassignRole(role.id, userId).then(
        () => {
          if (index == selectedRoles.length) {
            success("Successfully cleared all roles for member");
          }
        },
        (err) => {
          console.error(
            `Error: clearing/unassigning roles for member -- ${err}`,
          );
          error("Error clearing all roles for member");
        },
      );
    });
  }, [error, provider, selectedRoles, success, userId]);

  const handleRoleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      roles: Role[] | null,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<Role> | undefined,
    ) => {
      // no roles have been selected or de-selected
      if (!details) {
        return;
      }
      // there are currently no roles assigned (selected/displayed in the Autocomplete element)
      if (!roles) {
        return;
      }

      const oldSelectedRoles = selectedRoles;
      switch (reason) {
        case "selectOption": {
          provider.assignRole(details.option.id, userId, organizationId).then(
            () => success("Successfully assigned role to member"),
            (err) => {
              console.error(`Error: assigning role to member -- ${err}`);
              error("Error when assigning role to member");
              setSelectedRoles(oldSelectedRoles);
            },
          );
          break;
        }
        case "removeOption": {
          // check to see if user is removing admin role off of themselves

          if (details.option.name === "Administrator" && isCurrentUserId) {
            setRemoveAdminDialogShow(true);
            setRoleStatePreUnassign({
              role: details.option,
              oldRoles: oldSelectedRoles,
            });
            return;
          }

          unassignRole(details.option, oldSelectedRoles, false);

          break;
        }
        case "clear": {
          // clearing all roles from member in one swoop
          unassignAllRoles();
          break;
        }
        default: {
          console.error("Error: invalid reason for role selection");
        }
      }
      setSelectedRoles(roles);
    },
    [
      error,
      isCurrentUserId,
      organizationId,
      provider,
      selectedRoles,
      success,
      unassignAllRoles,
      unassignRole,
      userId,
    ],
  );
  // this order matters, an invitation can have the 'Member' or 'Account' type
  // but if it's an invitation we need to render it as an invitation element instead until the invite is accepted
  return (
    <FormControl fullWidth>
      <FormControl
        sx={{
          marginLeft: -1.4,
          overflow: "hidden",
        }}
      >
        <Autocomplete
          id="member-role-select"
          limitTags={MAX_ROLES_DISPLAYED}
          disableCloseOnSelect
          disableClearable
          multiple
          disabled={disabled}
          options={organizationRoles}
          getOptionLabel={(role) => role.name}
          value={selectedRoles ?? []}
          onChange={(event, roles, reason, details) =>
            handleRoleChange(event, [...roles], reason, details)
          }
          noOptionsText="No roles in organization"
          PaperComponent={(props) => <Paper elevation={4} {...props} />}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <Dialog
          open={removeAdminDialogShow}
          title={"Remove Administrator Role?"}
          confirmText={"Remove"}
          cancelText={"Cancel"}
          maxWidth="xs"
          fullWidth={true}
          handleConfirm={(): Promise<void> => {
            if (!roleStatePreUnassign) return Promise.resolve();
            unassignRole(
              roleStatePreUnassign?.role,
              roleStatePreUnassign?.oldRoles,
              true,
            );
            setRemoveAdminDialogShow(false);
            return Promise.resolve();
          }}
          handleCancel={function (): void {
            setRemoveAdminDialogShow(false);
          }}
        >
          <DialogContentText>
            Are you sure you want to remove the Administrator role off of
            yourself? You may lose permissions to perform certain actions.
          </DialogContentText>
        </Dialog>
      </FormControl>
    </FormControl>
  );
};

export default RoleAutocompleteComponent;
