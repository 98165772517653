import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { ProcessExecutionAPI } from "../../../../api";
import { UserSelectionByRole } from "../../../../components/core/Selection";
import { UserOrInvitedUser } from "../../../../components/core/Selection/UserSelection";
import { useAlert } from "../../../../lib/alert";
import { User } from "../../../../model";
import Invitation from "../../../../model/Invitation";
import { TaskExecution } from "../../../task";
interface TaskExecutionAccountModalProps {
  open: boolean;
  handleClose: () => void;
  taskExecution: TaskExecution;
  assignedUser: User | undefined;
  setAssignedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const TaskExecutionAccountModal = ({
  open,
  handleClose,
  taskExecution,
  assignedUser,
  setAssignedUser,
}: TaskExecutionAccountModalProps) => {
  const { handleRejectionWithError, error, success } = useAlert();
  const task = taskExecution.task;
  const targetRoleId = taskExecution.task?.role?.id || "";
  const processExecutionId = taskExecution?.process_execution_id || "";
  const [stateAssignedUser, setStateAssignedUser] = useState<User | undefined>(
    assignedUser,
  );

  const cancelAssignment = () => {
    setStateAssignedUser(assignedUser);
    handleClose();
  };

  const handleStateUpdate = (users: UserOrInvitedUser[]) => {
    if (!taskExecution?.task_id) {
      handleClose();
      return;
    }

    if (!users.length) {
      setStateAssignedUser(undefined);
    } else {
      setStateAssignedUser(users[0]);
    }
  };

  const handleApply = () => {
    if (!taskExecution.task_id) {
      return;
    }
    if (!stateAssignedUser) {
      ProcessExecutionAPI.unassign(processExecutionId, taskExecution.task_id)
        .then(() => {
          setAssignedUser(undefined);
          success("Successfully unassigned account!");
        }, handleRejectionWithError)
        .catch(() => error("Error unassigning account!"));
    } else {
      ProcessExecutionAPI.assign(
        processExecutionId,
        taskExecution.task_id,
        stateAssignedUser.id,
      )
        .then(() => {
          setAssignedUser(stateAssignedUser);
          success("Successfully assigned account!");
        }, handleRejectionWithError)
        .catch(() => error("Error assigning account!"));
    }
    handleClose();
  };

  return (
    task && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="tea-modal-title"
        aria-describedby="tea-modal-desc"
      >
        <DialogTitle id="alert-dialog-title">Assign User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Select a user to be assigned to complete "' +
              (task.name || "Unnamed Task") +
              '"'}
          </DialogContentText>

          <Box my="20px">
            <UserSelectionByRole
              values={stateAssignedUser ? [stateAssignedUser.id] : undefined}
              onInvite={(invitation: Invitation) => {
                if (!taskExecution?.task_id) {
                  return;
                }

                ProcessExecutionAPI.assign(
                  processExecutionId,
                  taskExecution.task_id,
                  invitation.target_user_id,
                ).then(() => {
                  setStateAssignedUser({
                    id: invitation.target_user_id,
                    email: invitation.target_user_email,
                  } as User);
                }, handleRejectionWithError);
              }}
              multiple={false}
              targetRoleId={targetRoleId}
              onChange={handleStateUpdate}
            />
          </Box>

          <Box>
            <Button
              sx={{ marginRight: "5px" }}
              variant="contained"
              onClick={handleApply}
            >
              Apply
            </Button>
            <Button onClick={cancelAssignment}>Cancel</Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
};

export default TaskExecutionAccountModal;
