import { PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import PreferenceAPI from "../../api/preferences";
import Users from "../../api/users";
import { UserIcon } from "../../components/elements";
import { useGlobalUserContext } from "../../hooks/useGlobalUserContext";
import { useAlert } from "../../lib/alert";
import { currentUserId } from "../../lib/auth";
import { PreferenceContainer } from "../../model/Preference";

const UserPreferencePage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { user, setUser } = useGlobalUserContext();
  const { handleRejectionWithError } = useAlert();
  const [preferences, setPreferences] = useState<PreferenceContainer | void>();
  const savePreference = (preference: PreferenceContainer) => {
    setPreferences(preference);
    PreferenceAPI.set(currentUserId(), preference).catch(
      handleRejectionWithError,
    );
  };
  const [tempUser, setTempUser] = useState(user);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTempUser({ ...tempUser, [name]: value });
  };
  const handleEditSave = useCallback(() => {
    setIsEditing(false);
    return Users.update(tempUser).then((savedUser) => {
      setUser(savedUser);
    }, handleRejectionWithError("Unable to save changes"));
  }, [handleRejectionWithError, tempUser]);

  const handleEditCancel = () => {
    setIsEditing(false);
    setTempUser(user);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          setTempUser({
            ...tempUser,
            profile_picture: reader.result as string,
          });
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h1" sx={{ fontWeight: "bold" }}>
        Preferences
      </Typography>
      <Box
        sx={{ mt: 5, padding: 1, display: "flex", alignItems: "center", mb: 2 }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          User Details
        </Typography>
        <Box>
          {isEditing ? (
            <Box>
              <Button
                sx={{ ml: 4, width: "80px" }}
                variant="outlined"
                onClick={handleEditCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  ml: 2,
                  color: "white",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  "&:hover": {
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
                variant="outlined"
                onClick={handleEditSave}
              >
                Save
              </Button>
            </Box>
          ) : (
            <Button
              sx={{
                ml: 4,
                width: "80px",
                color: "white",
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              variant="outlined"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ marginBottom: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              First Name
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="first_name"
              value={tempUser.first_name}
              onChange={handleTextChange}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: !isEditing }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              Last Name
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="last_name"
              value={tempUser.last_name}
              onChange={handleTextChange}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: !isEditing }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isEditing ? "default" : "transparent",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 1, color: (theme) => theme.palette.grey[500] }}
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="email"
              value={user.email}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: true }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                width: "400px",
              }}
            />
            {/* This is the editable version of the email field which will be implemented with changes later
            <TextField name="email" value={tempUser.email} onChange={handleTextChange} variant="outlined" size="small" InputProps={{ readOnly: !isEditing }} sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isEditing ? 'default' : 'transparent', 
              },
              '&:hover fieldset': {
                borderColor: isEditing ? 'default' : 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: isEditing ? 'default' : 'transparent',
              },
            },
            width: "400px"
          }}/> */}
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{ ml: 3, mt: 4, color: (theme) => theme.palette.grey[500] }}
            >
              Profile Picture
            </Typography>
          </Grid>
          <Grid item xs={isEditing ? 1 : 10}>
            <UserIcon user={tempUser} size="large" sx={{ ml: 2 }} />
          </Grid>
          {isEditing ? (
            <Grid item xs={9}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{ display: "none" }} // Hide the actual file input
                accept="image/*" // Accept only images
              />

              {/* Pencil icon button */}
              <IconButton
                onClick={handleIconClick}
                sx={{ cursor: "pointer", mt: 3 }}
              >
                <PhotoCamera />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box
        sx={{
          mt: 5,
          padding: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Notifications
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(value) => {
                    savePreference({
                      ...preferences,
                      notifications: {
                        ...preferences?.notifications,
                        notifyMeByEmailWhenANewTaskForARoleIHaveIsNotYetAssigned:
                          value.target.checked,
                      },
                    });
                  }}
                  checked={
                    preferences?.notifications
                      ?.notifyMeByEmailWhenANewTaskForARoleIHaveIsNotYetAssigned ==
                    true
                  }
                />
              }
              label="Notify me by email when a new task for a role I have is not yet assigned"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserPreferencePage;
