import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AccountTypeAPI } from "../../../api";
import Roles from "../../../api/roles";
import Users from "../../../api/users";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import {
  Display,
  GlobalPageContext,
} from "../../../hooks/useGlobalPageContext";
import { GlobalRoleContext } from "../../../hooks/useGlobalRoleContext";
import { GlobalUserContext } from "../../../hooks/useGlobalUserContext";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import eventEmitter from "../../../lib/event";
import { showNotification } from "../../../lib/notification";
import { Role, User } from "../../../model";
import { WebSocketNotificationData } from "../../../model/WebSocketMessage";
import { CommonRole } from "../../../types";
import { environment } from "../../../util";
import PATHS from "../../navigation/_paths";
import DashboardLayout from "./DashboardLayout";

const Dashboard = (props: React.PropsWithChildren<unknown>) => {
  // hooks
  const alertContext = useAlert();
  const globalOrganizationContext = useGlobalOrganizationContext();

  // state
  const [user, setUser] = useState<User>(new User());
  const [display, setDisplay] = useState<Display>({ drawer: true });
  const [roles, setRoles] = useState<Role[]>([]);
  const [accountRoles, setAccountRoles] = useState<Role[]>([]);

  // spread hooks
  const { handleRejectionWithWarning } = alertContext;
  const { organization, accountListIsMemberOf } = globalOrganizationContext;

  const navigate = useNavigate();

  const getAccountRoles = useCallback(async () => {
    const accRoles = accountListIsMemberOf
      .filter((account) => !!account.account_type_id)
      .map(async (account) => {
        if (!account.account_type_id) return [];
        const accountType = await AccountTypeAPI.get(account.account_type_id);
        if (!accountType.role_id) return [];
        const role = new Role();
        role.id = accountType.role_id;
        role.name = accountType.name;
        return role;
      });

    return (await Promise.all(accRoles)).flat();
  }, [accountListIsMemberOf]);

  useEffect(() => {
    Users.get(currentUserId()).then(
      setUser,
      handleRejectionWithWarning("Could not get current user"),
    );
  }, [handleRejectionWithWarning]);

  // handle a notification whenever it is received for the invitation
  // handles here so that it can get the organization icon
  eventEmitter.on(
    "websocket:invitationNotification",
    (notificationData: WebSocketNotificationData) => {
      if (document.hasFocus()) {
        // send a tiny notification if the user is already active on the page
        alertContext.info(notificationData.body);
        return;
      }

      // get the icon for the notification
      // the default is the Runway favicon
      const icon = "/favicon.ico";

      // show notification natively
      showNotification(
        notificationData.title,
        notificationData.body,
        notificationData.id,
        icon,
        () => {
          eventEmitter.emit("websocket:notificationClicked", {
            organization_id: notificationData.organization_id,
            process_execution_id: notificationData.process_execution_id,
            process_id: notificationData.process_id,
          });
          window.focus();
          navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link);
        },
        () => {},
        () => {},
      );
    },
  );

  useEffect(() => {
    try {
      const getRoles = async () => {
        let roles: Role[] = [];
        const publicRole = new Role();
        publicRole.id = CommonRole.PUBLIC;
        const accountRoles = await getAccountRoles();
        if (organization) {
          roles = await Roles.byUserIdAndOrganizationId(
            currentUserId(),
            organization.id,
          );
        }
        setRoles([...roles, publicRole]);
        setAccountRoles(accountRoles);
      };
      getRoles();
    } catch {
      handleRejectionWithWarning("Could not get current roles");
    }
  }, [getAccountRoles, handleRejectionWithWarning, organization]);

  useDisplayOptions({
    displayIcon: organization?.theme?.faviconUrl ?? environment.app?.logoIcon,
  });

  return (
    <GlobalUserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <GlobalRoleContext.Provider
        value={{ roles, setRoles, accountRoles, setAccountRoles }}
      >
        <GlobalPageContext.Provider value={{ display, setDisplay }}>
          <DashboardLayout>{props.children}</DashboardLayout>
        </GlobalPageContext.Provider>
      </GlobalRoleContext.Provider>
    </GlobalUserContext.Provider>
  );
};

export default Dashboard;
